import { useContext, useMemo, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import { EditableContext } from '../../../context/editableContext'
import FormikContext from '../../../context/formikContext'
import { PhoneType } from '../../../types'
import SimpleButton from '../../common/buttons/simpleButton'
import ContainedButton from '../../common/buttons/containedButton'
import { contactSchema } from '../../../validations'
import ContactFrom from './contactFrom'
import { EnterprisesServerStateKeysEnum, useCreateContact } from '../../../hooks/api/entreprises'
import { queryClient } from '../../../index'
import { ContactFormType } from '../../../hooks/pages/entreprise/useEntrepriseProfile'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'

const initialValuesContactsData = {
  crm_id: '',
  title: '',
  salutation: '',
  first_name: '',
  last_name: '',
  email: '',
  mainPhone: { phone_number: '', phone_number_post: '', type: '' as PhoneType },
  otherPhone: { phone_number: '', phone_number_post: '', type: '' as PhoneType },
  is_main_contact: false,
}
interface Props {
  handleClose: () => void
  setDirty: React.Dispatch<React.SetStateAction<boolean>>
}
const AddContactForm = ({ handleClose, setDirty }: Props) => {
  const editableContext = useContext(EditableContext)
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)

  const editableState = useMemo(() => ({ ...editableContext, isEditable: true }), [editableContext])
  const formik = useFormik<ContactFormType>({
    initialValues: initialValuesContactsData,
    enableReinitialize: true,
    validationSchema: contactSchema,
    onSubmit: async (values, formikBag) => {
      const { mainPhone, otherPhone, ...rest } = values

      const data = {
        contact: [mainPhone, otherPhone].filter((phone) => phone.phone_number),
        ...rest,
        crm_id: activeEnterprise.crm_id,
      }
      await mutate(data, {
        onSuccess: async () => {
          handleClose()
          formikBag.resetForm()
          await queryClient.invalidateQueries(EnterprisesServerStateKeysEnum.Contacts)
        },
      })
    },
  })
  const { mutate } = useCreateContact(formik)
  useEffect(() => {
    setDirty(formik.dirty)
  }, [formik.dirty])

  return (
    <Box sx={{ p: '38px 32px 47px 32px', border: '1px solid #DADCDD' }}>
      <Typography
        variant='h6'
        sx={{ textTransform: 'uppercase', letterSpacing: '1px', mb: '37px' }}
      >
        Nouveau Contact
      </Typography>
      <EditableContext.Provider value={editableState}>
        <FormikContext.Provider value={formik}>
          <ContactFrom values={formik.values} />
          <Divider sx={{ mt: '24px' }} />
          <Box sx={{ display: 'flex', gap: '36px', alignItems: 'center', mt: '24px' }}>
            <SimpleButton handleClick={handleClose} />
            <ContainedButton handleClick={formik.handleSubmit} title='Enregistrer le contact' />
          </Box>
        </FormikContext.Provider>
      </EditableContext.Provider>
    </Box>
  )
}

export default AddContactForm
