import BodyModalWithActions from '../../../../components/common/modals/bodyModalWithActions'
import { Typography, SxProps, Theme } from '@mui/material'
import CustomButton from '../../../../components/common/buttons/customButton'

interface Props {
  handleClose: () => void
  sx?: SxProps<Theme> 
}
const BodyModalErreur = ({ handleClose, sx }: Props) => {
  return (
    <BodyModalWithActions
      title='Aucunes dates disponibles'
      type='warning'
      content={
            <Typography variant='body1'>Cette formation n’est malheureusement pas offerte à d’autres dates pour le moment.</Typography>
      }
      sx={{...sx}}
      actions={
        <>
          <CustomButton variant = 'secondary' onClick={handleClose}  >
              ok
          </CustomButton>
        </>
      }
    />
  )
}

export default BodyModalErreur
