import CityApi from '../../api/cityApi'
import City from '../../types/api/city'
import useCustomQuery from './useCustomQuery'
import { OptionsTypeReactQuery } from '../../types/api'

export enum ServerStateKeysEnum {
  Cities = 'cities',
}
export const useGetCities = (
  name: string,
  options?:
    | OptionsTypeReactQuery<City[]>
) =>
  useCustomQuery<City[], Error>(
    [ServerStateKeysEnum.Cities, name],
    (header) => CityApi.findAll(name, header),
    options,
  )
