import {
    FormationType,
    FormationDetailsType,
    FormationOtherDatesType,
    UpdateInscriptionDate
  } from '../../types/api/formations'
import FormationApi from '../../api/formationsApi'
import useCustomQuery from './useCustomQuery'
import { FormikProps } from 'formik'
import useCustomMutationWithFormik from '../useCustomMutationWithFormik'
import { OptionsTypeReactQuery, PaginationResponseSecondFormat, OptionalParamsApi } from '../../types/api'


  export const useGetAutoFormationQuery = (
    params: OptionalParamsApi,
    type: string | undefined,
    options?: OptionsTypeReactQuery<PaginationResponseSecondFormat<FormationType>>,
  ) =>
    useCustomQuery<PaginationResponseSecondFormat<FormationType>, Error>(
      ['formationPerType', params],
      async (header) => await FormationApi.getAutoFormations(params, type, header),
      options,
  )


  export const useGetNextFormationQuery = (
    params: OptionalParamsApi,
    options?: OptionsTypeReactQuery<PaginationResponseSecondFormat<FormationType>>,
  ) =>
    useCustomQuery<PaginationResponseSecondFormat<FormationType>, Error>(
      ['nextFormations', params],
      async (header) => await FormationApi.getNextFormations(params, header),
      options,
  )




  export const useGetNextFormationDetailsQuery = (
    training_id: string,
    options?: OptionsTypeReactQuery<FormationDetailsType>,
  ) =>
    useCustomQuery<FormationDetailsType, Error>(
      ['nextFormationDetails', training_id],
      async (header) => await FormationApi.getNextFormationDetails(training_id, header),
      options,
  )

  export const useGetNextFormationOtherDatesQuery = (
    training_id: string,
    options?: OptionsTypeReactQuery<FormationOtherDatesType[]>,
  ) =>
    useCustomQuery<FormationOtherDatesType[], Error>(
      ['nextFormationDate', training_id],
      async (header) => await FormationApi.getNextFormationDates(training_id, header),
      options,
  )


export const useUpdateFormationDate = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(FormationApi.updateDateInscription, formik)