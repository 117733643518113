import { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { t } from 'i18next'
import { ErrorModalContext } from '../../../context/errorModalContext'
import CustomModal from './customModal'
import ExclamationPointIcon from '../../../icons/exclamationPointIcon'
export default function ErrorModal() {
  const { showModal, errorMessage, setShowModal } = useContext(ErrorModalContext)
  return (
    <CustomModal
      open={showModal}
      handleClose={() => {
        setShowModal(false)
      }}
      sx={{ height: '293px', px: 0 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ mb: '28px' }}>
          <ExclamationPointIcon />
        </Box>
        <Typography variant='modal' sx={{ mb: '16px', textAlign: 'center' }}>
          {t('common.error')}
        </Typography>
        <Typography sx={{ mb: '30px', textAlign: 'center', width: '413px', lineHeight: '24px' }}>
          {errorMessage}
        </Typography>
      </Box>
    </CustomModal>
  )
}
