const SiteIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse
        cx='7.5013'
        cy='5.83333'
        rx='3.33333'
        ry='3.33333'
        stroke='#F9A51A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 17.5V15.8333C2.5 13.9924 3.99238 12.5 5.83333 12.5H9.16667C11.0076 12.5 12.5 13.9924 12.5 15.8333V17.5'
        stroke='#F9A51A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.332 2.6084C14.807 2.98604 15.8386 4.31506 15.8386 5.83757C15.8386 7.36007 14.807 8.68909 13.332 9.06673'
        stroke='#F9A51A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 17.5V15.8333C17.4913 14.3205 16.4648 13.0032 15 12.625'
        stroke='#F9A51A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SiteIcon
