import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Label from '../label'
import OutlinedField from './outlinedField'
type Props = {
  label: string
  handleClickShowPassword: () => void
  showPassword: boolean
} & React.ComponentProps<typeof OutlinedField>
const PasswordField = ({
  label,
  sx,
  showPassword,
  handleClickShowPassword,
  required = false,
  ...rest
}: Props) => {
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  return (
    <div>
      <Label label={label} required={required} />
      <OutlinedField
        sx={{ width: '318px', ...sx }}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                sx={{
                  color: 'secondary.main',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </div>
  )
}

export default PasswordField
