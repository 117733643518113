import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react'
import CustomButton from './customButton'

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
interface Props {
  href: string
}
const ExternalLinkButton = ({ href }: Props) => {
  return (
        <IconButton target='blank' color='secondary' aria-label="delete" size="small" href={href}>
           <OpenInNewIcon fontSize="inherit" />
        </IconButton>
  )
}

export default ExternalLinkButton