import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import OpenCloseArrowIcon from '../../icons/openCloseArrowIcon'
import { Collapse } from '@mui/material'
interface Props {
  title: React.ReactNode
  children: React.ReactNode
}
const CustomDropdown = ({ title, children }: Props) => {
  const [open, setOpen] = useState(false)
  const handleToogle = () => setOpen((open) => !open)

  return (
    <Box component='section' sx={{ borderBottom: '1px solid #DADCDD', py: '20px', px: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: 'white',
          border: 'none',
          cursor: 'pointer',
        }}
        component='button'
        onClick={handleToogle}
      >
        <Typography>{title}</Typography>
        <OpenCloseArrowIcon open={open} />
      </Box>
      <Collapse in={open} timeout='auto' sx={{ mt: '20px' }} unmountOnExit>
        {children}
      </Collapse>
    </Box>
  )
}

export default CustomDropdown
