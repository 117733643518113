import { createTheme } from '@mui/material/styles'
declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string
    background?: string
  }

  interface SimplePaletteColorOptions {
    lighter?: string
    background?: string
  }
  interface TypographyVariants {
    smallText: React.CSSProperties
    captionSmall: React.CSSProperties
    modal: React.CSSProperties
    bodySmall: React.CSSProperties
    text: React.CSSProperties
    smallTextNormal: React.CSSProperties
    textBold : React.CSSProperties
  }
  interface TypographyVariantsOptions {
    smallText?: React.CSSProperties
    captionSmall?: React.CSSProperties
    modal?: React.CSSProperties
    bodySmall?: React.CSSProperties
    text?: React.CSSProperties
    smallTextNormal?: React.CSSProperties
    textBold?: React.CSSProperties
    bodyLarge?: React.CSSProperties
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    smallText: true
    captionSmall: true
    modal: true
    bodySmall: true
    smallTextNormal: true
    textBold: true
    bodyLarge: true
  }
}
const theme = createTheme({
  palette: {
    primary: {
      main: '#444D4E',
      light: '#5F686A',
      lighter: '#DADCDD',
      background: '#F1F1F2',
    },
    secondary: {
      main: '#F9A51A',
    },
    error: {
      main: '#991314',
    },
  },
  typography: {
    fontFamily: ['DIN', 'sans-serif'].join(','),
    allVariants: {
      color: '#444D4E',
    },
    modal: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '500',
      color: '#444D4E',
      padding: '40px',
    },
    h1: {
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: '700',
    },
    h2: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: '700',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '500',
    },
    h4: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '500',
    },
    h5: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '1px',
    },
    h6: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18px',
    },
    smallText: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: '700',
      color: '#444D4E',
    },
    smallTextNormal: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: '400',
      color: '#444D4E',
      lineHeight: '20px',
    },
    body1: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '0 !important',
    },
    bodyLarge: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '400',
      letterSpacing: '1px',
    },
    captionSmall: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '500',
      color: '#444D4E',
    },
    bodySmall: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#444D4E',
    },
    text: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '24px',
    },
    textBold: {
      fontFamily: ['DIN', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '700',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: '8px',
          fontSize: '16px',
          border: '1px solid #DADCDD',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25) !important',
          borderRadius: '4px',
        },
        list: {
          padding: '16px',
          '& li': {
            fontFamily: 'Overpass',
            backgroundColor: 'white!important',
            padding: '0',
          },
          '& li:hover': {
            color: '#292929',
            fontWeight: '700',
          },
          '& li + li': {
            marginTop: '8px',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: () => ({
          width: 52,
          height: 32,
          padding: 0,
          margin: '0 0 0 12px !important',
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            top: '2px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(22px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#F9A51A',
                opacity: 1,
                border: 'none',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                backgroundColor: '#fff',
              },
              '& .MuiSwitch-thumb:before': {
                content: '""',
                height: '15px',
                width: '15px',
                display: 'block',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
                  '#444D4E',
                )}" d="M5.99998 10.7799L3.21998 7.9999L2.27332 8.9399L5.99998 12.6666L14 4.66656L13.06 3.72656L5.99998 10.7799Z"/></svg>')`,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
            boxShadow: 'none',
            backgroundColor: '#5F686A',
            border: '4px solid #fff;',
          },
          '& .MuiSwitch-track': {
            boxSizing: 'border-box',
            borderRadius: 32 / 2,
            opacity: 1,
            backgroundColor: '#fff',
            border: '2px solid #DADCDD',
            transition: theme.transitions.create(['background-color, borderColor'], {
              duration: 500,
            }),
          },
        }),
      },
    },
  },
})
export default theme
