const StarIcon = () => {
  return (
    <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.9987 10.5133L11.1187 12.9999L10.0254 8.31325L13.6654 5.15992L8.87203 4.74659L6.9987 0.333252L5.12536 4.74659L0.332031 5.15992L3.96536 8.31325L2.8787 12.9999L6.9987 10.5133Z'
        fill='#F9A51A'
      />
    </svg>
  )
}

export default StarIcon
