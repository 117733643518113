import { useMemo, useContext, useState } from 'react'
import useSelectedItemSideBar from '../../../hooks/useSelectedItemSideBar'
import TitlePage from '../../../components/common/titlePage'
import ContainedButton from '../../../components/common/buttons/containedButton'
import { t } from 'i18next'
import type { MRT_Cell, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { Box } from '@mui/material'
import { useGetUsers } from '../../../hooks/api/entreprises'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'
import { UserEntrepriseType } from '../../../types/api/entreprise'
import { useGetPostsTitle } from '../../../hooks/api/postsTitle'
import useIsGrantedToUpdate from '../../../hooks/useIsGrantedToUpdate'
import PlusIcon from '../../../icons/plusIcon'
import CustomModal from '../../../components/common/modals/customModal'
import BodyModalConfirmDeleteUser from './components/bodyModalConfirmDeleteUser'
import BodyModalConfirmResendInvitation from './components/bodyModalConfirmResendInvitation'
import BodyModalConfirmSendInvitations from './components/bodyModalConfirmSendInvitations'
import BodyModalUpdateUser from './components/bodyModalUpdateUser'
import BodyModalInviteUsers from './components/bodyModalInviteUsers'
import CustomTable from '../../../components/common/customTable'
import ActionColumn from './components/actionColumn'
import {
  customContactColumn,
  customFullNameColumn,
  customHeaderColumn,
  customListColumn,
  customRoleColumn,
  customTitleColumn,
} from './components/columns'

const styles = {
  inviteUsers: {
    top: '97px',
    transform: 'translateX(-50%)',
    width: '86%',
    maxHeight: '70%',
    overflowY: 'scroll',
    minHeight: 'initial',
  },
  updateUser: {
    width: '770px',
    top: '50%',
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
      marginBottom: '20px',
      textAlign: 'center',
      width: '100%',
    },
  },
  confirmDeleteUser: {},
  confirmResendInvitation: {},
  confirmSendInvitations: {},
}

export type SelectedModal =
  | 'confirmDeleteUser'
  | 'confirmResendInvitation'
  | 'confirmSendInvitations'
  | 'inviteUsers'
  | 'updateUser'
const Users = () => {
  useSelectedItemSideBar('entreprise.users')
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data: usersList } = useGetUsers(activeEnterprise.crm_id)
  const [openModal, setOpenModal] = useState(false)
  const [selectedModal, setSelectedModal] = useState<SelectedModal | null>(null)
  const [selectedUser, setSelectedUser] = useState<UserEntrepriseType | undefined>(undefined)
  const handleOpenModal = (selectedModalParam: SelectedModal) => {
    setOpenModal(true)
    setSelectedModal(selectedModalParam)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedModal(null)
  }
  const { data: listTitles } = useGetPostsTitle()

  const columns = useMemo<MRT_ColumnDef<UserEntrepriseType>[]>(
    () => [
      {
        accessorKey: 'first_name',
        header: 'Nom',
        Header: () => customHeaderColumn(),
        size: 22,
        maxSize: 22,
        minSize: 22,
        columnDefType: 'display',
        Cell: ({ row }) => customFullNameColumn(row.original.first_name, row.original.last_name),
      },
      {
        accessorKey: 'title',
        header: 'Titre',
        size: 23,
        maxSize: 23,
        minSize: 23,
        columnDefType: 'display',
        Cell: ({ row }) => customTitleColumn(row.original.title, listTitles),
      },
      {
        accessorKey: 'contact',
        header: 'Contact',
        size: 22,
        maxSize: 22,
        minSize: 22,
        columnDefType: 'display',
        Cell: ({ row }) =>
          customContactColumn(
            row.original.email,
            row.original.contact.phone_number,
            row.original.contact.phone_number_post,
          ),
      },
      {
        accessorKey: 'roles',
        header: 'Rôle',
        Header: () => customRoleColumn(),
        size: 18,
        maxSize: 18,
        minSize: 18,
        columnDefType: 'display',
        Cell: ({ row }) => customListColumn(row.original.roles),
      },
      {
        accessorKey: 'status',
        header: 'Statut',
        size: 9,
        maxSize: 9,
        minSize: 9,
        columnDefType: 'display',
        Cell: ({ row }) => t(`common.status.${row.original.status}`),
      },
    ],
    [],
  )
  const isGrantedToUpdate = useIsGrantedToUpdate()
  const actionPropsTable = useMemo(
    () =>
      isGrantedToUpdate
        ? {
            renderRowActions: (props: {
              cell: MRT_Cell<UserEntrepriseType>
              row: MRT_Row<UserEntrepriseType>
              table: MRT_TableInstance<UserEntrepriseType>
            }) => {
              return (
                <ActionColumn
                  user={props.row.original}
                  setSelectedUser={setSelectedUser}
                  handleOpenModal={handleOpenModal}
                />
              )
            },
          }
        : {},
    [isGrantedToUpdate, setSelectedUser, setSelectedModal, setOpenModal],
  )

  return (
    <>
      <TitlePage
        title={t('pages.users.title')}
        callAction={
          <>
            {isGrantedToUpdate && (
              <ContainedButton
                title={t('pages.users.addUser')}
                handleClick={() => handleOpenModal('inviteUsers')}
                icon={
                  <Box
                    sx={{
                      ' & svg path': {
                        fill: '#fff',
                      },
                    }}
                  >
                    <PlusIcon />
                  </Box>
                }
              ></ContainedButton>
            )}
          </>
        }
        textDesc={t('pages.users.description')}
      />

      <CustomTable
        data={usersList ?? []}
        columns={columns}
        muiTableBodyRowProps={{
          hover: false,
        }}
        muiTableBodyCellProps={{
          sx: {
            p: '20px 0px',
            borderBottom: '1px solid #DADCDD',
            height: '70px',
            alignItems: 'flex-start',
          },
        }}
        positionActionsColumn='last'
        enableRowActions
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            size: 6,
            maxSize: 6,
            minSize: 6,
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
        }}
        state={{ isLoading: usersList ? false : true }}
        {...actionPropsTable}
      />

      {isGrantedToUpdate && (
        <>
          <CustomModal
            open={openModal}
            handleClose={handleCloseModal}
            sx={selectedModal && selectedModal in styles ? styles[selectedModal] : {}}
          >
            {selectedModal === 'confirmDeleteUser' && (
              <BodyModalConfirmDeleteUser user={selectedUser} handleClose={handleCloseModal} />
            )}
            {selectedModal === 'confirmResendInvitation' && (
              <BodyModalConfirmResendInvitation handleClose={handleCloseModal} />
            )}
            {selectedModal === 'confirmSendInvitations' && (
              <BodyModalConfirmSendInvitations handleClose={handleCloseModal} />
            )}
            {selectedModal === 'inviteUsers' && (
              <BodyModalInviteUsers
                handleClose={handleCloseModal}
                handleOpenSendInvitation={() => handleOpenModal('confirmSendInvitations')}
              />
            )}
            {selectedModal === 'updateUser' && (
              <BodyModalUpdateUser user={selectedUser} handleClose={handleCloseModal} />
            )}
          </CustomModal>
        </>
      )}
    </>
  )
}
export default Users
