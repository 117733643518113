import * as Yup from 'yup'
export const validationRuleSimpleEmail = Yup.string().email(
  'Veuillez inscrire une adresse courriel valide du type exemple@nomdedomaine.ca',
)
export const validationRuleEmail = Yup.string()
  .email('Veuillez inscrire une adresse courriel valide du type exemple@nomdedomaine.ca')
  .required('Veuillez inscrire une adresse courriel valide du type exemple@nomdedomaine.ca')
export const validationRuleFieldPhone = Yup.string().matches(/^\d{3} \d{3}-\d{4}$/, {
  message:
    'Veuillez inscrire un numéro de téléphone en respectant le format suivant : 123 123-1234',
  excludeEmptyString: true,
})
export const validationRuleFirstNameField = Yup.string().required('Veuillez inscrire votre prénom')
export const validationRuleLastNameField = Yup.string().required(
  'Veuillez inscrire votre nom de famille',
)
export const validationRuleTitleField = Yup.string().required(
  'Veuillez faire un choix dans la liste déroulante',
)
export const validationRulePhoneComponent = Yup.object().shape({
  phone_number: validationRuleFieldPhone.required(
    'Veuillez inscrire un numéro de téléphone en respectant le format suivant : 123 123-1234',
  ),
  type: Yup.string().required('Obligatoire'),
  phone_number_post: Yup.string().nullable(),
})
export const contactSchema = Yup.object().shape({
  first_name: Yup.string().required('Veuillez inscrire votre prénom'),
  last_name: Yup.string().required('Veuillez inscrire votre nom de famille'),
  email: validationRuleEmail,
  mainPhone: Yup.object().shape({
    phone_number: validationRuleFieldPhone.required(
      'Veuillez inscrire un numéro de téléphone en respectant le format suivant : 123 123-1234',
    ),
    type: Yup.string().required('Obligatoire'),
    phone_number_post: Yup.string().nullable().max(6, 'Le champ Poste doit contenir un maximun de 6 chiffres'),
  }),
  otherPhone: Yup.object().shape({
    phone_number: validationRuleFieldPhone,
    type: Yup.string(),
    phone_number_post: Yup.string().nullable().max(6, 'Le champ Poste doit contenir un maximun de 6 chiffres'),
  }),
})
export const userMemberSchema = Yup.object().shape({
  first_name: Yup.string().required('Veuillez inscrire votre prénom'),
  last_name: Yup.string().required('Veuillez inscrire votre nom de famille'),
  email: validationRuleEmail,
  contact: Yup.object().shape({
    phone_number: validationRuleFieldPhone.required(
      'Veuillez inscrire un numéro de téléphone en respectant le format suivant : 123 123-1234',
    ),
    phone_number_post: Yup.string()
      .nullable()
      .max(6, 'Ce champ ne peut contenir que des chiffres (maximum 6)'),
  }),
  title: Yup.string().required('Obligatoire'),
})

export const userEnterpriseSchema = Yup.object().shape({
  inviteList: Yup.array().of(
      Yup.object().shape({
        email: validationRuleEmail,
        first_name: Yup.string().required('Veuillez inscrire le prénom'),
        last_name: Yup.string().required('Veuillez inscrire le nom de famille'),
        roles: Yup.array().min(1, 'Au moins un rôle est requis')
      })
  )
});
