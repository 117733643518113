import React, { useContext, useState } from 'react'
import Divider from '@mui/material/Divider'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import FormikContext from '../../../../context/formikContext'
import { useFormik } from 'formik'
import { userMemberSchema } from '../../../../validations'
import { EnterprisesServerStateKeysEnum, useUpdateUser } from '../../../../hooks/api/entreprises'
import { ActiveEnterpriseContext } from '../../../../context/activeEnterpriseContext'
import { UserEntrepriseType } from '../../../../types/api/entreprise'
import { listRoles } from '../../../../constants'
import FieldWithLabel from '../../../common/fields/fieldWithLabel'
import OutlinedFieldFormik from '../../../formik/fields/outlinedFieldFormik'
import SelectFormik from '../../../formik/fields/selectFormik'
import SelectMultipleFormik from '../../../formik/fields/selectMultipleFormik'
import PhoneFieldFromik from '../../../formik/fields/phoneFieldFromik'
import PostFieldFormik from '../../../formik/fields/postFieldFormik'
import TooltipRole from '../../../../pages/entreprise/users/components/tooltipRole'
import SimpleButton from '../../../../components/common/buttons/simpleButton'
import ContainedButton from '../../../../components/common/buttons/containedButton'
import { useGetPostsTitle } from '../../../../hooks/api/postsTitle'
import { useQueryClient } from 'react-query'

interface Props {
  user?: UserEntrepriseType
  cancelFn: () => void
}
const UpdateUserForm = ({ user, cancelFn }: Props) => {
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data: listTitles } = useGetPostsTitle()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const query = useQueryClient()
  const formik = useFormik({
    initialValues: user ? user : ({} as UserEntrepriseType),
    enableReinitialize: true,
    validationSchema: userMemberSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true)
      await updateUserMember(
        {
          ...values,
          contact: { ...values.contact, type: 'work' },
          crm_company_id: activeEnterprise.crm_id,
        },
        {
          onSuccess: async () => {
            await query.invalidateQueries(EnterprisesServerStateKeysEnum.Users)
            cancelFn()
          },
          onSettled: () => {
            setIsSubmitting(false)
          },
        },
      )
    },
  })

  const { mutate: updateUserMember } = useUpdateUser(formik)
  return (
    <FormikContext.Provider value={formik}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px 0' }}>
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <FieldWithLabel label='Prénom' required sx={{ width: '50% ' }}>
            <OutlinedFieldFormik name='first_name' placeholder='Prénom' />
          </FieldWithLabel>
          <FieldWithLabel label='Nom' required sx={{ width: '50% ' }}>
            <OutlinedFieldFormik name='last_name' placeholder='Nom' />
          </FieldWithLabel>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px 0' }}>
          <Box sx={{ display: 'flex' }}>
            <FieldWithLabel sx={{ width: 'calc(50% - 12px)' }} label='Titre' required>
              <SelectFormik
                name='title'
                placeholder='Titre'
                fullWidth
                required
                listItems={listTitles || []}
              />
            </FieldWithLabel>
          </Box>
          <FieldWithLabel label='Courriel' required sx={{ width: 'calc(50% - 12px)' }}>
            <OutlinedFieldFormik name='email' placeholder='Courriel' />
          </FieldWithLabel>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <FieldWithLabel label='Téléphone' required sx={{ width: 'calc(50% - 12px)' }}>
              <PhoneFieldFromik name='contact.phone_number' />
            </FieldWithLabel>
            <FieldWithLabel label='Poste' sx={{ width: '90px' }}>
              <PostFieldFormik name='contact.phone_number_post' />
            </FieldWithLabel>
          </Box>
          <Box sx={{ width: 'calc(50% - 12px)' }}>
            <Typography
              component='div'
              variant='smallText'
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <label>Rôles</label>
              <TooltipRole />
            </Typography>
            <SelectMultipleFormik
              name='roles'
              placeholder='Role'
              disabledItems={['Utilisateur']}
              listItems={listRoles}
              sx={{ maxWidth: '469px' }}
            />
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: '32px' }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '36px' }}>
        <SimpleButton sx={{ py: '0' }} handleClick={cancelFn} />
        <ContainedButton
          title='Confirmer'
          sx={{ py: '0' }}
          disabled={isSubmitting}
          handleClick={() => {
            formik.handleSubmit()
          }}
        />
      </Box>
    </FormikContext.Provider>
  )
}

export default UpdateUserForm
