import BodyModalWithActions from '../../../../components/common/modals/bodyModalWithActions'
import UpdateDateInscription from './UpdateDateInscription'
import {FormationOtherDatesType} from '../../../../types/api/formations'




interface Props {
  status: string
  dataDates : FormationOtherDatesType[] | undefined
  handleClose: () => void,
  oldTrainingId : string
  isRefreshed: () => void
  isRefreshedData : ()=> void
}
const BodyModalUpdateDates = ({ dataDates, handleClose, status, oldTrainingId, isRefreshed, isRefreshedData }: Props) => {
  return (
    <BodyModalWithActions
    title=''
      content={<UpdateDateInscription dataDates={dataDates} cancelFn={handleClose} status={status} oldTrainingId={oldTrainingId}
       isRefreshed={isRefreshed}
       isRefreshedData={isRefreshedData}
       />}
    />
  )
}

export default BodyModalUpdateDates
