import { AxiosConfigType } from '../types'
import {
  Preference,
  Session,
  UpdatePassword,
  UpdatePreference,
  UpdateUser,
  User,
} from '../types/api/user'
import Axios from './index'
const basePath = '/user'
const findUserById = async (config?: AxiosConfigType) => {
  const response = await Axios.get<User>(`${basePath}/profile`, config)
  return response.data
}
const getPreferences = async (config?: AxiosConfigType) => {
  const response = await Axios.get<Preference>(`${basePath}/preferences`, config)
  return response.data
}
const updatePreferences = async (data: UpdatePreference, config?: AxiosConfigType) => {
  return Axios.patch<any>(`${basePath}/preferences`, data, config)
}
const getSessionById = async (config?: AxiosConfigType) => {
  const response = await Axios.get<Session>(`${basePath}/session`, config)
  if (response.data.is_member && response.data?.members.length > 0) {
    const { members, ...rest } = response.data
    return {
      ...rest,
      members: members.sort((m1, m2) => {
        const firstMemeberName = m1.legal_name.toUpperCase()
        const secondMemeberName = m2.legal_name.toUpperCase()

        if (firstMemeberName < secondMemeberName) {
          return -1
        }
        if (firstMemeberName > secondMemeberName) {
          return 1
        }
        return 0
      }),
    }
  }
  return response.data
}

const updateSession = async (active_member_id: string | null, config?: AxiosConfigType) => {
  return Axios.post<any>(`${basePath}/session`, { active_member_id }, config)
}
const updateUser = async (data: UpdateUser, config?: AxiosConfigType) => {
  return Axios.put<any>(`${basePath}/profile`, data, config)
}

const updateUserPassword = async (data: UpdatePassword, config?: AxiosConfigType) => {
  return await Axios.put<any>(`${basePath}/password`, data, config)
}

const UserApi = {
  findUserById,
  updateUser,
  updateUserPassword,
  getSessionById,
  getPreferences,
  updatePreferences,
  updateSession,
}

export default UserApi
