import Typography from '@mui/material/Typography'
import { MRT_ColumnDef } from 'material-react-table'
import { useContext, useMemo } from 'react'
import CustomTable from '../../../../components/common/customTable'
import Box from '@mui/material/Box'
import StarIcon from '../../../../icons/starIcon'

import { DocumentType } from '../../../../types/api/entreprise'
import { getFormatDate } from '../../../../utils'
import useDownloadDocument from '../../../../hooks/api/useDownloadDocument'
import useOpenDocument from '../../../../hooks/api/useOpenDocument'
import DownloadButton from '../../../../components/common/buttons/downloadButton'
import { ActiveEnterpriseContext } from '../../../../context/activeEnterpriseContext'
import { t } from 'i18next'
interface Props {
  propsTable: Omit<React.ComponentProps<typeof CustomTable>, 'columns'>
  text: React.ReactNode
  type: 'acq' | 'enterprise'
}
const getActions = (type: 'acq' | 'enterprise') => {
  const Actions = ({ row }: any) => {
    const { activeEnterprise } = useContext(ActiveEnterpriseContext)
    const [downloadDocument] = useDownloadDocument(
      type === 'acq'
        ? `/files/download/${row.original.crm_id}/doc-acq`
        : `/files/download/${row.original.crm_id}/member/${activeEnterprise.crm_id}`,
      row.original.title,
    )

    return <DownloadButton onClick={downloadDocument} />
  }
  Actions.displayName = 'Actions'
  return Actions
}

const NameTitle = () => <Box sx={{ ml: '39px', my: '0' }}>Document</Box>
const getCategoryComponent = (type: 'acq' | 'enterprise') => {
  const Category = ({ row }: any) => (
    <Typography variant='smallTextNormal'>
      {type === 'acq' ? row.original.category : t(`common.${row.original.category}`)}
    </Typography>
  )
  Category.displayName = 'Category'
  return Category
}

const TypeBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>{row.original.type}</Typography>
)
const DateBody = ({ row }: any) => <p>{getFormatDate(row.original.created_at)}</p>

const getTitleComponent = (type: 'acq' | 'enterprise') => {
  const Title = ({ row }: any) => {
    const { activeEnterprise } = useContext(ActiveEnterpriseContext)
    const [openDocument] = useOpenDocument(
      type === 'acq'
        ? `/files/download/${row.original.crm_id}/doc-acq`
        : `/files/download/${row.original.crm_id}/member/${activeEnterprise.crm_id}`,
    )
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={openDocument}>
        <Box sx={{ ml: '10px', minWidth: '13px', minHeight: '13px', mr: '16px' }}>
          {row.original.favorite && <StarIcon />}
        </Box>
        <Typography
          variant='smallTextNormal'
          sx={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: '500' }}
        >
          {row.original.title}
        </Typography>
      </Box>
    )
  }
  Title.displayName = 'Title'
  return Title
}

const DocumentTab = ({ propsTable, text, type }: Props) => {
  const columns = useMemo<MRT_ColumnDef<DocumentType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Document',
        Header: NameTitle,
        size: 50,
        maxSize: 50,
        minSize: 50,
        Cell: getTitleComponent(type),
      },
      {
        accessorKey: 'category',
        header: 'Catégorie',
        size: 21,
        maxSize: 21,
        minSize: 21,
        Cell: getCategoryComponent(type),
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 12,
        enableSorting: false,
        maxSize: 12,
        minSize: 12,
        Cell: TypeBody,
      },
      {
        accessorKey: 'created_at',
        header: 'Date',
        maxSize: 7,
        minSize: 7,
        size: 7,
        Cell: DateBody,
      },
    ],
    [],
  )

  return (
    <>
      <Typography sx={{ my: '48px!important', width: '92%' }}>{text}</Typography>
      <CustomTable
        {...propsTable}
        columns={columns as MRT_ColumnDef<Record<string, any>>[]}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 10,
            maxSize: 10,
            minSize: 10,
            header: '',
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
        }}
        enableRowActions
        enableSorting={true}
        enableSortingRemoval={false}
        enableMultiSort={false}
        manualSorting
        positionActionsColumn='last'
        muiTableBodyRowProps={{ hover: false }}
        renderRowActions={getActions(type)}
      />
    </>
  )
}

export default DocumentTab
