const SimpleArrowRightIcon = () => {
  return (
    <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.332031 8.56214L3.89425 4.99992L0.332031 1.42992L1.4287 0.333252L6.09537 4.99992L1.4287 9.66659L0.332031 8.56214Z'
        fill='#444D4E'
      />
    </svg>
  )
}

export default SimpleArrowRightIcon
