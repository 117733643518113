import TextField from '@mui/material/TextField'

const OutlinedField = ({ sx, ...props }: React.ComponentProps<typeof TextField>) => {
  return (
    <TextField
      sx={{
        width: '100%',
        borderRadius: '4px',
        '& .MuiFormHelperText-root': {
          marginLeft: '0px',
        },
        '& .MuiInputBase-input': {
          padding: '10px 16px 10px 16px',
          color: 'primary.main',
          fontWeight: 400,
          fontSize: '1rem',
        },
        '& .MuiInputBase-input::placeholder': {
          color: 'primary.main',
          fontWeight: 400,
          fontSize: '1rem',
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'primary.lighter',
        },

        ' & .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'primary.light',
          borderWidth: '1px',
        },
        '& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: 'error.main',
        },

        ...sx,
      }}
      {...props}
    />
  )
}

export default OutlinedField
