import OutlinedFieldFormik from './outlinedFieldFormik'
import React from 'react'
import FormatedInput from '../../common/fields/formatedInput'
const AmountFieldFromik = (props: React.ComponentProps<typeof OutlinedFieldFormik>) => {
  return (
    <OutlinedFieldFormik
      placeholder='123 123'
      InputProps={{
        inputComponent: FormatedInput as any,
      }}
      inputProps={{
        mask: Number,
        thousandsSeparator: ' ',
        scale: 3,
        radix: ',',
        min: '0',
        normalizeZeros: true,
      }}
      {...props}
    />
  )
}

export default AmountFieldFromik
