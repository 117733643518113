import Axios from '.'
import { AxiosConfigType } from '../types'
import { MemberShipType} from '../types/api/memberShip'
import { generateParamsURL } from '../utils'

const updateMemberShip = async (data: MemberShipType, config?: AxiosConfigType) => {
  return await Axios.post<any>('/cart', data, config)
}



const MemberShipApi = {
    updateMemberShip
}

export default MemberShipApi