import {
    OrderDetailsType
  } from '../../types/api/order'
import OrderApi from '../../api/ordersApi'
import useCustomQuery from './useCustomQuery'
import { OptionsTypeReactQuery } from '../../types/api'


  export const useGetOrdersQuery = (
    order_id: string | undefined,
    options?: OptionsTypeReactQuery<OrderDetailsType>,
  ) =>
    useCustomQuery<OrderDetailsType, Error>(
      ['order', order_id],
      async (header) => await OrderApi.getOrderData(order_id, header),
      options,
  )