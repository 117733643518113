import CustomTabs from '../../components/common/CustomTabs'
import MembershipTab from '../../components/membershipTab'
import GeneralForm from '../../components/forms/entrepriseProfile/generalForm'
import ContactsForm from '../../components/forms/entrepriseProfile/contactsForm'

import { t } from 'i18next'

import TabLoader from '../../components/common/tabLoader'
import useEntrepriseProfile from '../../hooks/pages/entreprise/useEntrepriseProfile'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { useContext } from 'react'
import Title from '../../components/common/title'

const EntrepriseProfile = () => {
  const { generalTab, contactsTab, membershipTab } = useEntrepriseProfile()
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  return (
    <>
      <Title>
        {t('pages.entrepriseProfile.title')}
      </Title>
      <CustomTabs
        list={[
          {
            label: t('pages.entrepriseProfile.tabs.general'),
            element: (
              <TabLoader isLoading={generalTab.isloading}>
                <GeneralForm {...generalTab.generalFormProps} />
              </TabLoader>
            ),
            width: '176px',
          },
          {
            label: t('pages.entrepriseProfile.tabs.contacts'),
            element: (
              <TabLoader isLoading={contactsTab.isloading}>
                <ContactsForm
                  key={activeEnterprise?.crm_id || ''}
                  {...contactsTab.contactsFormProps}
                />
              </TabLoader>
            ),
            width: '199px',
          },
          {
            label: t('pages.entrepriseProfile.tabs.membership'),
            element: (
              <TabLoader isLoading={membershipTab.isloading}>
                <MembershipTab data={membershipTab.data} />
              </TabLoader>
            ),
            width: '199px',
          },
        ]}
      />
    </>
  )
}

export default EntrepriseProfile
