import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ActiveEnterpriseContext } from '../context/activeEnterpriseContext'
import { isAnyRoleGranted } from '../utils'
import { authorization } from '../constants'

const useIsGrantedToUpdate = () => {
  const location = useLocation()
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  return isAnyRoleGranted(
    activeEnterprise?.roles ?? null,
    authorization[location.pathname]?.write ?? null,
  )
}

export default useIsGrantedToUpdate
