import { useAuth0 } from '@auth0/auth0-react'
import { QueryKey, UseQueryOptions, useQuery } from 'react-query'
import { AxiosConfigType } from '../../types'

const useCustomQuery = <TData, TError>(
  queryKey: QueryKey,
  queryFn: (config: AxiosConfigType) => Promise<TData>,
  options?: Omit<UseQueryOptions<TData, TError, TData, QueryKey>, 'queryKey' | 'queryFn'>,
) => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery<TData, TError>(
    queryKey,
    async () => {
      const token = await getAccessTokenSilently()
      return queryFn({ headers: { Authorization: `Bearer ${token}` } })
    },
    options,
  )
}

export default useCustomQuery
