import axios from 'axios'
import i18n from 'i18next'

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    'Content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
    'Accept-Language': i18n.language ?? 'fr',
  },
})

export const Mock = axios.create({
  baseURL: 'https://bd880442-fd00-43eb-baaf-c2ce41fe9cc2.mock.pstmn.io',
  headers: {
    'Content-type': 'application/json',
    Accept: '*/*',
    'x-api-key': 'PMAK-63e2cff5620bbc53d4ec91c1-5cbe4de1b2dde540471d423c62b0b3048c',
  },
})

export default instance
