import { Box, Grid, Typography } from '@mui/material'
import Title from '../../components/common/title'
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import useSelectedItemSideBar from '../../hooks/useSelectedItemSideBar'
import { useMemo, useState } from 'react'
import CustomTable from '../../components/common/customTable'
import OpenCloseArrowIcon from '../../icons/openCloseArrowIcon'
import {  useGetEventsQuery } from '../../hooks/api/events'
import {EventsType, activities} from '../../types/api/events'
import {getDate} from '../../utils'

const MyEvents = () => {

  const [selectedTitle, setSelectedTitle] = useState('')
  const [page, setPage] = useState('1')
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id:'start_date', desc: true }])
  const {data , isFetching} = useGetEventsQuery({
    page,
    sort: sorting[0],
  })

  useSelectedItemSideBar('events.myEvents');

  const DateTitle = () => <Box sx={{ pl: '15px' }}>Date</Box>
  const DateBody = ({ row }: any) => (
    <Typography variant='smallTextNormal' pl='15px'>
      {getDate(row.original.start_date, row.original.end_date)}
    </Typography>
  )
  const TitleBody = ({ row }: any) => (
     <Typography
        variant='h6'
        component='a'
        href={`${process.env.REACT_APP_ACQ_SITE}/entrepreneurs/evenements/${row.original.crm_id}`}
          target='_blank'
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
       >
        {row.original.title}
       </Typography>
  )
  const DetailPanel = ({ row }: any) => (
    <Box           
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection:'column',
      gap:'60px',
      width: '100%',
    }}>
    {row.original.activities && row.original.activities.map((item: activities, index: number) => (
          <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
            <Box width='23%' sx={{ display: 'flex' }}>
                <Typography component='ul' sx={{ display: 'flex', flexDirection: 'column' , gap:'10px', paddingInlineStart: 0, listStyle: 'none' }}>
                  <Typography component='li'  variant='h6'>
                    {item.title}
                  </Typography>
                </Typography>
            </Box>
            {item.participants && 
            <Box width='60%' sx={{ display: 'flex' }}>
                  <Typography component='ul' sx={{ 
                    columns: '2',
                    gap:'10px',
                    paddingInlineStart: 0,
                    justifyContent: 'space-between',
                    width: '100%', listStyle: 'none'  }} >
                    {item.participants && item.participants.map((participant, index)=> {
                       return  (<Typography key={index} component='li'  variant='smallTextNormal'>
                              {participant}
                            </Typography>)
                    })
                    }
              </Typography>
            </Box>
            }
        </Box>
    ))}  

  </Box>
  )
  const ExpandBody = ({ row }: any) => (
    <Box
      onClick={() => row.toggleExpanded()}
      sx={{
        mr: '12px',
        cursor: 'pointer',
        p: '5px',
        display: 'flex',
        alignItems: 'center',
        gap: '9px',
      }}
    >
      <Typography variant='smallTextNormal'>Voir les participants</Typography>
      <OpenCloseArrowIcon open={row.getIsExpanded()} />
    </Box>
  )
  const columns = useMemo<MRT_ColumnDef<EventsType>[]>(
    () => [
      {
        accessorKey: 'start_date',
        header: 'Date',
        Header: DateTitle,
        maxSize: 29,
        minSize: 29,
        size: 29,
        enableSorting: true,
        Cell: DateBody,
      },

      {
        accessorKey: 'title',
        header: 'événement',
        size: 54,
        enableSorting: true,
        maxSize: 54,
        minSize: 54,
        Cell: TitleBody,
      },
    ],
    [],
  )

  return (
    <>
      <Title sx={{ mb: '48px' }}>mes événements</Title>
      <CustomTable
        muiTableBodyRowProps={({ row, isDetailPanel }) => ({
          sx: {
            ...(isDetailPanel &&
              !row.getIsExpanded() && {
                height: '0px',
                overflow: 'hidden',
              }),
          },
          hover: false,
        })}
        data={data?.data??[]}
        columns={columns}
        enableSorting={true}
        enableExpandAll={false}
        renderDetailPanel={DetailPanel}
        displayColumnDefOptions={{
          'mrt-row-expand': {
            size: 41,
            maxSize: 41,
            minSize: 41,
            header: '',
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                px: '0',
                justifyContent: 'end',
                borderBottom: '1px solid #DADCDD',
                ...(row.getIsExpanded() ? { borderBottom: 'none' } : {}),
              },
            }),
            Cell: ExpandBody,
          },
        }}
        muiTableDetailPanelProps={({ row }) => ({
          sx: {
            width: '100%',
            pt: '3px',
            '& .MuiCollapse-root': {
              width: '100%',
            },
            ...(row.getIsExpanded() ? { pb: '36px' } : {}),
          },
        })}
        state={{ sorting, isLoading: isFetching }}
        enableExpanding
        enableSortingRemoval={false}
        enableMultiSort={false}
        manualSorting
        propsPagination={{
          page: Number(page),
          count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
          onChange: (e, index) => {
            setPage(index.toString())
          },
        }}
        onSortingChange={(params) => {
          setPage('1')
          setSorting(params)
        }}
      />
    </>
  )
}

export default MyEvents
