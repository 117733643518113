import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sideBarFr from './fr/sibebar.json'
import pagesFr from './fr/pages.json'
import commonFr from './fr/common.json'
import provincesFr from './fr/provinces.json'
i18n.use(initReactI18next).init({
  fallbackLng: 'fr',
  lng: 'fr',
  debug: true,
  resources: {
    en: { translation: { title: 'hello' } },
    fr: {
      translation: {
        sideBar: sideBarFr,
        pages: pagesFr,
        common: commonFr,
        provinces: provincesFr,
        title: 'informations personnelles',
      },
    },
  },
})
