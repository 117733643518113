import { Typography, SxProps, Theme } from '@mui/material'
interface Props {
  children: React.ReactNode
  sx?: SxProps<Theme>
}
const Title = ({ children, sx }: Props) => {
  return (
    <Typography variant='h1' sx={{ textTransform: 'uppercase', mb: '55px', ...sx }}>
      {children}
    </Typography>
  )
}

export default Title
