import { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Axios from '../../api'
import { ErrorModalContext } from '../../context/errorModalContext'

const useDownloadDocument = (url: string, fileName: string) => {
  const { setShowModal, setErrorMessage } = useContext(ErrorModalContext)
  const { getAccessTokenSilently } = useAuth0()
  const handleDownload = async () => {
    const token = await getAccessTokenSilently()
    try {
      const response = await Axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
      const downloadUrl = window.URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      setShowModal(true)
      setErrorMessage('Erreur lors du téléchargement.')
    }
  }
  return [handleDownload]
}

export default useDownloadDocument
