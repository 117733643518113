const ThreeDotsIcon = () => {
  return (
    <svg width='4' height='18' viewBox='0 0 4 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 4.76735C3.1 4.76735 4 3.78105 4 2.57557C4 1.37009 3.1 0.383789 2 0.383789C0.9 0.383789 0 1.37009 0 2.57557C0 3.78105 0.9 4.76735 2 4.76735ZM2 6.95913C0.9 6.95913 0 7.94543 0 9.15091C0 10.3564 0.9 11.3427 2 11.3427C3.1 11.3427 4 10.3564 4 9.15091C4 7.94543 3.1 6.95913 2 6.95913ZM0 15.7263C0 14.5208 0.9 13.5345 2 13.5345C3.1 13.5345 4 14.5208 4 15.7263C4 16.9317 3.1 17.918 2 17.918C0.9 17.918 0 16.9317 0 15.7263Z'
        fill='#F9A51A'
      />
    </svg>
  )
}

export default ThreeDotsIcon
