import React from 'react'
import Button from '@mui/material/Button'
import { SxProps, Theme } from '@mui/material'
interface Props {
  handleClick: () => void
  sx?: SxProps<Theme>
  variant?: 'principal' | 'secondary'
  title: string
  icon?: React.ReactNode
  disabled?: boolean
}
const stylesPrincipal = {
  backgroundColor: 'secondary.main',
  py: '11px',
  fontSize: '15px',
  color: 'white',
  minWidth: '50px',
  minHeight: '50px',
  '&:hover': {
    backgroundColor: 'primary.main',
    boxShadow: 'none',
  },
}
const stylesSecondary = {
  backgroundColor: 'white',
  py: '10px',
  fontSize: '12px',
  minHeight: '16px',
  border: '2px solid #444D4E',
  color: 'primary.main',
  '&:hover': {
    backgroundColor: 'primary.main',
    color: 'white',
    boxShadow: 'none',
  },
}
const ContainedButton = ({
  handleClick,
  sx,
  title,
  variant = 'principal',
  icon,
  disabled = false,
}: Props) => {
  return (
    <Button
      variant='contained'
      sx={{
        px: '30px',

        fontWeight: '700',
        borderRadius: '0px',

        letterSpacing: '1px',
        boxShadow: 'none',

        ...(variant === 'principal' ? stylesPrincipal : stylesSecondary),
        ...sx,
      }}
      onClick={handleClick}
      {...(icon && { startIcon: icon })}
      disabled={disabled}
    >
      <span>{title}</span>
    </Button>
  )
}

export default ContainedButton
