import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomModal from './customModal'
import ContainedButton from '../buttons/containedButton'
import { t } from 'i18next'
import ExclamationPointIcon from '../../../icons/exclamationPointIcon'

interface Props {
  open: boolean
  errorTitle: string
  errorDescription?: string | null
}

export default function BlockedUserModal({ open, errorTitle, errorDescription }: Props) {
  const acqSite = process.env.REACT_APP_ACQ_SITE as string
  return (
    <CustomModal open={open} handleClose={() => window.location.replace(acqSite)}>
      <Box sx={{ mb: '28px' }}>
        <ExclamationPointIcon />
      </Box>
      <Typography variant='modal' sx={{ textAlign: 'center' }}>
        {errorTitle}
      </Typography>
      <Typography sx={{ mb: '30px', textAlign: 'center', width: '413px', lineHeight: '24px' }}>
        {errorDescription === 'user is blocked' ? t('common.accountBlocked') : t('common.tryLater')}
        {t('common.contactAdmin')}
      </Typography>
      <Box sx={{ display: 'flex', gap: '36px', alignItems: 'center', mt: '24px' }}>
        <ContainedButton
          handleClick={() => window.location.replace(acqSite)}
          variant='secondary'
          title={t('common.backHomePage')}
        />
      </Box>
    </CustomModal>
  )
}
