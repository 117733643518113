import  Axios  from './index'
import {AxiosConfigType, Option} from '../types';

const basePath = '/posts-title'
const findAll = async (config?: AxiosConfigType) => {
  const response = await Axios.get<Option[]>(`${basePath}`, config)
  return response.data
}
const PostTitleApi = {
  findAll,
}

export default PostTitleApi
