import React from 'react'

const EditIcon = () => {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.71 3.63006C18.1 4.00006 18.1 4.65006 17.71 5.04006L15.87 6.87006L12.12 3.12006L13.96 1.29006C14.35 0.900059 15 0.900059 15.37 1.29006L17.71 3.63006ZM0 19.0001V15.2501L11.06 4.18006L14.81 7.93006L3.75 19.0001H0Z'
        fill='white'
      />
    </svg>
  )
}

export default EditIcon
