import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'
import { Phone } from '../../../types'
import { ContactType, GeneralDataType, UpdateGeneralDataType } from '../../../types/api/entreprise'
import {
  useGetContacts,
  useGetGeneralData,
  useGetMembershipData,
  useUpdateGeneralData,
} from '../../api/entreprises'
import useSelectedItemSideBar from '../../useSelectedItemSideBar'
import * as Yup from 'yup'
import { validationRuleEmail, validationRuleFieldPhone } from '../../../validations'
import useBlocker from '../../useBlocker'
export interface ContactFormType {
  crm_id: string
  salutation: string
  first_name: string
  last_name: string
  email: string
  title: string
  is_main_contact: boolean
  mainPhone: Phone
  otherPhone: Phone
}

const useGeneralForm = (activeEnterprise: string) => {
  const [isEditable, setIsEditable] = useState(false)
  const { data: generalData, refetch, isLoading } = useGetGeneralData(activeEnterprise)

  const formik = useFormik<UpdateGeneralDataType>({
    initialValues: {
      ...(generalData ? generalData : ({} as GeneralDataType)),
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      reach_us: Yup.object().shape({
        email: validationRuleEmail,
        phone_number: validationRuleFieldPhone.required('Obligatoire'),
        toll_free_phone_number: validationRuleFieldPhone,
        fax: validationRuleFieldPhone,
        website: Yup.string().url(
          'Veuillez inscrire une adresse valide du type https://www.nomdedomaine.ca',
        ),
      }),
    }),
    onSubmit: async (values) => {
      const data = {
        about: {
          usual_name: values.about.usual_name,
          business_sectors: values.about.business_sectors,
          founding_date: values.about.founding_date,
          number_of_employees: values.about.number_of_employees,
          revenue: values.about.revenue,
        },
        reach_us: values.reach_us,
      }
      await updateGeneralData(
        { id: activeEnterprise, data },
        {
          onSuccess: async () => {
            await refetch()
            setIsEditable(false)
          },
        },
      )
    },
  })
  const { mutate: updateGeneralData } = useUpdateGeneralData(formik)
  const generalFormProps = { formik, isEditable, setIsEditable }
  return {
    generalFormProps,
    isLoading,
  }
}

const useContactsForm = (activeEnterprise: string) => {
  const [addContact, setAddContact] = useState(false)
  const { data: contacts, isFetching } = useGetContacts(activeEnterprise)
  const hasMainContact = contacts ? contacts?.some((contact) => contact.is_main_contact) : false
  const [selectedPrincipalContact, setSelectedPrincipalContact] = useState<number>(-1)
  useEffect(() => {
    setSelectedPrincipalContact(hasMainContact ? 0 : -1)
  }, [hasMainContact])
  const [dirty, setDirty] = useState(false)
  const contactsFormProps = {
    addContact,
    setAddContact,
    contacts: (contacts || []) as ContactType[],
    selectedPrincipalContact,
    setSelectedPrincipalContact,
    hasMainContact,
    setDirty,
  }
  return { contactsFormProps, isLoading: isFetching, dirty }
}
const useEntrepriseProfile = () => {
  useSelectedItemSideBar('entreprise.entrepriseProfile')
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)

  const { data: membership, isLoading: isLoadingMemebershipData } = useGetMembershipData(
    activeEnterprise.crm_id,
  )

  const { generalFormProps, isLoading: isLoadingGeneralFormData } = useGeneralForm(
    activeEnterprise.crm_id,
  )
  const {
    contactsFormProps,
    isLoading: isLoadingContactsData,
    dirty,
  } = useContactsForm(activeEnterprise.crm_id)

  useEffect(() => {
    if (activeEnterprise) {
      generalFormProps.setIsEditable(false)
    }
  }, [activeEnterprise])
  const { hasMainContact, selectedPrincipalContact } = contactsFormProps
  const contactIsDirty =
    dirty ||
    (hasMainContact && selectedPrincipalContact !== 0) ||
    (!hasMainContact && selectedPrincipalContact !== -1)
  useBlocker(generalFormProps.formik.dirty || contactIsDirty)

  return {
    generalTab: {
      isloading: isLoadingGeneralFormData,
      generalFormProps,
    },
    contactsTab: {
      isloading: isLoadingContactsData,
      contactsFormProps,
    },
    membershipTab: {
      isloading: isLoadingMemebershipData,
      data: membership,
    },
  }
}

export default useEntrepriseProfile
