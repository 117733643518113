const HelpIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.66669 10C1.66669 5.39765 5.39765 1.66669 10 1.66669C14.6024 1.66669 18.3334 5.39765 18.3334 10C18.3334 14.6024 14.6024 18.3334 10 18.3334C7.78988 18.3334 5.67027 17.4554 4.10746 15.8926C2.54466 14.3298 1.66669 12.2102 1.66669 10ZM10.8334 13.3334V15H9.16669V13.3334H10.8334ZM10 16.6667C6.32502 16.6667 3.33335 13.675 3.33335 10C3.33335 6.32502 6.32502 3.33335 10 3.33335C13.675 3.33335 16.6667 6.32502 16.6667 10C16.6667 13.675 13.675 16.6667 10 16.6667ZM6.66669 8.33335C6.66669 6.4924 8.15907 5.00002 10 5.00002C11.841 5.00002 13.3334 6.4924 13.3334 8.33335C13.3334 9.40245 12.675 9.97779 12.034 10.538C11.4259 11.0694 10.8334 11.5872 10.8334 12.5H9.16669C9.16669 10.9823 9.95179 10.3805 10.6421 9.85142C11.1835 9.43636 11.6667 9.06603 11.6667 8.33335C11.6667 7.41288 10.9205 6.66669 10 6.66669C9.07955 6.66669 8.33335 7.41288 8.33335 8.33335H6.66669Z'
        fill='#F9A51A'
      />
    </svg>
  )
}
export default HelpIcon
