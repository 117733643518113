import React from 'react'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SxProps, Theme } from '@mui/material'
type Props = { label: string | undefined; sx?: SxProps<Theme> } & React.ComponentProps<typeof Radio>
const RadioButton = ({ label, sx, ...rest }: Props) => {
  return (
    <FormControlLabel
      sx={{
        ml: '-1px',
        '& .Mui-disabled': {
          color: 'primary.light',
        },
        '&  .MuiFormControlLabel-label.Mui-disabled': {
          color: 'primary.light',
        },
        ...sx,
      }}
      control={
        <Radio
          sx={{
            p: '0',
            mr: '5px',
            color: 'primary.light',
            '& .MuiButtonBase-root-MuiRadio-root.Mui-disabled': {
              color: 'primary.light',
            },
            '& .MuiButtonBase-root-MuiRadio-root .Mui-disabled .MuiFormControlLabel-label': {
              color: 'primary.light',
            },
          }}
          color='secondary'
          {...rest}
        />
      }
      label={label}
    />
  )
}

export default RadioButton
