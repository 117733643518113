import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {getDate, getDay} from '../../utils'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '16px' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '45px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

 interface Schedule {
  date: string
  start_time: string
  end_time: string
 }
interface Panel {
    panel_id: string
    header : React.ReactNode
    schedules : Schedule[]
  }
interface Props {
    panels : Panel[]
    info?: string | null
  }
export default function CustomizedAccordions({panels, info}:Props) {
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
        {
                panels && 
                panels.map((panel, index)=> {
                    return <Accordion key={index} expanded={expanded === `panel-${index}`} onChange={handleChange(`panel-${index}`)}>
                    <AccordionSummary aria-controls={`panel-${index}d-content`} id={`panel-${index}d-header`} 
                    sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant='bodySmall' style={{width:'40%'}}>{panel.header}</Typography>
                      {info && <Typography style={{width:'30%'}}> {info} </Typography> }
                      <Typography variant='h6' style={{width:'20%', textAlign:'right'}} sx={{paddingRight:'10px'}}>Voir horaire</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <Typography variant='body1' component='ul'> 
                          {panel?.schedules?.map((schedule, index)=> {
                            return <Typography key={`schedule-${index}d-header`} variant='body1' component='li'> 
                              {`${getDate(schedule?.date, null )}, ${schedule?.start_time} à ${schedule?.end_time}`}
                            </Typography>
                            })}
                        </Typography>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                })  
                } 



    </div>
  );
}