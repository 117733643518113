import { Box, CircularProgress } from '@mui/material'

const LoaderPage = () => {
  return (
    <Box sx={{
      width: 'calc(100vw - 240px)',
      height: 'calc(100vh - 230px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <CircularProgress color='secondary' />
    </Box>
  )
}

export default LoaderPage
