import React from 'react'
import CustomButton from './customButton'
import LinkIcon from '../../../icons/linkIcon'
interface Props {
  href: string
  children: React.ReactNode
}
const LinkButton = ({ href, children }: Props) => {
  return (
    <CustomButton href={href} endIcon={<LinkIcon />}>
      {children}
    </CustomButton>
  )
}

export default LinkButton
