import {
    FormationType,
    FormationDetailsType,
    FormationOtherDatesType,
    UpdateInscriptionDate
  } from '../../types/api/formations'
import MemberShipApi from '../../api/memberShipApi'
import useCustomQuery from './useCustomQuery'
import { FormikProps } from 'formik'
import useCustomMutationWithFormik from '../useCustomMutationWithFormik'
import { OptionsTypeReactQuery, PaginationResponseSecondFormat, OptionalParamsApi } from '../../types/api'



 


export const useUpdateMemberShip = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(MemberShipApi.updateMemberShip, formik)
