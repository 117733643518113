import { Box, Stack, Typography } from '@mui/material'
import { ContactType } from '../../../types/api/entreprise'
import AddButton from '../../common/buttons/addButton'
import AddContactForm from './addContactForm'
import UpdateContactForm from './updateContactForm'
import { useEffect, useState } from 'react'
import useIsGrantedToUpdate from '../../../hooks/useIsGrantedToUpdate'

interface Props {
  contacts: ContactType[]
  addContact: boolean
  setAddContact: React.Dispatch<React.SetStateAction<boolean>>
  selectedPrincipalContact: number
  setSelectedPrincipalContact: React.Dispatch<React.SetStateAction<number>>
  setDirty: React.Dispatch<React.SetStateAction<boolean>>
  hasMainContact:boolean
}
const showPrincipalContactInFirstPlace = (contact1: ContactType, contact2: ContactType) => {
  if (contact1.is_main_contact) return -1
  if (!contact2.is_main_contact) return 1
  return 0
}
const ContactsForm = ({
  addContact,
  setAddContact,
  contacts,
  selectedPrincipalContact,
  setSelectedPrincipalContact,
  setDirty,
  hasMainContact,
}: Props) => {
  const sortedArray = [...contacts].sort(showPrincipalContactInFirstPlace)
  const [addContactIsDirty, setAddContactIsDirty] = useState(false)
  const [updateContactIsDirty, setUpdateContactIsDirty] = useState<boolean[]>(
    Array.from({ length: sortedArray.length }, () => false),
  )
  useEffect(() => {
    setDirty((addContact && addContactIsDirty) || updateContactIsDirty.some(Boolean))
  }, [addContactIsDirty, updateContactIsDirty, addContact])
  const isGrantedToUpdate = useIsGrantedToUpdate()
  return (
    <Box component='form' sx={{ pt: '48px', overflow: 'hidden' }}>
      <Stack spacing='27px'  mb='30px'>
        <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>
          Contacts
        </Typography>
        {sortedArray.map((contact, index) => (
          <UpdateContactForm
            key={contact.crm_id}
            contact={contact}
            index={index}
            selectedPrincipalContact={selectedPrincipalContact}
            setSelectedPrincipalContact={setSelectedPrincipalContact}
            setDirty={setUpdateContactIsDirty}
            hasMainContact={hasMainContact}
          />
        ))}
        {isGrantedToUpdate && (
          <>
            {addContact ? (
              <AddContactForm
                handleClose={() => setAddContact(false)}
                setDirty={setAddContactIsDirty}
              />
            ) : (
              <AddButton handleClick={() => setAddContact(true)} />
            )}
          </>
        )}
      </Stack>
    </Box>
  )
}

export default ContactsForm
