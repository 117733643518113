import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
interface Props {
  title: string
  textDesc?: string | null | React.ReactNode
  callAction?: React.ReactNode
}
const defaultStyle = {
  border: '1px solid #DADCDD',
  padding: '32px 64px 32px 32px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}
const Card = ({ title, callAction, textDesc }: Props) => {
  return (
    <Box sx={defaultStyle}>
      <Typography variant='h3' sx={{ textTransform: 'uppercase' }}>
        {title}
      </Typography>
      <Typography>{textDesc}</Typography>
      {callAction}
    </Box>
  )
}
export default Card
