const ResetIcon = () => {
  return (
    <svg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6006 6.3001C13.1006 4.8001 11.1006 4.0001 9.00064 4.0001V0.600098L4.00064 5.6001L9.00064 10.6001V6.0001C10.5006 6.0001 12.1006 6.6001 13.2006 7.7001C15.5006 10.1001 15.5006 13.9001 13.2006 16.2001C11.9006 17.5001 10.1006 18.1001 8.40064 17.9001L7.90064 19.9001C10.3006 20.2001 12.8006 19.5001 14.6006 17.6001C17.7006 14.5001 17.7006 9.4001 14.6006 6.3001ZM2.10064 7.9001C0.300639 11.0001 0.700639 15.0001 3.30064 17.6001C4.10064 18.4001 5.00064 19.0001 6.00064 19.4001L6.60064 17.4001C5.90064 17.1001 5.30064 16.7001 4.80064 16.2001C2.90064 14.4001 2.50064 11.6001 3.60064 9.4001L2.10064 7.9001Z'
        fill='#444D4E'
      />
    </svg>
  )
}

export default ResetIcon
