import { useContext } from 'react'
import PasswordField from '../../common/fields/passwordField'
import FormikContext from '../../../context/formikContext'
type Props = {
  name: string
  handleClickShowPassword: () => void
  showPassword: boolean
} & React.ComponentProps<typeof PasswordField>
const PasswordFieldFormik = ({ name, ...props }: Props) => {
  const formik = useContext(FormikContext)
  return (
    <PasswordField
      {...props}
      {...formik.getFieldProps(name)}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name] ? formik.errors[name] : ''}
    />
  )
}

export default PasswordFieldFormik
