import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F1F1F2',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #DADCDD',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.14)',
    fontSize: theme.typography.pxToRem(14),
    borderRadius: '4px',
    padding: '15px',
  },
}))

export default CustomTooltip
