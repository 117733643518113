import { useContext } from 'react'
import { UseMutationOptions } from 'react-query'
import { AxiosErrorData } from '../types/api'
import { FormikProps } from 'formik'
import useCustomMutation from './api/useCustomMutation'
import { AxiosConfigType } from '../types'
import { handleCustomErrorsFormik } from '../utils'
import { useNavigate } from 'react-router-dom'
import { ErrorModalContext } from '../context/errorModalContext'
import { handleApiErros } from './api/useHandleError'

const useCustomMutationWithFormik = <Response, Request>(
  queryFn: (data: Request, config?: AxiosConfigType) => Promise<any>,
  formik: FormikProps<any>,
  options?: UseMutationOptions<Response, AxiosErrorData, Request, unknown>,
) => {
  const navigate = useNavigate()
  const { setShowModal, setErrorMessage } = useContext(ErrorModalContext)
  return useCustomMutation(queryFn, {
    onError: (error: AxiosErrorData) => {
      if (error.response && error.response.status === 422) {
        handleCustomErrorsFormik(formik, error.response.data.errors)
        return
      }
      handleApiErros(error, setShowModal, setErrorMessage, navigate)
    },
    ...options,
  })
}

export default useCustomMutationWithFormik
