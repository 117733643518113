import Typography from '@mui/material/Typography'
import InviteUsersForm from '../../../../components/forms/entrepriseProfile/inviteUsersForm'
interface Props {
  handleClose: () => void
  handleOpenSendInvitation: () => void
}
export default function BodyModalInviteUsers({
  handleClose,
  handleOpenSendInvitation,
}: Props) {
  return (
    <>
      <Typography variant='h3' sx={{ mb: '24px' }}>
        Ajouter des utilisateurs
      </Typography>
      <Typography sx={{ mb: '40px!important', width: '90%' }}>
        Les utilisateurs recevront une invitation par courriel à se joindre à votre organisation sur
        ACQ.org et à se créer des accès. Ils apparaîtront « En attente » dans la liste tant qu’ils
        n’auront pas rempli leur inscription. Si aucun rôle est attribué, le rôle attribué par
        défaut sera « Utilisateur ». Il sera possible de le changer par la suite.
      </Typography>
      <InviteUsersForm
        handleClose={handleClose}
        handleOpenSendInvitation={handleOpenSendInvitation}
      />
    </>
  )
}
