import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Title from './title'
interface Props {
  title: string
  textDesc?: string | null | React.ReactNode
  callAction?: React.ReactNode
}
const TitlePage = ({ title, callAction, textDesc }: Props) => {
  return (
    <Grid sx={{ mb: '55px' }}>
      <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='flex-start'>
        <Title>
          {title}
        </Title>
        {callAction}
      </Stack>

      <Grid container>
        <Grid item xs={12} sm={9} sx={{ paddingRight: '20px' }}>
          <Typography>{textDesc}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TitlePage
