const SmallArrowUpIcon = () => {
  return (
    <svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.43908 6.09683L5.0013 2.53461L8.5713 6.09683L9.66797 5.00016L5.0013 0.333496L0.334635 5.00016L1.43908 6.09683Z'
        fill='#444D4E'
      />
    </svg>
  )
}

export default SmallArrowUpIcon
