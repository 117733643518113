const RemoteIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.5 15.8334H17.5'
        stroke='#F9A51A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='4.16797'
        y='5'
        width='11.6667'
        height='8.33333'
        rx='1'
        stroke='#F9A51A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default RemoteIcon
