import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material'
import EditButton from './buttons/editButton'
import ContainedButton from './buttons/containedButton'
import SimpleButton from './buttons/simpleButton'
import FormikContext from '../../context/formikContext'
import { useContext } from 'react'
import { EditableContext } from '../../context/editableContext'
interface Props {
  sx?: SxProps<Theme> 
  handleReset?: () => void
}
const EditButtons = ({ sx, handleReset }: Props) => {
  const formik = useContext(FormikContext)
  const { isEditable, setIsEditable } = useContext(EditableContext)
  return (
    <Box sx={{ ...sx }}>
      {isEditable ? (
        <Stack direction='row' spacing='36px'>
          <SimpleButton
            handleClick={() => {
              if (handleReset) handleReset()
              formik.resetForm()
              setIsEditable(false)
            }}
          />
          <ContainedButton
            handleClick={() => {
              formik.handleSubmit()
            }}
            title='sauvegarder'
          />
        </Stack>
      ) : (
        <EditButton handleClick={() => setIsEditable(true)} />
      )}
    </Box>
  )
}

export default EditButtons
