import { useContext, useEffect } from 'react'
import { ContextType, SidebarContext } from '../context/sideBarContext'


const useSelectedItemSideBar = (item: string) => {
  const { setSelectedItemSidebar } = useContext(SidebarContext) as ContextType
  useEffect(() => {
    setSelectedItemSidebar(item)
  }, [])
}

export default useSelectedItemSideBar
