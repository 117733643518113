import { useCallback } from 'react'
import { useBeforeUnload, unstable_usePrompt as usePrompt } from 'react-router-dom'
const message = 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
const useBlocker = (promptUser: boolean): void => {
  useBeforeUnload(
    useCallback(
      (e) => {
        if (promptUser) {
          e.preventDefault()
        }
      },
      [promptUser],
    ),
  )

  usePrompt({
    when: promptUser,
    message,
  })
}

export default useBlocker
