import { useMemo, useState, useContext } from 'react'
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import Title from '../../components/common/title'
import useSelectedItemSideBar from '../../hooks/useSelectedItemSideBar'
import CustomTable from '../../components/common/customTable'
import { Box, Typography } from '@mui/material'
import { useFormik } from 'formik'
import FormikContext from '../../context/formikContext'
import FieldWithLabel from '../../components/common/fields/fieldWithLabel'
import OutlinedFieldFormik from '../../components/formik/fields/outlinedFieldFormik'
import ExternalLinkButton from '../../components/common/buttons/externalLinkButton'
import ContainedButton from '../../components/common/buttons/containedButton'
import ResetButton from '../../components/common/buttons/resetButton'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { OrderType } from '../../types/api/entreprise'
import {  useGetOrdersQuery } from '../../hooks/api/entreprises'
import { FilterType } from '../../types/api'
import {getOrderType} from '../../utils'

const NumOrderTitle = () => <Box sx={{ pl: '15px', whiteSpace: 'wrap', pr: '5px'  }}>Numéro de facture</Box>
const NumOrderBody = ({ row }: any) => (
  <Typography variant='bodySmall' sx={{ pl: '15px'}}>
    {row.original.order_number}
  </Typography>
)
const DateBody = ({ row }: any) => (
  <Typography variant='bodySmall'>
    {row.original.date_entered}
  </Typography>
)
const UserBody = ({ row }: any ) => (
  <Typography variant='bodySmall'>
     {row.original.user_name}
  </Typography>
)
const AcqBody = ({ row }: any ) => (
  <Typography variant='bodySmall'>
     {row.original.region_name}
  </Typography>
)
const TotalBody = ({ row }: any ) => (
  <Typography variant='bodySmall'>
     {row.original.total} $
  </Typography>
)
const SoldBody = ({ row }: any ) => (
  <Typography variant='bodySmall'>
     {row.original.credit} $
  </Typography>
)
const StatusBody = ({ row }: any ) => (
  <Typography variant='bodySmall'>
    {getOrderType(row.original.status)}
  </Typography>
)
const RowActions = ({ row }: any) => (
  <Box sx={{ pr: '10px'}}>
  <ExternalLinkButton href={`/commande/${row.original.crm_id}`}/>
  </Box>
)
const DetailsColumn = () => <Box sx={{ pr: '24px' }}>Détail</Box>
const EnterpriseOrders = () => {
  const [filter, setFilter] = useState<FilterType>({})
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'date_entered', desc: true }])
  const [page, setPage] = useState('1')
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data, isFetching } = useGetOrdersQuery({
    member_crm_id: activeEnterprise?.crm_id,
    page,
    sort: sorting[0],
    filter,
  })
  useSelectedItemSideBar('entreprise.orders')
  const columns = useMemo<MRT_ColumnDef<OrderType>[]>(
    () => [
      {
        accessorKey: 'order_number',
        header: 'Numéro de facture',
        Header: NumOrderTitle,
        maxSize: 29,
        minSize: 29,
        size: 29,
        enableSorting: true,
        Cell: NumOrderBody,
      },
      {
        accessorKey: 'date_entered',
        header: 'Date',
        maxSize: 29,
        minSize: 29,
        size: 29,
        enableSorting: true,
        Cell: DateBody,
      },
      {
        accessorKey: 'region_name',
        header: 'ACQ',
        size: 29,
        enableSorting: true,
        maxSize: 29,
        minSize: 29,
        Cell: AcqBody,
      },
      {
        accessorKey: 'user_name',
        header: 'Utilisateur',
        size: 29,
        enableSorting: true,
        maxSize: 29,
        minSize: 29,
        Cell: UserBody,
      },
      {
        accessorKey: 'total',
        header: 'Total',
        size: 29,
        enableSorting: true,
        maxSize: 29,
        minSize: 29,
        Cell: TotalBody,
      },
      {
        accessorKey: 'credit',
        header: 'Solde',
        size: 29,
        enableSorting: true,
        maxSize: 29,
        minSize: 29,
        Cell: SoldBody,
      },
      {
        accessorKey: 'status',
        header: 'Statut',
        size: 29,
        maxSize: 29,
        minSize: 29,
        Cell: StatusBody,
        enableSorting: true,
      },
    ],
    [],
  )
  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      setPage('1')
      setFilter(values)
    },
  })

  return (
    <>
      <Title sx={{ mb: '48px' }}>Commandes</Title>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '48px' }}
      >
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <FormikContext.Provider value={formik}>
            <FieldWithLabel label='Rechercher'>
              <OutlinedFieldFormik
                sx={{ width: '416px', mt: '-12px' }}
                name='search'
                placeholder='Numéro, date'
              />
            </FieldWithLabel>

            <ContainedButton
              sx={{
                alignSelf: 'end',
                width: '140px',
                minHeight: '44px',
                height: '44px',
                py: '6px',
                fontSize: '12px',
              }}
              title='rechercher'
              handleClick={() => formik.handleSubmit()}
            />
          </FormikContext.Provider>
        </Box>
        {formik.isSubmitting && formik.dirty && (
          <ResetButton handleClick={() => {
            setPage('1')
            setFilter({})
            formik.resetForm()
          }} />
        )}
      </Box>
      <Typography sx={{ fontWeight: '500', mb: '15px!important' }}>Résultats : {data?.metadata?.total ? data.metadata.total : 0}</Typography>
      <CustomTable
        data={data?.data ?? []}
        columns={columns}
        enableSorting={true}
        muiTableBodyRowProps={{ hover: false }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 10,
            maxSize: 10,
            minSize: 10,
            header: 'Details',
            accessorKey: 'details',
            Header: DetailsColumn,
            muiTableBodyCellProps: {
              align: 'right',
            },
            muiTableHeadCellProps: {
              align: 'right',
            },
          },
        }}
        enableSortingRemoval={false}
        enableMultiSort={false}
        manualSorting
        onSortingChange={(params) => {
          setPage('1')
          setSorting(params)
        }}
        enableRowActions
        state={{ sorting, isLoading: isFetching }}
        renderRowActions={RowActions}
        positionActionsColumn='last'
        enableSelectAll={false}
        propsPagination={{
          page: Number(page),
          count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
          onChange: (e, index) => {
            setPage(index.toString())
          },
        }}
        muiTableBodyCellProps= {{
          sx: {
            p: '10px 7px 10px 0',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            color: 'white',
            textTransform: 'uppercase',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '500',
            letterSpacing: '1px',
            justifyContent:'center',
            wordBreak: 'break-word',
            py: '11px',
            px: '0',
            pr: '7px',
            '& .MuiTableSortLabel-root': {
              ml: '14px',
            },
            '& .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon, & .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon':
              {
                color: '#A5A3A3',
              },
          },
        }}
      />
    </>
  )
}

export default EnterpriseOrders
