const ArrowRightIcon = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.99935 0.666687L5.05935 1.60669L8.77935 5.33335H0.666016V6.66669H8.77935L5.05935 10.3934L5.99935 11.3334L11.3327 6.00002L5.99935 0.666687Z'
        fill='#444D4E'
      />
    </svg>
  )
}
export default ArrowRightIcon
