import Axios from '.'
import { AxiosConfigType } from '../types'
import { CertificateType } from '../types/api/certificates'
import { OptionalParamsApi, PaginationResponseSecondFormat } from '../types/api'
import { generateParamsURL } from '../utils'


const getCertificates = async (
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponseSecondFormat<CertificateType>>(
    `certificates?${generateParamsURL(params)}`,
    config,
  )
  return response.data
}


const CertificatesApi = {
    getCertificates,
}




export default CertificatesApi