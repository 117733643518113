import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import OpenCloseArrowIcon from '../../icons/openCloseArrowIcon'
interface Props {
  title?: string
  job?: string
  subTitle: string
  children: React.ReactNode
}
const SectionFormAccordion = ({ title, children, subTitle, job }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <section>
      {title && (
        <Typography variant='h5' sx={{ textTransform: 'uppercase', mb: '20px' }}>
          {title}
        </Typography>
      )}
      <Box
        sx={{
          border: '1px solid #DADCDD',
          pl: '30px',
          pr: '35px',
          ...(isOpen ? { pb: '37px' } : {}),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            pb: '44px',
            pt: '34px',
          }}
          onClick={toggleAccordion}
        >
          <Box>
            <Typography variant='h6' sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
              {subTitle}
            </Typography>
            {job && !isOpen && <Typography mt='10px'>{job}</Typography>}
          </Box>
          <OpenCloseArrowIcon open={isOpen} size='large' />
        </Box>
        {isOpen && <>{children}</>}
      </Box>
    </section>
  )
}

export default SectionFormAccordion
