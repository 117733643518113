import FormikContext from '../../context/formikContext'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PasswordFieldFormik from '../formik/fields/passwordFieldFormik'
import SimpleButton from '../common/buttons/simpleButton'
import ContainedButton from '../common/buttons/containedButton'
interface Props {
  formik: any
  handleShowPassword: (key: 'newPassword' | 'confirmPassword') => void
  showPassword: {
    newPassword: boolean
    confirmPassword: boolean
  }
}
const UpdatePasswordForm = ({ formik, handleShowPassword, showPassword }: Props) => {
  return (
    <FormikContext.Provider value={formik}>
      <Box component='form' sx={{ pt: '48px' }}>
        <Typography variant='h3' sx={{ mb: '27px' }}>
          Changer mon mot de passe
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: '27px' }}>
        <PasswordFieldFormik
          handleClickShowPassword={() => handleShowPassword('newPassword')}
          showPassword={showPassword['newPassword']}
          name='newPassword'
          label='Nouveau mot de passe'
          required
        />
        <Typography variant='captionSmall' sx={{ width: '280px', mt: '21px', ml: '24px' }}>
          Le mot de passe doit être constitué de 10 caractères au minimum et comporter au moins un
          chiffre, une majuscule, une minuscule et un caractère spécial.
        </Typography>
      </Box>
      <PasswordFieldFormik
        handleClickShowPassword={() => handleShowPassword('confirmPassword')}
        showPassword={showPassword['confirmPassword']}
        name='confirmPassword'
        label='Confirmation du mot de passe'
        sx={{ mb: '48px' }}
        required
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SimpleButton handleClick={() => formik.resetForm()} sx={{ px: 0, mr: '36px' }} />
        <ContainedButton handleClick={() => formik.handleSubmit()} title='sauvegarder' />
      </Box>
    </FormikContext.Provider>
  )
}

export default UpdatePasswordForm
