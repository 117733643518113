import { useState, useEffect } from 'react'
import { t } from 'i18next'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import TitlePage from '../../components/common/titlePage'
import Card from '../../components/common/card'
import CustomSwitch from '../../components/common/customSwitch'
import useSelectedItemSideBar from '../../hooks/useSelectedItemSideBar'
import { useGetPreferences, useUpdatePreferences } from '../../hooks/api/users'
import { Preference } from '../../types/api/user'
import useHandleError from '../../hooks/api/useHandleError'
import TabLoader from '../../components/common/tabLoader'

const Preferences = () => {
  useSelectedItemSideBar('myProfile.preferences')
  const { data, isLoading, refetch } = useGetPreferences()
  const [disabledSwitches, setDisabledSwitches] = useState<Preference>({
    regional_newsletter: false,
    acq_rt_nego_newsletter: false,
    acq_newsletter: false,
  })
  const [preferences, setPreferences] = useState({
    regional_newsletter: true,
    acq_rt_nego_newsletter: false,
    acq_newsletter: false,
  })
  const { mutate, error } = useUpdatePreferences()
  useHandleError(error)
  const handleToogle =
    (key: keyof Preference) => async (e: React.ChangeEvent<HTMLInputElement>) => {
      setDisabledSwitches((disabledSwitches) => ({
        ...disabledSwitches,
        [key]: true,
      }))
      await mutate(
        { preference: key, value: e.target.checked },
        {
          onSuccess: () => {
            refetch()
          },
          onSettled: () => {
            setDisabledSwitches((disabledSwitches) => ({
              ...disabledSwitches,
              [key]: false,
            }))
          },
        },
      )
    }
  useEffect(() => {
    if (data) {
      setPreferences(data)
    }
  }, [data])
  return (
    <TabLoader isLoading={isLoading}>
      <TitlePage
        title={t('pages.profil.preferences.title')}
        textDesc={
          <>
            <Typography variant='h3'>{t('pages.profil.preferences.subTitle')}</Typography>
            <Typography>{t('pages.profil.preferences.description')}</Typography>
          </>
        }
      />
      <Grid container rowSpacing={4} columnSpacing={3} alignItems='stretch'>
        <Grid item xs={12} sm={6}>
          <Card
            title='Infolettre ACQ'
            textDesc='Bulletin électronique envoyé toutes les deux semaines incluant notamment les plus récentes nouvelles de l’ACQ, les dernières actualités de l’industrie de la construction au Québec et les événements à venir.'
            callAction={
              <CustomSwitch
                label='Je m’abonne et je consens à recevoir l’infolettre'
                switchProps={{
                  checked: preferences?.acq_newsletter,
                  disabled: disabledSwitches.acq_newsletter,
                  onChange: handleToogle('acq_newsletter'),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card
            title='Infolettre ACQ-RT/NÉGO'
            textDesc='Bulletin électronique envoyé périodiquement portant, entre autres, sur des dossiers liés aux relations du travail et aux conventions collectives IC/I.'
            callAction={
              <CustomSwitch
                label='Je m’abonne et je consens à recevoir l’infolettre'
                switchProps={{
                  checked: preferences?.acq_rt_nego_newsletter,
                  disabled: disabledSwitches.acq_rt_nego_newsletter,
                  onChange: handleToogle('acq_rt_nego_newsletter'),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card
            title='Infolettre régionale'
            textDesc='Bulletin électronique envoyé toutes les deux semaines incluant notamment les plus récentes nouvelles de l’ACQ, les dernières actualités de l’industrie de la construction au Québec et les événements à venir.'
            callAction={
              <CustomSwitch
                label='Je m’abonne et je consens à recevoir l’infolettre'
                switchProps={{
                  checked: preferences?.regional_newsletter,
                  disabled: disabledSwitches.regional_newsletter,
                  onChange: handleToogle('regional_newsletter'),
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </TabLoader>
  )
}
export default Preferences
