import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { SxProps, Theme } from '@mui/material'
import CloseIcon from '../../../icons/closeIcon'
interface Props {
  open: boolean
  handleClose: () => void
  children: React.ReactNode
  sx?: SxProps<Theme>
  backdropClick ?: boolean
   
}
export default function CustomModal({ open, handleClose, children, sx, backdropClick = false }: Props) {

  const closeHandling = (event:any, reason:any) => {
    if (backdropClick && reason && reason == 'backdropClick') 
    return;
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={closeHandling}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description' 
    >
      <Box
        sx={{
          top: '296px',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '557px',
          minHeight: '150px',
          bgcolor: 'white',
          boxShadow: 24,
          outline: 'none',
          padding: '40px',
          ...sx,
        }}
      >
        <Box
        className='closeIcon'
          component='button'
          sx={{
            position: 'absolute',
            border: 'none',
            outline: 'none',
            background: 'none',
            cursor: 'pointer',
            top: '25px',
            right: '21px',
            p: '0',
            width: '14px',
            height: '14px',
            boxShadow: 'none',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Box>
        {children}
      </Box>
    </Modal>
  )
}
