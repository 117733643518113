import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Product } from '../../types/api/order'
import {getDate} from '../../utils'
import theme from '../../config/theme'
interface Props {
  product : Product,
  isMember: null | string

}
const defaultStyle = {
    padding: '30px 0',
    borderBottom: '1px solid #5F686A'
}
const isDiscountable = (price : string | number , price_WithDiscount : string | number) => {
 return (price > price_WithDiscount)
}
const hasRabis = (amount : string  | undefined) => {
    return  typeof amount === 'string' && parseFloat(amount)!== 0  ; 
}

const OrderItem = ({ isMember, product}: Props) => {
  return (
    <Box sx={defaultStyle}>
            <Box sx={{ display:'flex', flexDirection:'column', gap:'8px' , marginBottom:'25px'}}>
                <Typography variant='h5' sx={{letterSpacing: '1px',textTransform:'uppercase', fontWeight: '700'}}> 
                    {product.title} {product.activity ? ` | ${product.activity}` : ''} {product.package ? ` | ${product.package}` : ''} 
                </Typography>
                <Typography variant='captionSmall' sx={{letterSpacing: '0', marginBottom:'0'}}>
                    {product.cohort}
                </Typography>
                <Box>
                    <Typography variant='bodySmall'> 
                     {getDate(product.start_date,product.end_date)}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='bodySmall'> 
                    ACQ régionale {product.region?.name}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display:'flex', flexDirection:'row', gap:'8px' , justifyContent:'flex-end', alignItems:'center'}}>
            {product.code_promo_value && 
                         <Typography variant='h6' sx={{padding:'5px 30px', background:theme.palette.primary.lighter, textAlign:'center', lineHeight:'20px'}}>
                            Code promo
                        </Typography>
            }

             {product?.discount?.amount !== null &&  hasRabis(product?.discount?.amount) &&
                         <Typography variant='h6' sx={{padding:'5px 30px', background:theme.palette.primary.lighter, textAlign:'center', lineHeight:'20px'}}>
                            Rabais
                        </Typography>
            }
            </Box>
            <Box sx={{ display:'flex', flexDirection:'row', gap:'8px' , justifyContent:'space-between', alignItems:'center'}}>
                    <Box sx={{ width: '75%', mr: '5%'}}>
                    <Box >
                        <Typography variant='captionSmall' sx={{letterSpacing: '1px',textTransform:'uppercase', fontWeight: '700'}}> 
                           {isMember ?   'Prix membre' : 'Prix non membre' }
                        </Typography>
                    </Box>
                    <Box sx={{color:'#444D4E', marginTop:'5px'}}>
                        <Typography variant='bodyLarge'> 
                            {product.price} $
                        </Typography>
                    </Box>
                    </Box>
                    <Box sx={{ width: '5%'}}>
                        <Typography variant='body1'> 
                            X {product.quantity}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '20%', display:'flex', flexDirection:'row', gap:'20px' , justifyContent:'flex-end' }}>
                        {product.discount?.amount && isDiscountable(product.price, product.total_price) &&
                        <Box>
                            <Typography variant='body1' sx={{ textDecoration:'line-through' }}> 
                                {product.total_price * product.quantity } $
                            </Typography>
                        </Box>
                        }
                        <Box>
                            <Typography variant='bodyLarge' sx={{fontSize:'18px'}}> 
                                {product.total_price} $
                            </Typography>
                        </Box>
                    </Box>
            </Box>
            <Box sx={{ display:'flex', flexDirection:'row', gap:'8px' , justifyContent:'flex-end', alignItems:'center'}}>
            {!product.taxable && 
                         <Typography variant='captionSmall' sx={{padding:'4px'}}>
                            Non taxable
                        </Typography>
            }
            </Box>
    </Box>
  )
}
export default OrderItem
