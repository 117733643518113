import Axios from '.'
import { AxiosConfigType } from '../types'
import { memberAventageType} from '../types/api/memberAventages'
import { PaginationResponseSecondFormat, OptionalParamsApi } from '../types/api'
import { generateParamsURL } from '../utils'


const basePath = '/companies'

const getNextFormations = async (
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponseSecondFormat<memberAventageType>>(
    `/companies/${generateParamsURL(params)}/advantages`,
    config,
  )
  return response.data
}



const getMemberBenefits = async (crm_id: string, config?: AxiosConfigType) => {
    const response = await Axios.get<memberAventageType>(`${basePath}/${crm_id}/advantages`, config)
    return response.data
  }

const memberBenefitsApi = {
    getMemberBenefits,
}

export default memberBenefitsApi