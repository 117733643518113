import { Box } from '@mui/material'
import React from 'react'
import Loader from './loader'
interface Props {
  isLoading: boolean
  children: React.ReactNode
}
const TabLoader = ({ isLoading, children }: Props) => {
  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mt: '250px',
          }}
        >
          <Loader />
        </Box>
      ) : (
        children
      )}
    </>
  )
}

export default TabLoader
