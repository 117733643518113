const ConfirmIcon = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='15' stroke='#F9A51A' strokeWidth='2' />
      <path
        d='M21.9104 10.5143L14.2631 18.1896L10.0972 14.02L8.46094 15.6622L14.2631 21.4857L23.5467 12.1681L21.9104 10.5143Z'
        fill='#F9A51A'
      />
    </svg>
  )
}

export default ConfirmIcon
