import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import './i18n'
import theme from './config/theme'
import './index.css'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_LARAVEL_DSN,
  // release: `growtheco-portail@${process.env.BUILD_ID}`,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/logientlab.com\/api/],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  replaysSessionSampleRate: Number(process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE),
  replaysOnErrorSampleRate: Number(process.env.REACT_APP_SENTRY_ONERROR_SAMPLE_RATE),
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 0,
      useErrorBoundary: true,
    },
  },
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)
