import { SxProps, Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import PlusIcon from '../../../icons/plusIcon'
interface Props {
  handleClick?: () => void
  sx?: SxProps<Theme>
}
const AddButton = ({ handleClick, sx }: Props) => {
  return (
    <Typography
      component='button'
      type='button'
      sx={{
        py: '30px',
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        border: '2px solid #444D4E',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: 'white',
        textTransform: 'uppercase',
        cursor: 'pointer',
        fontWeight: '700',
        ...sx,
      }}
      onClick={handleClick}
    >
      <PlusIcon />
      <span>Ajouter un contact</span>
    </Typography>
  )
}

export default AddButton
