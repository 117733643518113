const LinkIcon = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.33333 1.33333V10.6667H10.6667V6H12V10.6667C12 11.403 11.403 12 10.6667 12H1.33333C0.596954 12 0 11.403 0 10.6667V1.33333C0 0.596954 0.596954 0 1.33333 0H6V1.33333H1.33333ZM7.33333 1.33333V0H12V4.66667H10.6667V2.27333L4.11333 8.82667L3.17333 7.88667L9.72667 1.33333H7.33333Z'
        fill='white'
      />
    </svg>
  )
}

export default LinkIcon
