import OutlinedFieldFormik from './outlinedFieldFormik'
import React from 'react'
import FormatedInput from '../../common/fields/formatedInput'
const NumberFieldFromik = (props: React.ComponentProps<typeof OutlinedFieldFormik>) => {
  return (
    <OutlinedFieldFormik
      placeholder='123 123'
      InputProps={{
        inputComponent: FormatedInput as any,
      }}
      inputProps={{
        mask: /^\d+$/,
      }}
      {...props}
    />
  )
}

export default NumberFieldFromik
