import Axios from '.'
import { AxiosConfigType } from '../types'
import { OrderDetailsType } from '../types/api/order'


const getOrderData = async (
  order_id: string | undefined,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<OrderDetailsType>(
    `orders/${order_id}`,
    config,
  )
  return response.data
}


const OrderApi = {
    getOrderData
}

export default OrderApi