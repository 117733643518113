import Button from '@mui/material/Button'
import { SxProps, Theme } from '@mui/material'
interface Props {
  handleClick: () => void
  sx?: SxProps<Theme> 
}
const SimpleButton = ({ handleClick, sx }: Props) => {
  return (
    <Button
      sx={{
        minHeight: '50px',
        fontWeight: '700',
        fontSize: '14px',
        textDecoration: 'underline',
        textTransform: 'capitalize',
        '&:hover': {
          background: 'white',
          textDecoration: 'underline',
        },
        ...sx,
      }}
      onClick={handleClick}
    >
      Annuler
    </Button>
  )
}

export default SimpleButton
