import PostTitleApi from '../../api/postTitleApi'
import { Option } from '../../types'
import useCustomQuery from './useCustomQuery'
import { OptionsTypeReactQuery } from '../../types/api'

export enum ServerStateKeysEnum {
  PostsTitle = 'posts-title',
}
export const useGetPostsTitle = (options?: OptionsTypeReactQuery<Option[]>) =>
  useCustomQuery<Option[], Error>(
    [ServerStateKeysEnum.PostsTitle],
    (header) => PostTitleApi.findAll(header),
    options,
  )
