const ChevronLeftIcon = () => {
  return (
    <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.09683 8.56214L2.53461 4.99992L6.09683 1.42992L5.00016 0.333252L0.333496 4.99992L5.00016 9.66659L6.09683 8.56214Z'
        fill='#444D4E'
      />
    </svg>
  )
}

export default ChevronLeftIcon
