const DatePickerIcon = () => {
  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 4C18 2.89543 17.1046 2 16 2H15V0H13V2H5V0H3V2H2C0.89543 2 0 2.89543 0 4V18C0 19.1046 0.89543 20 2 20H16C17.1046 20 18 19.1046 18 18V4ZM6 11V9H4V11H6ZM10 11V9H8V11H10ZM14 9V11H12V9H14ZM16 18V7H2V18H16ZM6 15V13H4V15H6ZM10 13V15H8V13H10ZM14 15V13H12V15H14Z'
        fill='#F9A51A'
      />
    </svg>
  )
}

export default DatePickerIcon
