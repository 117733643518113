import {
    CertificateType,
  } from '../../types/api/certificates'
import CertificatesApi from '../../api/certificatesApi'

import useCustomQuery from './useCustomQuery'
import { OptionsTypeReactQuery, PaginationResponseSecondFormat, OptionalParamsApi } from '../../types/api'


  export const useGetCertificatesQuery = (
    params: OptionalParamsApi,
    options?: OptionsTypeReactQuery<PaginationResponseSecondFormat<CertificateType>>,
  ) =>
    useCustomQuery<PaginationResponseSecondFormat<CertificateType>, Error>(
      ['certificates', params],
      async (header) => await CertificatesApi.getCertificates(params, header),
      options,
  )


