import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ConfirmIcon from '../../../icons/confirmIcon'
import WarningIcon from '../../../icons/warningIcon'
import { SxProps, Theme } from '@mui/material'
interface Props {
  title: string
  type?: 'warning' | 'confirm'
  content?: React.ReactNode
  actions?: React.ReactNode
  sx?: SxProps<Theme> 
}
const icons = {
  confirm: <ConfirmIcon />,
  warning: <WarningIcon />,
}
export default function BodyModalWithActions({ title, type, content, actions, sx }: Props) {
  return (
    <Stack
      spacing='24px'
      sx={{
        width: '100%',
        ...sx
      }}
    >
      <Box className='modal-header' sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {type && <>{icons[type]}</>}
        <Typography variant='h4'>{title}</Typography>
      </Box>

      <div>{content}</div>
      {actions && (
        <>
          <Divider />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '36px' }}>{actions}</Box>
        </>
      )}
    </Stack>
  )
}
