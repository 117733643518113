import Stack from '@mui/material/Stack'
import { Phone } from '../../types'
import PhoneFieldFromik from '../formik/fields/phoneFieldFromik'
import PostFieldFormik from '../formik/fields/postFieldFormik'
import SelectFormik from '../formik/fields/selectFormik'
import EditableTextField from './fields/editableTextField'
interface PhoneProps {
  object: Phone
  typeName: string
  phoneName: string
  postName: string
  required?: boolean
}
const listItems = [
  {
    name: 'Maison',
    id: 'home',
  },
  {
    name: 'Travail',
    id: 'work',
  },
  {
    name: 'Cellulaire',
    id: 'mobile',
  },
]
const PhoneComponent = ({
  object,
  typeName,
  phoneName,
  postName,
  required = false,
}: PhoneProps) => {
  const phoneType = {
    home: 'Maison',
    work: 'Travail',
    mobile: 'Cellulaire',
  }
  return (
    <Stack direction='row' spacing='24px'>
      <EditableTextField
        sx={{ width: '204px' }}
        label='Type'
        value={phoneType[object['type']]}
        required={required}
      >
        <SelectFormik name={typeName} placeholder='Type' fullWidth listItems={listItems} />
      </EditableTextField>
      <EditableTextField
        label='Téléphone'
        value={object['phone_number']}
        sx={{ width: '212px' }}
        required={required}
      >
        <PhoneFieldFromik name={phoneName} />
      </EditableTextField>
      {object['type'] === 'work' && (
        <EditableTextField label='Poste' sx={{ width: '90px' }} value={object['phone_number_post']}>
          <PostFieldFormik name={postName} />
        </EditableTextField>
      )}
    </Stack>
  )
}

export default PhoneComponent
