import React, { createContext } from 'react'
interface ErrorModalContextType {
  showModal: boolean
  errorMessage: string
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}
export const ErrorModalContext = createContext<ErrorModalContextType>({
  showModal: false,
  errorMessage: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorMessage: () => {},
})
