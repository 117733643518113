import { useMemo, useState, useContext } from 'react'
import CustomizedAccordions from '../../../../components/common/accordion'
import FormikContext from '../../../../context/formikContext'
import RadioGroup from '@mui/material/RadioGroup';
import { useFormik } from 'formik'
import Label from '../../../../components/common/label'
import RadioButton from '../../../../components/common/fields/radioButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Loader from '../../../../components/common/loader'
import {Schedule} from '../../../../types/api/certificates'
import {FormationOtherDatesType} from '../../../../types/api/formations'
import {getDate} from '../../../../utils'
import ContainedButton from '../../../../components/common/buttons/containedButton'
import SimpleButton from '../../../../components/common/buttons/simpleButton'
import { useUpdateFormationDate } from '../../../../hooks/api/formations'





interface Props {
    status: string
    dataDates : FormationOtherDatesType[] | undefined,
    cancelFn: () => void,
    oldTrainingId : string
    isRefreshed: () => void
    isRefreshedData: () => void
}

const UpdateDateInscription = ({status, dataDates, cancelFn, oldTrainingId, isRefreshed, isRefreshedData }:Props) => {
    const [value, setValue] = useState('');
    const [isSolo, setIsSolo] = useState(false);

    
    const [updatedValues, setUpdatedValues] = useState(
        {
        old_training_id: '',
        new_training_id: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setUpdatedValues({'new_training_id' :(event.target as HTMLInputElement).value , 'old_training_id': oldTrainingId });

      };
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...updatedValues
        },
        onSubmit: async (values) => {
          setIsSubmitting(true)
          await updateInscriptionDate(
            {
             'new_training_id'  : value,
             old_training_id : values.old_training_id,
            },
            {
              onSuccess: async () => {
                isRefreshed()
                isRefreshedData()
                cancelFn()
              },
              onSettled: async () => {
                setIsSubmitting(false)
              },
            },
          )
        },
      })
        const { mutate: updateInscriptionDate } = useUpdateFormationDate(formik)

        return  (
            (status === 'loading' || isSubmitting ) ? 
            <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                mt: '10px',
            }}
            >
            <Loader />
            </Box>  : 
            dataDates  ?  
            <Box> 
            <Box 
            sx={{ 
            flex: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap:'16px',
            paddingBottom:'24px',
            }}> 
            <Typography variant='captionSmall' sx={{marginBottom: '8px', textTransform:'uppercase'}}>{dataDates[0]?.cohort ? ` – ${dataDates[0]?.cohort}` : null}{dataDates[0]?.title}</Typography>
            <Typography variant='h4'>Changement de dates</Typography>
            <Typography variant='body1'>Il est encore possible de modifier votre inscription à cette formation. Sélectionner de nouvelles dates:  </Typography>
            </Box>
            {
                <FormikContext.Provider value={formik}>
                { dataDates?.length > 0 ? 
                dataDates.map((date)=> {
                   if(date.schedule.length > 1) {
                    return (
                        <>
                        <Box 
                            sx={{ 
                                flex: 1,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                borderBottom:'1px solid #DADCDD'
                                }}>
                                <RadioGroup
                                aria-labelledby="controlled-radio-buttons-group"
                                name="grouope"
                                value={value}
                                onChange={handleChange}
                                >
                                        <CustomizedAccordions
                                                panels = {[
                                                    {
                                                    panel_id: date.id,
                                                    header : <RadioButton
                                                    label={getDate(date.start_date, date.end_date)}
                                                    onChange={() => console.log('test')}
                                                    value={date.id}
                                                    /> ,
                                                    schedules : date.schedule
                                                    }
                                                ]}
                                                info = {date?.cohort}
                                                /> 
                                                </RadioGroup>
                                            </Box>
                                            </>
                        )
                   }
                   else {
                    return (
                        <>
                        <Box 
                            sx={{ 
                                flex: 1,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding:'24px 0'
                                }}>
                                <RadioGroup
                                aria-labelledby="controlled-radio-buttons-group"
                                name="grouope"
                                value={value}
                                onChange={handleChange}
                                >
                                     <RadioButton
                                        label={getDate(date.start_date, date.end_date)}
                                        value={date.id}
                                        />        
                                </RadioGroup>
                            </Box>
                        </>
                        )
                   }
                })
            
            : null}
                
                 <Box sx={{ display: 'flex', alignItems: 'center', gap: '36px', marginTop: '24px' }}>
                 <SimpleButton sx={{ py: '0' }} handleClick={cancelFn} />

                 <ContainedButton
                title='Enregistrer'
                sx={{ py: '0' }}
                disabled={isSubmitting}
                handleClick={() => {
                    formik.handleSubmit()
                }}
                />
                 </Box>
                
                </FormikContext.Provider>
            }
            </Box>
        : 
        <div> Aucunes dates disponibles</div>
       

        )
}

export default UpdateDateInscription