import { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'ramda'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'
import ChevronLeftIcon from '../../../icons/chevronLeftIcon'
const RetunLinkAndActiveEnterperise = () => {
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '42px' }}>
      <Box
        component='a'
        sx={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          color: 'primary.main',
          cursor: 'pointer',
        }}
        href={`${process.env.REACT_APP_ACQ_SITE as string}`}
      >
        <ChevronLeftIcon />
        <Typography variant='h6' ml='6px'>
          Retour au site ACQ
        </Typography>
      </Box>
      {!isEmpty(activeEnterprise) && (
        <Typography variant='captionSmall' sx={{ fontWeight: '500', textTransform: 'uppercase' }}>
          {`${activeEnterprise.legal_name}${
            activeEnterprise?.region?.name ? `, ${activeEnterprise.region.name}` : ''
          }`}
        </Typography>
      )}
    </Box>
  )
}

export default RetunLinkAndActiveEnterperise
