import { useMemo, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import CustomTable from '../../../../components/common/customTable'
import {getDate} from '../../../../utils'
import { useGetNextFormationsQuery } from '../../../../hooks/api/entreprises'
import { ActiveEnterpriseContext } from '../../../../context/activeEnterpriseContext'
import {
  FormationType
} from '../../../../types/api/formations'

const DateTitle = () => <Box sx={{ pl: '15px' }}>Date</Box>
const DateBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>
    <Typography variant='smallTextNormal' pl='15px'>
    {getDate(row.original.start_date , row.original.end_date)}
  </Typography>
  </Typography>
)
const ParticipantBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>{row.original.participant_full_name}</Typography>
)
const TitleBody = ({ row }: any) => <Typography variant='h6'>{row.original.title}</Typography>
const NextFormations = () => {
const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'start_date', desc: true }])
  const [page, setPage] = useState('1')
  const { data, isFetching } = useGetNextFormationsQuery(
    {
      member_crm_id: activeEnterprise?.crm_id,
      page,
      sort: sorting[0],
    })
  const columns = useMemo<MRT_ColumnDef<FormationType>[]>(
    () => [
      {
        accessorKey: 'start_date',
        header: 'Date',
        Header: DateTitle,
        size: 21,
        enableSorting: true,
        maxSize: 21,
        minSize: 21,
        Cell: DateBody,
      },
      {
        accessorKey: 'participant_full_name',
        header: 'Participant',
        size: 20,
        enableSorting: true,
        maxSize: 20,
        minSize: 20,
        Cell: ParticipantBody,
      },
      {
        accessorKey: 'title',
        header: 'Formation',
        size: 59,
        enableSorting: false,
        maxSize: 59,
        minSize: 59,
        Cell: TitleBody,
      },
    ],
    [],
  )
  return (
    <CustomTable
      data={data?.data ?? []}
      columns={columns}
      enableSortingRemoval={false}
      enableSorting={true}
      enableMultiSort={false}
      manualSorting
      onSortingChange={(params) => {
        setPage('1')
        setSorting(params)
      }}
      state={{ sorting, isLoading: isFetching }}
      muiTableBodyRowProps={{ hover: false }}
      propsPagination={{
        page: Number(page),
        count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
        onChange: (e, index) => {
          setPage(index.toString())
        },
      }}
    />
  )
}

export default NextFormations
