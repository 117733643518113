import React from 'react'
export interface ActiveRegionContextType {
  activeRegion: string
  setActiveRegion: (selectedItem: string) => void
}
export const ActiveRegionContext = React.createContext<ActiveRegionContextType>({
  activeRegion: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveRegion: () => {},
})
