import { useState } from 'react'
import { SxProps, Theme } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
interface Tab {
  label: string
  element: React.ReactNode
  width: string
}
interface Props {
  list: Tab[]
  styleTabs?:SxProps<Theme>
}
const CustomTabs = ({ list, styleTabs }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event: React.SyntheticEvent, tab: number) => {
    setSelectedTab(tab)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', ...styleTabs }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label='basic tabs example'
          indicatorColor='secondary'
          sx={{
            '& .MuiTabs-indicator': {
              height: '5px',
            },
          }}
        >
          {list.map((tab) => (
            <Tab
              label={tab.label}
              key={tab.label}
              sx={{
                letterSpacing: '1px',
                fontWeight: '700',
                color: 'primary.main',
                width: tab.width,
              }}
            />
          ))}
        </Tabs>
      </Box>

      {list.map((item, index) => (
        <div hidden={index !== selectedTab} key={item.label}>
          {item.element}
        </div>
      ))}
    </Box>
  )
}

export default CustomTabs
