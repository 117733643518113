import React from 'react'
import { IMaskInput } from 'react-imask'
type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
} & React.ComponentProps<typeof IMaskInput>
const FormatedInput = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})
export default FormatedInput
