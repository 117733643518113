import { AxiosConfigType } from '../types'
import City from '../types/api/city'
import  Axios  from './index'
const basePath = '/cities'
const findAll = async (name: string, config?: AxiosConfigType) => {
  const response = await Axios.get<City[]>(`${basePath}?name=${name}`, config)
  return response.data
}
const CityApi = {
  findAll,
}

export default CityApi
