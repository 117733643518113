import { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CustomTabs from '../../../components/common/CustomTabs'
import Title from '../../../components/common/title'
import useSelectedItemSideBar from '../../../hooks/useSelectedItemSideBar'
import AutoFormations from './components/autoFormations'
import MyCertificates from './components/myCertificates'
import NextFormations from './components/nextFormations'
import CustomButton from '../../../components/common/buttons/customButton'
import { ActiveRegionContext } from '../../../context/activeRegionContext'
import LiveHelpIcon from '../../../icons/liveHelpIcon'

const MyFormations = () => {
  useSelectedItemSideBar('formations.my-formations')
  const { activeRegion } = useContext(ActiveRegionContext)
  return (
    <Box sx={{ 
      height: '100%',
      }}>
      <Title>mes formations</Title>
      <Box sx={{ 
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom:'40px'
      }}>
          <CustomTabs
            styleTabs={{ 
            mb: '48px',
            }}
            list={[
              {
                label: 'prochaines formations',
                element: <NextFormations />,
                width: '255px',
              },
              {
                label: 'autoformations',
                element: <AutoFormations />,
                width: '192px',
              },
              {
                label: 'mes attestations',
                element: <MyCertificates />,
                width: '192px',
              },
            ]}
          />

          <Box
            sx={{
              border: '1px solid #444D4E',
              p: '40px',
              display: 'flex',
              alignItems: 'center',
              mt: '51px',
            }}
          >
            <LiveHelpIcon />
                <Box
                  sx={{
                    ml: '5%',
                    mr: '8%',
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                  }}
                >
                    <Typography variant='h3' textTransform='uppercase'>
                      BESOIN D’AIDE ?
                    </Typography>
                    <Typography>
                      Contacter nos experts pour adhérer aux services sur demande et pour toutes questions
                      concernants nos services.
                    </Typography>
                </Box>
                <CustomButton
                  variant='principal'
                  href={`${process.env.REACT_APP_JOIN_US}?regionid=${activeRegion}`}
                  sx={{
                    py: '11px',
                    px: '30px',
                    fontSize: '15px',
                  }}
                >
                  NOUS JOINDRE
                </CustomButton>
          </Box>
        </Box>
    </Box>
  )
}

export default MyFormations
