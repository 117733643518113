import React from 'react'
import CustomTooltip from '../../../../components/common/customToolptip'
import Box from '@mui/material/Box'
import HelpIcon from '../../../../icons/helpIcon'
import { SxProps, Theme } from '@mui/material'
interface Props {
  sx?: SxProps<Theme> 
}

const content = (
  <>
    <p>Le rôle Utilisateur permet de :</p>
    <ul>
      <li>Se connecter</li>
      <li>Accéder à son profil, les événements et formations auquels il est inscrit</li>
    </ul>
    <p>Le rôle Administratif permet de:</p>
    <ul>
      <li>Ajouter/modifier un contact/utilisateur</li>
      <li>Voir les contacts/utilisateurs</li>
      <li>Voir l’historique d’activité</li>
    </ul>
    <p>Le rôle Financier permet de:</p>
    <ul>
      <li>Voir les factures</li>
      <li>Payer les factures</li>
    </ul>
    <p>Le rôle d’achat permet de:</p>
    <ul>
      <li>S’inscrire à des événements et formations</li>
      <li>
        Effectuer d’autres achats en ligne (portés au compte lorsque les articles le permettent)
      </li>
    </ul>
    À noter que pour inscrire plusieurs participants à une formation ou un événement, l'utilisateur
    devra avoir les rôles Administratif et Achat.
  </>
)
const TooltipRole = ({sx}:Props) => {
  return (
    <CustomTooltip title={<React.Fragment>{content}</React.Fragment>} placement='right'>
      <Box
        sx={{
          cursor: 'pointer',
          '&:hover svg path': {
            fill: '#5F686A',
          },
          ...sx
        }}
        component='span'
        className='tooltip'
      >
        <HelpIcon />
      </Box>
    </CustomTooltip>
  )
}

export default TooltipRole
