import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'
interface Props {
  label: string
  required?: boolean
  sx?: SxProps<Theme> 
}
const Label = ({ label, required = false, sx }: Props) => {
  return (
    <Typography
      variant='smallText'
      component='label'
      sx={{
        display: 'block',
        color: 'primary.main',
        position: 'relative',
        mb: '3px',
        ...sx,
      }}
    >
      <span>{label}</span>
      {required && (
        <Box component='sup' sx={{ position: 'absolute', top: '0' }}>
          *
        </Box>
      )}
    </Typography>
  )
}

export default Label
