const LiveHelpIcon = () => {
  return (
    <svg width='52' height='61' viewBox='0 0 52 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.0026 60.8665L17.3359 52.1998H6.6026C4.86705 52.1998 3.38872 51.5716 2.1676 50.3152C0.946493 49.0589 0.335938 47.5759 0.335938 45.8665V7.06647C0.335938 5.33092 0.946493 3.85258 2.1676 2.63147C3.38872 1.41036 4.86705 0.799805 6.6026 0.799805H45.4026C47.1121 0.799805 48.595 1.41036 49.8513 2.63147C51.1077 3.85258 51.7359 5.33092 51.7359 7.06647V45.8665C51.7359 47.5759 51.1077 49.0589 49.8513 50.3152C48.595 51.5716 47.1121 52.1998 45.4026 52.1998H34.7359L26.0026 60.8665ZM6.6026 45.8665H19.9397L26.0026 51.9998L32.1359 45.8665H45.4026V7.06647H6.6026V45.8665ZM25.6711 41.6665C26.4699 41.6665 27.1804 41.3618 27.8026 40.7524C28.4248 40.143 28.7359 39.4208 28.7359 38.5857C28.7359 37.7507 28.4242 37.0331 27.8007 36.4331C27.1773 35.8331 26.455 35.5331 25.6341 35.5331C24.8131 35.5331 24.1026 35.8378 23.5026 36.4472C22.9026 37.0566 22.6026 37.7789 22.6026 38.6139C22.6026 39.4489 22.9061 40.1665 23.5131 40.7665C24.1202 41.3665 24.8395 41.6665 25.6711 41.6665ZM27.7359 31.7998C27.7359 30.6443 27.947 29.5887 28.3693 28.6331C28.7915 27.6776 29.5964 26.6282 30.784 25.4851C31.952 24.3171 32.8248 23.1603 33.4026 22.0147C33.9804 20.869 34.2693 19.5834 34.2693 18.1577C34.2693 15.608 33.4804 13.6554 31.9026 12.2998C30.3248 10.9442 28.3243 10.2665 25.9011 10.2665C23.4108 10.2665 21.3162 10.9331 19.6174 12.2665C17.9186 13.5998 16.6693 15.2442 15.8693 17.1998L20.4693 18.9998C21.0915 17.9776 21.8026 17.0887 22.6025 16.3331C23.4025 15.5776 24.4543 15.1998 25.7579 15.1998C26.8988 15.1998 27.7693 15.5071 28.3693 16.1217C28.9693 16.7363 29.2693 17.5207 29.2693 18.4749C29.2693 19.2693 29.0359 20.0331 28.5693 20.7665C28.1026 21.4998 27.5137 22.222 26.8026 22.9331C24.9804 24.7109 23.8804 26.122 23.5026 27.1665C23.1248 28.2109 22.9359 29.7554 22.9359 31.7998H27.7359Z'
        fill='#F9A51A'
      />
    </svg>
  )
}

export default LiveHelpIcon
