import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'
interface Props {
  title: string
  children: React.ReactNode
  sx?: SxProps<Theme> 
}
const SectionForm = ({ title, children, sx }: Props) => {
  return (
    <Box component='section' sx={{ ...sx }}>
      <Typography variant='h5' sx={{ textTransform: 'uppercase', mb: '20px', fontWeight: '700' }}>
        {title}
      </Typography>
      <Box sx={{ border: '1px solid #DADCDD', pt: '38px', px: '32px', pb: '47px' }}>{children}</Box>
    </Box>
  )
}

export default SectionForm
