import OutlinedFieldFormik from './outlinedFieldFormik'
import React from 'react'
import FormatedInput from '../../common/fields/formatedInput'
const PhoneFieldFromik = (props: React.ComponentProps<typeof OutlinedFieldFormik>) => {
  return (
    <OutlinedFieldFormik
      placeholder='123 123-1234'
      InputProps={{
        inputComponent: FormatedInput as any,
      }}
      inputProps={{
        mask: '000 000-0000',
      }}
      {...props}
    />
  )
}

export default PhoneFieldFromik
