import Title from '../../components/common/title';

const Forbidden = () => {
    return (
        <Title>
            non auth
        </Title>
    )
}

export default Forbidden;