import CustomTabs from '../../../components/common/CustomTabs'
import Title from '../../../components/common/title'
import useSelectedItemSideBar from '../../../hooks/useSelectedItemSideBar'
import DocumentsAcq from './components/documentsAcq'
import DocumentsEntreprise from './components/documentsEntreprise'
import {ActiveEnterpriseContext} from '../../../context/activeEnterpriseContext';
import {useContext} from 'react';

const Documents = () => {
  useSelectedItemSideBar('entreprise.documents')
  const {activeEnterprise} = useContext(ActiveEnterpriseContext)

  // Limit documents to only ACQ documents if user is not admin
  if (activeEnterprise.roles.includes('Admin'))
    return (
      <>
        <Title>Documents</Title>
        <CustomTabs
          list={[
            {
              label: 'Documents entreprise',
              element: <DocumentsEntreprise/>,
              width: '255px',
            },
            {
              label: 'Documents ACQ',
              element: <DocumentsAcq/>,
              width: '219px',
            },
          ]}
        />
      </>
    )
  else
    return (
      <>
        <Title>Documents</Title>
        <CustomTabs
          list={[
            {
              label: 'Documents ACQ',
              element: <DocumentsAcq/>,
              width: '219px',
            },
          ]}
        />
      </>
    )
}

export default Documents
