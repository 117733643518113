import { useRouteError } from 'react-router-dom'
import useHandleError from '../../hooks/api/useHandleError'

const ErrorBoundary = () => {
  const error = useRouteError()
  useHandleError(error)
  return null
}

export default ErrorBoundary
