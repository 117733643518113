import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'
import Label from '../label'
interface Props {
  label: string
  required?: boolean
  sx?: SxProps<Theme>
  children: React.ReactNode
}

const FieldWithLabel = ({ label, required = false, sx, children }: Props) => {
  return (
    <Box sx={{ ...sx }}>
      <Label required={required} label={label} sx={{ mb: '16px' }} />
      {children}
    </Box>
  )
}

export default FieldWithLabel
