import UserApi from '../../api/userApi'
import { Preference, Session, User } from '../../types/api/user'
import useCustomQuery from './useCustomQuery'
import useCustomMutation from './useCustomMutation'
import { FormikProps } from 'formik'
import useCustomMutationWithFormik from '../useCustomMutationWithFormik'
import { OptionsTypeReactQuery } from '../../types/api'

export enum UsersServerStateKeysEnum {
  User = 'user',
  Session = 'session',
  Preference = 'preference',
}

export const useGetUser = (options?: OptionsTypeReactQuery<User>) => {
  return useCustomQuery<User, Error>(
    [UsersServerStateKeysEnum.User],
    (header) => UserApi.findUserById(header),
    options,
  )
}
export const useGetPreferences = (options?: OptionsTypeReactQuery<Preference>) => {
  return useCustomQuery<Preference, Error>(
    [UsersServerStateKeysEnum.Preference],
    (header) => UserApi.getPreferences(header),
    options,
  )
}
export const useUpdatePreferences = () => useCustomMutation(UserApi.updatePreferences)
export const useUpdateSession = () => useCustomMutation(UserApi.updateSession)
export const useGetSession = (options?: OptionsTypeReactQuery<Session>) =>
  useCustomQuery<Session, Error>(
    [UsersServerStateKeysEnum.Session],
    (header) => UserApi.getSessionById(header),
    { ...options, useErrorBoundary: false },
  )

export const useUpdateUser = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(UserApi.updateUser, formik)
export const useUpdateUserPassword = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(UserApi.updateUserPassword, formik)
