import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Label from '../label'
import { SxProps, Theme } from '@mui/material'
interface Props {
  value: React.ReactNode
  label: string
  sx?: SxProps<Theme> 
}
const ReadOnlyField = ({ value, label, sx }: Props) => {
  return (
    <Box sx={{ ...sx }}>
      <Label label={label} sx={{ mb: '16px' }} />
      <Typography>{value}</Typography>
    </Box>
  )
}

export default ReadOnlyField
