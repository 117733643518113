import Typography from '@mui/material/Typography'
import TooltipRole from './tooltipRole'
import { Option, Role } from '../../../../types'
import { getTitle } from '../../../../utils'
import { t } from 'i18next'
import Box from '@mui/material/Box'
export const customContactColumn = (email: string, phone: string, poste: string) => {
  return (
    <div>
      {email && (
        <Typography
          component='a'
          variant='smallTextNormal'
          fontWeight={700}
          href={`mailto:${email}`}
        >
          {email}
        </Typography>
      )}
      {(phone || poste) && (
        <Typography component='div' variant='smallTextNormal'>
          {phone && (
            <Typography
              sx={{ textDecoration: 'none' }}
              variant='smallTextNormal'
              component='a'
              href={`tel:${phone}`}
            >
              {phone}
            </Typography>
          )}{' '}
          {poste && ` poste ${poste}`}
        </Typography>
      )}
    </div>
  )
}
export const customListColumn = (list: string[] | Role[]) => {
  const sortListWithoutAdmin = [...list.filter((role) => role !== 'Admin')].sort((a, b) =>
    a.localeCompare(b),
  )

  const sortList = [...(list.includes('Admin') ? ['Admin'] : []), ...sortListWithoutAdmin]
  return (
    <div>
      {sortList?.map((el: string) => {
        return (
          <div key={el} className='list'>
            {t(`common.role.${el}`)}
          </div>
        )
      })}
    </div>
  )
}
export const customFullNameColumn = (firstName: string, lastName: string) => {
  return (
    <Typography variant='smallText' sx={{ pl: '24px' }}>
      {firstName} {lastName}
    </Typography>
  )
}
export const customTitleColumn = (title: string, listTitles: Option[] | undefined) => {
  return <Typography variant='smallTextNormal'>{title && getTitle(title, listTitles)}</Typography>
}
export const customRoleColumn = () => {
  return (
    <span>
      Rôle
      <TooltipRole
        sx={{
          marginLeft: '5px',
          marginTop: '-10px',
          display: 'table-caption',
          '&:hover svg path': {
            fill: '#fff',
          },
          svg: { width: '13px', height: '13px' },
        }}
      />
    </span>
  )
}
export const customHeaderColumn = () => <Box sx={{ pl: '24px' }}>Nom</Box>
