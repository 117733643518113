import BodyModalWithActions from '../../../../components/common/modals/bodyModalWithActions'
import { Typography } from '@mui/material'
import ContainedButton from '../../../../components/common/buttons/containedButton'
import { t } from 'i18next'
interface Props {
  handleClose: () => void
}
const BodyModalConfirmSendInvitations = ({ handleClose }: Props) => {
  return (
    <BodyModalWithActions
      title={t('pages.users.sendInvitationTitle')}
      type='confirm'
      content={
        <Typography sx={{ my: '16px' }}>{t('pages.users.sendInvitationDescription')}</Typography>
      }
      actions={<ContainedButton title='ok' sx={{ py: '0' }} handleClick={handleClose} />}
    />
  )
}

export default BodyModalConfirmSendInvitations
