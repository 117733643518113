import Button from '@mui/material/Button'
import EditIcon from '../../../icons/editIcon'
import { Box, SxProps, Theme } from '@mui/material'
interface Props {
  sx?: SxProps<Theme>
  handleClick: () => void
}

const EditButton = ({ sx, handleClick }: Props) => {
  return (
    <Button
      sx={{
        p: '0',
        minWidth: '50px',
        minHeight: '50px',
        backgroundColor: 'secondary.main',
        color: 'white',
        borderRadius: '0',
        transformOrigin: 'left',
        transition: '0.5s ',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'primary.main',
          width: '135px',
        },
        '&:hover div': {
          display: 'block',
        },
        ...sx,
      }}
      onClick={handleClick}
    >
      <EditIcon />
      <Box sx={{ ml: '18px', display: 'none' }}>éditier</Box>
    </Button>
  )
}

export default EditButton
