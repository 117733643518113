import { useContext } from 'react'
import { FieldConfig } from 'formik'
import MenuItem from '@mui/material/MenuItem'
import OutlinedField from '../../common/fields/outlinedField'
import FormikContext from '../../../context/formikContext'
import { getNestedObject } from '../../../utils'
import { Box, Chip } from '@mui/material'
import { without } from 'ramda'
import Checkbox from '../../common/fields/checkbox'
import { Option } from '../../../types'
import CloseIcon from '../../../icons/closeIcon'
interface Options {
  listItems: Option[]
}
const displaySelectedOptions =
  // eslint-disable-next-line react/display-name
  (handleDelete: (item: string) => void, listItems: Option[]) => (selectedOptions: string[]) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
        {selectedOptions.map((option) => (
          <Chip
            key={option}
            label={listItems.find((item) => item.id === option)?.name || ''}
            clickable
            deleteIcon={
              <Box
                onMouseDown={(event) => event.stopPropagation()}
                sx={{
                  '& svg': {
                    maxWidth: '10px',
                    maxHeight: '10px',
                  },
                  '&.MuiChip-deleteIcon': { mt: '-5px' },
                }}
              >
                <CloseIcon />
              </Box>
            }
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '20px',
              backgroundColor: 'white',
              py: '10px',
              pl: '12px',
              pr: '15px',
              border: '1px solid #DADCDD',
              '&:active': {
                boxShadow: 'none',
              },
              '&:hover': {
                backgroundColor: 'white',
              },
              '& .MuiChip-label': {
                px: '0',
                mr: '11px',
              },
              '& .MuiChip-deleteIcon': {
                m: '0',
              },
            }}
            onDelete={(e) => {
              e.preventDefault()
              handleDelete(option)
            }}
          />
        ))}
      </Box>
    )
  }

type Props = FieldConfig &
  React.ComponentProps<typeof OutlinedField> &
  Options & { selectType?: boolean; disabledItems?: string[] }
export default function SelectMultipleFormik({
  listItems,
  name,
  sx,
  selectType = false,
  disabledItems = [],
  ...rest
}: Props) {
  const formik = useContext(FormikContext)
  const hasError = Boolean(
    getNestedObject(name, formik.touched) && getNestedObject(name, formik.errors),
  )
  const handleDelete = (value: string) => {
    formik.setFieldValue(name, without([value], getNestedObject(name, formik.values) as unknown[]))
  }
  const displaySelectedOptionsCount = (selectedOptions: string[]) => {
    return selectedOptions.length > 1 ? `${selectedOptions.length} rôles attribués` : 'Choisir'
  }
  return (
    <OutlinedField
      {...rest}
      {...formik.getFieldProps(name)}
      SelectProps={{
        multiple: true,
        renderValue: selectType
          ? displaySelectedOptions(handleDelete, listItems)
          : displaySelectedOptionsCount,
        MenuProps: {
          MenuListProps: {
            sx: {
              '& li + li': {
                mt: '12px',
              },
            },
          },
        },
      }}
      error={hasError}
      helperText={hasError ? getNestedObject(name, formik.errors) : ''}
      select
      sx={{ height: 'auto', ...sx }}
    >
      {listItems.map((option) => (
        <MenuItem
          key={option.id}
          disabled={disabledItems.includes(option.id)}
          value={option.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&.Mui-disabled': {
              opacity: 1,
            },
          }}
        >
          <Checkbox
            disabled={disabledItems.includes(option.id)}
            checked={(getNestedObject(name, formik.values) as any[]).includes(option.id)}
            sx={{ '&.Mui-disabled': { color: '#A5A3A3' } }}
          />
          <Box sx={{ mb: '-4px', ml: '3px' }}>{option.name}</Box>
        </MenuItem>
      ))}
    </OutlinedField>
  )
}
