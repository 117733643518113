import Axios from '.'
import { AxiosConfigType } from '../types'
import { FormationType, FormationDetailsType, FormationOtherDatesType, UpdateInscriptionDate} from '../types/api/formations'
import { PaginationResponseSecondFormat, OptionalParamsApi } from '../types/api'
import { generateParamsURL } from '../utils'


const getAutoFormations = async (
  params: OptionalParamsApi,
  type: string | undefined,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponseSecondFormat<FormationType>>(
    `trainings/purchased?type=${type}&${generateParamsURL(params)}`,
    config,
  )
  return response.data
}


const getNextFormations = async (
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponseSecondFormat<FormationType>>(
    `/trainings/purchased?${generateParamsURL(params)}`,
    config,
  )
  return response.data
}


const getNextFormationDetails = async (
  training_id: string | undefined,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<FormationDetailsType>(
    `/trainings/purchased/other/${training_id}`,
    config,
  )
  return response.data
}

const getNextFormationDates = async (
  training_id: string | undefined,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<FormationOtherDatesType[]>(
    `/trainings/purchased/${training_id}/other-dates`,
    config,
  )
  return response.data
}

const updateDateInscription = async (data: UpdateInscriptionDate, config?: AxiosConfigType) => {
  return await Axios.post<any>('/trainings/purchased/change-dates', data, config)
}

const FormationApi = {
    getAutoFormations,
    getNextFormations,
    getNextFormationDetails,
    getNextFormationDates,
    updateDateInscription
}

export default FormationApi