import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import { useContext, useMemo, useState } from 'react'
import useSelectedItemSideBar from '../../hooks/useSelectedItemSideBar'
import { Box, Typography } from '@mui/material'
import OpenEmailIcon from '../../icons/openEmailIcon'
import DownloadIcon from '../../icons/downloadIcon'
import UnreadedEmailIcon from '../../icons/unreadedEmailIcon'
import OutlinedFieldFormik from '../../components/formik/fields/outlinedFieldFormik'
import FieldWithLabel from '../../components/common/fields/fieldWithLabel'
import ContainedButton from '../../components/common/buttons/containedButton'
import SelectFormik from '../../components/formik/fields/selectFormik'
import FormikContext from '../../context/formikContext'
import { useFormik } from 'formik'
import CustomTable from '../../components/common/customTable'
import Title from '../../components/common/title'
import ResetButton from '../../components/common/buttons/resetButton'
import { useGetCommunicationsQuery, useReadNote } from '../../hooks/api/entreprises'
import { CommunicationType } from '../../types/api/entreprise'
import { FilterType } from '../../types/api'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { useQueryClient } from 'react-query'
import useHandleError from '../../hooks/api/useHandleError'
import { getFormatDate } from '../../utils'
import useDownloadDocument from '../../hooks/api/useDownloadDocument'
import useOpenDocument from '../../hooks/api/useOpenDocument'

const categories = [
  {
    id: 'follow',
    name: 'Suivi',
  },
  {
    id: 'call',
    name: 'Appel',
  },
]
const SubjectTitle = () => <Box sx={{ ml: '56px', my: '0' }}>Sujet</Box>
const SubjectBody = ({ row }: any) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '25px',
      ml: '15px',
      ...(row.original.unread === '0' && !row.getIsExpanded()
        ? {
            '& svg path': {
              fill: '#DADCDD',
            },
          }
        : {}),
    }}
  >
    {row.original.unread === '0' || row.getIsExpanded() ? <OpenEmailIcon /> : <UnreadedEmailIcon />}
    <Typography variant='h6'> {row.original.title}</Typography>
  </Box>
)
const CategoryBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>{row.original.category}</Typography>
)
const DateBody = ({ row }: any) => <p>{getFormatDate(row.original.created_at)}</p>
const RowActions = ({ row }: any) => {
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const [downloadDocument] = useDownloadDocument(
    `/companies/${activeEnterprise.crm_id}/notes/${row.original.crm_id}/download`,
    row.original.file_name,
  )
  return (
    <>
      {!row.getIsExpanded() && row.original.file_name && (
        <Box
          onClick={(e) => {
            e.stopPropagation()
            downloadDocument()
          }}
          sx={{ cursor: 'pointer', p: '5px' }}
        >
          <DownloadIcon />
        </Box>
      )}
    </>
  )
}
const DetailPanel = ({ row }: any) => {
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const [openDocument] = useOpenDocument(
    `/companies/${activeEnterprise.crm_id}/notes/${row.original.crm_id}/download`,
  )
  return (
    <>
      <Typography component='p' variant='smallTextNormal' width='95%'>
        {row.original.description}
      </Typography>
      {row.original.file_name && (
        <Box
          component='button'
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            gap: '11px',
            mt: '30px',
            background: 'transparent',
            border: 'none',
          }}
          onClick={(e) => {
            e.stopPropagation()
            openDocument()
          }}
        >
          <DownloadIcon />
          <Typography variant='h6' sx={{ textDecoration: 'underline' }}>
            {row.original.file_name || ''}
          </Typography>
        </Box>
      )}
    </>
  )
}
const Communications = () => {
  useSelectedItemSideBar('entreprise.communications')
  const [filter, setFilter] = useState<FilterType>({})
  const queryClient = useQueryClient()
  const formik = useFormik({
    initialValues: {
      search: '',
      category: '',
    },
    onSubmit: (values) => {
      setPage('1')
      setFilter(values)
    },
  })
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'created_at', desc: true }])
  const [page, setPage] = useState('1')
  const { mutate: readNote, error } = useReadNote({
    onMutate: (crm_id: any) => {
      const previousData = queryClient.getQueryData([
        'communications',
        activeEnterprise.crm_id,
        {
          page,
          sort: sorting[0],
          filter,
        },
      ])
      queryClient.setQueryData(
        [
          'communications',
          activeEnterprise.crm_id,
          {
            page,
            sort: sorting[0],
            filter,
          },
        ],
        (oldData: any) => ({
          ...oldData,
          data: oldData.data.map((item: CommunicationType) =>
            item.crm_id === crm_id ? { ...item, unread: '0' } : item,
          ),
        }),
      )
      return { previousData }
    },
    onError: (err: any, newTodo: any, context: any) => {
      queryClient.setQueryData(
        [
          'communications',
          activeEnterprise.crm_id,
          {
            page,
            sort: sorting[0],
            filter,
          },
        ],
        context.previousData,
      )
    },
  })
  useHandleError(error)
  const columns = useMemo<MRT_ColumnDef<CommunicationType>[]>(
    () => [
      {
        accessorKey: 'title',
        Header: SubjectTitle,
        header: 'Sujet',
        size: 47,
        Cell: SubjectBody,
      },
      {
        accessorKey: 'category',
        header: 'Catégorie',
        size: 24,
        maxSize: 24,
        minSize: 24,
        Cell: CategoryBody,
      },
      {
        accessorKey: 'created_at',
        header: 'Date',
        maxSize: 9,
        minSize: 9,
        size: 9,
        Cell: DateBody,
      },
    ],
    [],
  )
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data, isFetching } = useGetCommunicationsQuery(activeEnterprise.crm_id, {
    page,
    sort: sorting[0],
    filter,
  })

  return (
    <>
      <Title>Communications</Title>
      <Typography variant='h3' mb='19px'>
        Rechercher ou filtrer
      </Typography>
      <Box sx={{ display: 'flex', gap: '24px', mb: '48px' }}>
        <FormikContext.Provider value={formik}>
          <FieldWithLabel label='Mots clés'>
            <OutlinedFieldFormik
              sx={{ width: '320px', mt: '-12px' }}
              name='search'
              placeholder='Nom, sujet, note'
            />
          </FieldWithLabel>
          <FieldWithLabel label='Catégorie'>
            <SelectFormik
              sx={{ width: '320px', mt: '-12px' }}
              name='category'
              listItems={categories}
            />
          </FieldWithLabel>
          <ContainedButton
            sx={{ alignSelf: 'end', width: '158px', minHeight: '42px', height: '42px', py: '6px' }}
            title='rechercher'
            handleClick={() => {
              formik.handleSubmit()
            }}
          />
        </FormikContext.Provider>
      </Box>
      {formik.isSubmitting && formik.dirty && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            mb: '16px',
          }}
        >
          <Typography sx={{ fontWeight: '500' }}>
            Résultats : {data?.meta?.total ? data.meta.total : 0}
          </Typography>
          <ResetButton
            handleClick={() => {
              setPage('1')
              setFilter({})
              formik.resetForm()
            }}
          />
        </Box>
      )}
      <CustomTable
        muiTableBodyRowProps={({ row, isDetailPanel }) => ({
          onClick: async () => {
            if (row.original.unread === '1' && !row.getIsExpanded()) {
              await readNote(row.original.crm_id)
            }
            row.toggleExpanded()
          },
          hover: false,
          sx: {
            cursor: 'pointer',
            ...(isDetailPanel &&
              !row.getIsExpanded() && {
                height: '0px',
                overflow: 'hidden',
              }),

            ...(!isDetailPanel && row.original.unread === '1' && !row.getIsExpanded()
              ? { backgroundColor: 'primary.background' }
              : {}),
          },
        })}
        data={data?.data ?? []}
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 17,
            maxSize: 17,
            minSize: 17,
            header: '',
            muiTableBodyCellProps: {
              align: 'center',
            },
          },
        }}
        enableExpandAll={false}
        enableRowActions
        enableExpanding
        enableSortingRemoval={false}
        enableMultiSort={false}
        manualSorting
        state={{ sorting, isLoading: isFetching }}
        onSortingChange={(params) => {
          setPage('1')
          setSorting(params)
        }}
        renderRowActions={RowActions}
        positionActionsColumn='last'
        renderDetailPanel={DetailPanel}
        propsPagination={{
          page: Number(page),
          count: data?.meta?.last_page ? Number(data?.meta?.last_page) : 0,
          onChange: (e, index) => {
            setPage(index.toString())
          },
        }}
      />
    </>
  )
}

export default Communications
