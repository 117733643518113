import { useContext, useState, Fragment } from 'react'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import { Stack, SxProps, Theme } from '@mui/material'
import { ContextType, SidebarContext } from '../../context/sideBarContext'
import { t } from 'i18next'
import { useNavigate, Link } from 'react-router-dom'
import { ItemHasChildrenType, ItemType } from '../../types'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { hasAccess, hasChildren } from '../../utils'
import { LocalStorageKeys, authorization, itemsSubBar } from '../../constants'
import OpenCloseArrowIcon from '../../icons/openCloseArrowIcon'

interface PropsItemComponent {
  handleClick?: () => void
  children: React.ReactNode
  hasSubItems?: boolean
  sx?: SxProps<Theme>
}
const Item = ({ handleClick, children, sx, hasSubItems = true }: PropsItemComponent) => {
  return (
    <Typography
      component='li'
      sx={{
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
        ...(hasSubItems ? {} : { borderBottom: '1px solid #DADCDD', pb: '15px' }),
        ...sx,
      }}
      onClick={handleClick}
    >
      {children}
    </Typography>
  )
}
const styleBeforeSelectedItem = {
  position: 'absolute',
  content: '""',
  width: '8px',
  height: '8px',
  backgroundColor: 'secondary.main',
  left: '-17px',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
}
const stylesSelectedItem = {
  position: 'relative',
  '&:before': {
    ...styleBeforeSelectedItem,
    top: '32%',
  },
}
const stylesSelectedSubItem = {
  position: 'relative',
  '&:before': styleBeforeSelectedItem,
}

interface PropsListItems {
  item: ItemHasChildrenType
}
const getOpenItems = () =>
  JSON.parse(localStorage.getItem(LocalStorageKeys.OpenItemsSideBar)!) || []
const addItemToList = (item: string) => {
  const list = getOpenItems()
  list.push(item)
  localStorage.setItem(LocalStorageKeys.OpenItemsSideBar, JSON.stringify(list))
}
const isItemInList = (item: string) => {
  if (localStorage.getItem(LocalStorageKeys.OpenItemsSideBar) === null && item === 'myProfile')
    return true
  return getOpenItems().includes(item)
}
const deleteItemFromList = (item: string) => {
  const list = getOpenItems()
  const index = list.indexOf(item)
  if (index > -1) {
    list.splice(index, 1)
    localStorage.setItem(LocalStorageKeys.OpenItemsSideBar, JSON.stringify(list))
  }
}
const ListItems = ({ item }: PropsListItems) => {
  const { selectedItemSidebar } = useContext(SidebarContext) as ContextType
  const [open, setOpen] = useState(isItemInList(item.item) as boolean)
  const handleToogle = () => {
    isItemInList(item.item) ? deleteItemFromList(item.item) : addItemToList(item.item)
    setOpen((open) => !open)
  }
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  return (
    <List
      component='nav'
      aria-labelledby='nested-list-subheader'
      sx={{ borderBottom: '1px solid #DADCDD', pb: '15px', pt: '0px' }}
    >
      <Item handleClick={handleToogle}>
        <span>{t(`sideBar.${item.label}`)}</span>
        <OpenCloseArrowIcon open={open} />
      </Item>
      <Collapse
        in={open}
        timeout='auto'
        unmountOnExit
        sx={{
          pl: '18px',
        }}
      >
        {item.children
          .filter((item) =>
            hasAccess(authorization[item.mainRoute]?.read ?? null, activeEnterprise),
          )
          .map((subItem) => (
            <Typography
              key={subItem.item}
              variant='h6'
              component='li'
              sx={{
                mt: '19px',
                '& a': {
                  textDecoration: 'none',
                  color: 'primary.main',
                },
                ...(selectedItemSidebar === subItem.item ? stylesSelectedSubItem : {}),
              }}
            >
              <Link to={subItem.mainRoute}>{t(`sideBar.${subItem.label}`)}</Link>
            </Typography>
          ))}
      </Collapse>
    </List>
  )
}

const ListSideBar = () => {
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { selectedItemSidebar } = useContext(SidebarContext) as ContextType
  const navigate = useNavigate()
  const hasMoreThanOneChild = (item: ItemType | ItemHasChildrenType): item is ItemHasChildrenType =>
    hasChildren(item) &&
    item.children.filter((item) =>
      hasAccess(authorization[item.mainRoute]?.read ?? null, activeEnterprise),
    ).length > 1

  return (
    <Stack spacing='18px'>
      {itemsSubBar
        .filter((item) => hasAccess(item.roles, activeEnterprise))
        .map((item) => (
          <Fragment key={item.item}>
            {hasMoreThanOneChild(item) ? (
              <ListItems item={item} />
            ) : (
              <Item
                sx={{ ...(item.item === selectedItemSidebar ? stylesSelectedItem : {}) }}
                hasSubItems={false}
                handleClick={() => navigate(item.mainRoute)}
              >
                <span>{t(`sideBar.${item.label}`)}</span>
              </Item>
            )}
          </Fragment>
        ))}
    </Stack>
  )
}
export default ListSideBar
