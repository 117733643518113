import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'
import { useContext } from 'react'
import { EditableContext } from '../../../context/editableContext'
import Label from '../label'
interface Props {
  label: string
  required?: boolean
  sx?: SxProps<Theme>
  value: string
  children: React.ReactNode
}

const EditableTextField = ({ label, value, required = false, sx, children }: Props) => {
  const { isEditable } = useContext(EditableContext)
  return (
    <Box sx={{ ...sx }}>
      <Label required={required} label={label} sx={{ mb: isEditable ? '3px' : '16px' }} />
      {isEditable ? children : <Typography>{value}</Typography>}
    </Box>
  )
}

export default EditableTextField
