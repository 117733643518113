import { FormControlLabel } from '@mui/material'
import Checkbox from './checkbox'

const CheckboxWithLabel = ({
  label,
  sx,
  ...props
}: { label: string } & React.ComponentProps<typeof Checkbox>) => {
 
  return (
    <FormControlLabel sx={{ ml: '-3px', ...sx }} label={label} control={<Checkbox {...props} />} />
  )
}

export default CheckboxWithLabel
