import { useState } from 'react'
import BodyModalWithActions from '../../../../components/common/modals/bodyModalWithActions'
import { Typography } from '@mui/material'
import ContainedButton from '../../../../components/common/buttons/containedButton'
import SimpleButton from '../../../../components/common/buttons/simpleButton'
import { useDeleteUser, EnterprisesServerStateKeysEnum } from '../../../../hooks/api/entreprises'
import useHandleError from '../../../../hooks/api/useHandleError'
import { UserEntrepriseType } from '../../../../types/api/entreprise'
import { t } from 'i18next'
import { useQueryClient } from 'react-query'
interface Props {
  user: UserEntrepriseType | undefined
  handleClose: () => void
}
const BodyModalConfirmDeleteUser = ({ user, handleClose }: Props) => {
  const query = useQueryClient()
  const { mutate: deleteUser, error } = useDeleteUser()
  useHandleError(error)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)
  const deleteHandler = (): void => {
    setDisableDeleteButton(true)
    deleteUser(
      {
        user_role_crm_id: user?.user_role_id,
      },
      {
        onSettled: async () => {
          await query.invalidateQueries(EnterprisesServerStateKeysEnum.Users)
          handleClose()
          setDisableDeleteButton(false)
        },
      },
    )
  }
  return (
    <BodyModalWithActions
      title={t('pages.users.deleteUserTitle')}
      type='warning'
      content={
        <>
          {user && (
            <Typography variant='smallText'>{`${user.first_name} ${user.last_name}`}</Typography>
          )}
          <Typography>{t('pages.users.deleteUser')}</Typography>
        </>
      }
      actions={
        <>
          <SimpleButton sx={{ py: '0' }} handleClick={handleClose} />
          <ContainedButton
            title='Retirer'
            sx={{ py: '0' }}
            disabled={disableDeleteButton}
            handleClick={() => {
              user && deleteHandler()
            }}
          />
        </>
      }
    />
  )
}

export default BodyModalConfirmDeleteUser
