import BodyModalWithActions from '../../../../components/common/modals/bodyModalWithActions'
import UpdateUserForm from '../../../../components/forms/entrteprise/users'
import { UserEntrepriseType } from '../../../../types/api/entreprise'
interface Props {
  user: UserEntrepriseType | undefined
  handleClose: () => void
}
const BodyModalUpdateUser = ({ user, handleClose }: Props) => {
  return (
    <BodyModalWithActions
      title={'Modifier les informations d’un utilisateur'}
      content={<UpdateUserForm user={user} cancelFn={handleClose} />}
    />
  )
}

export default BodyModalUpdateUser
