
import memberBenefitsApi from '../../api/memberBenefitsApi'
import {
  memberAventageType,
} from '../../types/api/memberAventages'
import useCustomQuery from './useCustomQuery'
import { OptionalParamsApi, OptionsTypeReactQuery, PaginationResponse, PaginationResponseSecondFormat } from '../../types/api'

export enum MemberAventageTypeServerStateKeysEnum {
    Avent = 'aventages',
}
export const useGetMemberBenefits = (crm_id: string, options?: OptionsTypeReactQuery<memberAventageType>) =>
  useCustomQuery<memberAventageType, Error>(
    [MemberAventageTypeServerStateKeysEnum.Avent, crm_id],
    (header) => memberBenefitsApi.getMemberBenefits(crm_id, header),
    options,
  )
