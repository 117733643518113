import { useMemo, useState, ChangeEvent, useCallback, useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import FormikContext from '../../../../context/formikContext'
import FieldWithLabel from '../../../../components/common/fields/fieldWithLabel'
import OutlinedFieldFormik from '../../../../components/formik/fields/outlinedFieldFormik'
import ContainedButton from '../../../../components/common/buttons/containedButton'
import ResetButton from '../../../../components/common/buttons/resetButton'
import CheckboxWithLabel from '../../../../components/common/fields/checkboxWithLabel'
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import CustomTable from '../../../../components/common/customTable'
import DownloadButton from '../../../../components/common/buttons/downloadButton'
import Checkbox from '../../../../components/common/fields/checkbox'
import { assoc, dissoc } from 'ramda'
import { isEmpty } from 'lodash'
import {  useGetCertificatesQuery } from '../../../../hooks/api/certificates'
import {CertificateType} from '../../../../types/api/certificates'
import useDownloadDocument from '../../../../hooks/api/useDownloadDocument'
import { FilterType } from '../../../../types/api'
import {getDate} from '../../../../utils'
import { ActiveEnterpriseContext } from '../../../../context/activeEnterpriseContext'
import Loader from  '../../../../components/common/loader'


type RowSelectionType = string[]
const RowActions = ({ row }: any) => (
  <>{row.original.file && <DownloadButton sx={{ mr: '21px' }} />}</>
)

const DateBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>{getDate(row.original.date, null)}</Typography>
)
const ParticipantBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>{row.original.participant_full_name}</Typography>
)
const TitleBody = ({ row }: any) => <Typography variant='h6'>{row.original.title}</Typography>
const CertificateColumn = () => <Box sx={{ pr: '24px' }}>Attestation</Box>
const MyCertificates = () => {
  const [rowSelection, setRowSelection] = useState<RowSelectionType>([] as RowSelectionType)
  const [page, setPage] = useState('1')
  const [filter, setFilter] = useState<FilterType>({})
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id:'title', desc: true }])
  const [certificates, setCertificates] = useState<Array<string>|undefined>([])
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const {data , isFetching} = useGetCertificatesQuery({
    member_crm_id: activeEnterprise?.crm_id,
    page,
    sort: sorting[0],
    filter
  })
  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      setPage('1')
      setFilter(values)
    },
  })
  const columns = useMemo<MRT_ColumnDef<CertificateType>[]>(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        size: 15,
        enableSorting: true,
        maxSize: 15,
        minSize: 15,
        Cell: DateBody,
      },
      {
        accessorKey: 'participant_full_name',
        header: 'Participant',
        size: 20,
        enableSorting: true,
        maxSize: 20,
        minSize: 20,
        Cell: ParticipantBody,
      },
      {
        accessorKey: 'title',
        header: 'Formation',
        size: 49,
        enableSorting: true,
        maxSize: 49,
        minSize: 49,
        Cell: TitleBody,
      },
    ],
    [],
  )

  const RowActions = ({ row }: any) => {
    const [downloadDocument] = useDownloadDocument(
      `certificates/download?member_crm_id=${activeEnterprise.crm_id}&certificates[]=${row.original.crm_id}`,
      row.original.title,
    )
    return  <DownloadButton onClick={downloadDocument} />
  }
  const downloadAll = () => {
    const certificationList = rowSelection.map((item)=> 
      `&certificates[]=${item}`
    )
    const [downloadDocument] = useDownloadDocument(
      `certificates/download?member_crm_id=${activeEnterprise.crm_id}&certificates[]=${certificationList.join('')}`,
      'certifications',
    )
    

    return downloadDocument ;
  }

  const BodySelectRow = useCallback(
    ({ row }: any) => (
      <Checkbox
        sx={{ ml: '-3px' }}
        checked={rowSelection.includes(row.id)}
        onClick={() => {
          if ( rowSelection.includes(row.id)) {
            setRowSelection(rowSelection.filter((elem) => elem !== row.id))
          } else {
            setRowSelection((rowSelection) => [...rowSelection, row.id ])
          }
        }}
      />
    ),
    [rowSelection, setRowSelection],
  )


  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '48px' }}
      >
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <FormikContext.Provider value={formik}>
            <FieldWithLabel label='Rechercher'>
              <OutlinedFieldFormik
                sx={{ width: '416px', mt: '-12px' }}
                name='search'
                placeholder='Nom, formation'
              />
            </FieldWithLabel>

            <ContainedButton
              sx={{
                alignSelf: 'end',
                width: '140px',
                minHeight: '44px',
                height: '44px',
                py: '6px',
                fontSize: '12px',
              }}
              title='rechercher'
              handleClick={() => formik.handleSubmit()}
            />
          </FormikContext.Provider>
        </Box>
        {formik.isSubmitting && formik.dirty && (
          <ResetButton handleClick={() => {
            setPage('1')
            setFilter({})
            formik.resetForm()
          }} />
        )}
      </Box>
      <Typography sx={{ fontWeight: '500', mb: '15px!important' }}>Résultats : {data?.metadata?.total ? data.metadata.total : 0}</Typography>
      {
        (data && data?.data.length >0 )?  
            <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '15px' }}
          >
          <CheckboxWithLabel
            label='Sélectionner tout'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const rowSeelection = 
              e.target.checked ?
              data?data?.data.map((elm)=>
              elm.crm_id
              )
              : [] : []
              setRowSelection(rowSeelection);
            }
          }
            sx={{
              mx: '0px',
              '& .MuiFormControlLabel-label': {
                ml: '3px',
              },
            }}
          />
          <Box  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '5px' }}>
            <Typography variant='bodySmall'> {rowSelection?.length > 1 ? `${rowSelection?.length} items sélectionnés` : rowSelection?.length === 1 ? `${rowSelection?.length} item sélectionné`  : null} </Typography>
            <Button
              sx={{
                alignSelf: 'end',
                width: '145px',
                minHeight: '44px',
                height: '44px',
                fontWeight: '700',
                py: '6px',
                letterSpacing: '1px',
                borderRadius: 'inherit',
                fontSize: '12px',
                border: '2px solid #444D4E',
                '&.Mui-disabled': {
                  color: '#b4b8b8',
                  border: '2px solid #b4b8b8',
                },
                '&:hover': {
                  color: 'white',
                  backgroundColor: 'primary.main',
                },
              }}
              disabled={isEmpty(rowSelection)}
              onClick={downloadAll()}
            >
              Télécharger
            </Button>
          </Box>
      </Box>
         : null
      }
    
    {isFetching ?  <Box sx={{with:'100%', 'heigt':'200px',display:'flex',alignItems:'center',justifyContent:'center'}}><Loader/></Box> : 
    
    
    <CustomTable
    data={data?.data??[]}
    columns={columns}
    enableSorting={true}
    muiTableBodyRowProps={{ hover: false }}
    displayColumnDefOptions={{
      'mrt-row-actions': {
        size: 9,
        maxSize: 9,
        minSize: 9,
        header: 'Attestation',
        Header: CertificateColumn,
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableHeadCellProps: {
          align: 'right',
        },
      },
      'mrt-row-select': {
        header: '',
        enableColumnActions: false,
        size: 2,
        maxSize: 2,
        minSize: 2,
        Cell: BodySelectRow,
      },
    }}
    enableRowActions
    positionActionsColumn='last'
    enableRowSelection
    state={{ sorting, isLoading: isFetching }}
    renderRowActions={RowActions}
    enableSelectAll={false}
    getRowId={(originalRow) => originalRow.crm_id}
    propsPagination={{
      page: Number(page),
      count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
      onChange: (e, index) => {
        setPage(index.toString())
      },
    }}
    onSortingChange={(params) => {
      setPage('1')
      setSorting(params)
    }}
  />
    }
     
    </>
  )
}

export default MyCertificates
