const ExclamationPointIcon = () => {
  return (
    <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 20C0.5 31.0457 9.4543 40 20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C15.1957 0 10.1086 2.10714 6.35786 5.85786C2.60714 9.60859 0.5 14.6957 0.5 20ZM22.5 30V26H18.5V30H22.5ZM22.5 22V10H18.5V22H22.5Z'
        fill='#F9A51A'
      />
    </svg>
  )
}

export default ExclamationPointIcon
