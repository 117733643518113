import { useAuth0 } from '@auth0/auth0-react'
import { UseMutationOptions, useMutation } from 'react-query'
import { AxiosConfigType } from '../../types'
import { AxiosErrorData } from '../../types/api'

const useCustomMutation = <TQuery>(
  queryFn: (data: TQuery, config?: AxiosConfigType) => Promise<any>,
  options?:
    | Omit<UseMutationOptions<any, AxiosErrorData, TQuery, unknown>, 'mutationFn'>
    | undefined,
) => {
  const { getAccessTokenSilently } = useAuth0()
  return useMutation(async (data: TQuery) => {
    const token = await getAccessTokenSilently()
    return queryFn(data, { headers: { Authorization: `Bearer ${token}` } })
  }, options)
}

export default useCustomMutation
