import { useMemo, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import CustomTable from '../../../../components/common/customTable'
import DownloadButton from '../../../../components/common/buttons/downloadButton'
import {  useGetCertificatesQuery } from '../../../../hooks/api/certificates'
import {CertificateType} from '../../../../types/api/certificates'
import useDownloadDocument from '../../../../hooks/api/useDownloadDocument'
import {getDate} from '../../../../utils'


const DateTitle = () => <Box sx={{ pl: '15px' }}>Date</Box>
const DateBody = ({ row }: any) => (
  <Typography variant='smallTextNormal' sx={{ pl: '15px' }}>
    {getDate(row.original.date, null)}
  </Typography>
)
const TitleBody = ({ row }: any) => <Typography variant='h6'>{row.original.title}</Typography>
const CertificateColumn = () => <Box sx={{ pr: '24px' }}>Attestation</Box>

const MyCertificates = () => {
  const [page, setPage] = useState('1')
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'title', desc: true }])
  const {data , isFetching} = useGetCertificatesQuery({
    page,
    sort: sorting[0],
  })
  const columns = useMemo<MRT_ColumnDef<CertificateType>[]>(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        Header: DateTitle,
        size: 18,
        enableSorting: true,
        maxSize: 18,
        minSize: 18,
        Cell: DateBody,
      },
      {
        accessorKey: 'title',
        header: 'Formation',
        size: 68,
        enableSorting: true,
        maxSize: 68,
        minSize: 68,
        Cell: TitleBody,
      },
    ],
    [],
  )
  const RowActions = ({ row }: any) => {
    const [downloadDocument] = useDownloadDocument(
      `certificates/download?certificates[]=${row.original.crm_id}`,
      row.original.title,
    )
    return  <DownloadButton onClick={downloadDocument} />
  }
  return (
    <CustomTable
      data={data?.data??[]}
      columns={columns as MRT_ColumnDef<Record<string, any>>[]}
      enableSortingRemoval={false}
      enableSorting={true}
      enableMultiSort={false}
      manualSorting
      displayColumnDefOptions={{
        'mrt-row-actions': {
          size: 14,
          maxSize: 14,
          minSize: 14,
          header: 'Attestation',
          Header: CertificateColumn,
          muiTableBodyCellProps: {
            align: 'right',
          },
          muiTableHeadCellProps: {
            align: 'right',
          },
        },
      }}
      enableRowActions
      renderRowActions={RowActions}
      positionActionsColumn='last'
      muiTableBodyRowProps={{ hover: false }}
      onSortingChange={(params) => {
        setPage('1')
        setSorting(params)
      }}
      propsPagination={{
        page: Number(page),
        count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
        onChange: (e, index) => {
          setPage(index.toString())
        },
      }}
      state={{ sorting, isLoading: isFetching }}
    />
  )
}
export default MyCertificates
