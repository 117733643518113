import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AuthenticationGuard } from './components/layout/authentificationGuard'
import PersonnalInformations from './pages/profile/personnalInformations'
import Preferences from './pages/profile/preferences'
import EntrepriseProfile from './pages/entreprise/entrepriseProfile'
import Forbidden from './pages/error/forbidden'
import Layout from './components/layout'
import LayoutWithoutMenu from './components/layout/layoutWithoutMenu'
import Users from './pages/entreprise/users'
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate'
import ErrorBoundary from './components/layout/errorBoundry'
import MyEvents from './pages/events/myEvents'
import EnterpriseEvent from './pages/events/enterpriseEvent'
import MemberBenefits from './pages/memberBenefits'
import Communications from './pages/entreprise/communications'
import Documents from './pages/entreprise/documents'
import MyFormations from './pages/formations/myFormations'
import FormationEnterprise from './pages/formations/formationEnterprise'
import EnterpriseOrders from './pages/orders/enterpriseOrders'
import MyOrders from './pages/orders/myOrders'
import Order from './pages/orders/order'
import './app.css'






const router = createBrowserRouter([
  {
    element: (
      <Auth0ProviderWithNavigate>
        <AuthenticationGuard component={Layout} />
      </Auth0ProviderWithNavigate>
    ),
    children: [
      {
        path: '/events/my-events',
        element: <MyEvents />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/events/enterprise-event',
        element: <EnterpriseEvent />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/formations/my-formations',
        element: <MyFormations />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/formations/formation-enterprise',
        element: <FormationEnterprise />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/member-benefits',
        element: <MemberBenefits />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/entreprise/entreprise-profile',
        element: <EntrepriseProfile />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/entreprise/users',
        element: <Users />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/entreprise/communications',
        element: <Communications />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/entreprise/documents',
        element: <Documents />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/entreprise/commandes',
        element: <EnterpriseOrders />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/profile/personal-informations',
        element: <PersonnalInformations />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/profile/preferences',
        element: <Preferences />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/profile/commandes',
        element: <MyOrders/>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/',
        element: <PersonnalInformations />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/forbidden',
        element: <Forbidden />,
        errorElement: <ErrorBoundary />,
      }
    ],
  },
  {
    element: (
      <Auth0ProviderWithNavigate>
        <AuthenticationGuard component={LayoutWithoutMenu} />
      </Auth0ProviderWithNavigate>
    ),
    children: [
      {
        path: '/commande/:id',
        element: <Order />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
])
function App() {
  return <RouterProvider router={router} />
}

export default App
