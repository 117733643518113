import { useMemo, useState, useContext } from 'react'
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import Title from '../../components/common/title'
import useSelectedItemSideBar from '../../hooks/useSelectedItemSideBar'
import CustomTable from '../../components/common/customTable'
import { Box, Typography } from '@mui/material'
import OpenCloseArrowIcon from '../../icons/openCloseArrowIcon'
import {  useGetEventsQuery } from '../../hooks/api/events'
import {EventsType, activities} from '../../types/api/events'
import {getDate} from '../../utils'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
interface Event {
  date: string
  title: string
  user: string
  description: string
  participantsList: string[]
}
const events = [
  {
    date: '19 avril 2023',
    title: 'Soirée Poker 2023',
    user:'James Parker',
    description: 'Brunch, golf, voiturette, cocktail',
    participantsList: ['Jean-Pierre Bernard', 'Mélanie Tessier', 'Joël Fortin-Champagne'],
  },
  {
    date: '11 mai 2023',
    title: 'Tournoi de golf 2023 de l’ACQ Métropolitaine',
    user:'James Parker',
    description: 'Cocktail et banquet seulement',
    participantsList: ['Jean-Pierre Bernard', 'Mélanie Tessier', 'Joël Fortin-Champagne'],
  },
]
const DateTitle = () => <Box sx={{ pl: '15px' }}>Date</Box>
const DateBody = ({ row }: any) => (
  <Typography variant='smallTextNormal' pl='15px'>
    {getDate(row.original.start_date, row.original.end_date)}
  </Typography>
)
const TitleBody = ({ row }: any) => (
  <Typography component='a' variant='h6' 
  href={`${process.env.REACT_APP_ACQ_SITE}/entrepreneurs/evenements/${row.original.crm_id}`}
  target='_blank'
  sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
    {row.original.title}
  </Typography>
)


const UserTitle = () => <Box sx={{ pl: '15px' }}>Acheteur</Box>


const UserBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>
    {row.original.buyer_full_name}
  </Typography>
)

const DetailPanel = ({ row }: any) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
    }}
  >
    <Box width='38%' sx={{ display: 'flex' }}>
        <Typography component='ul' sx={{ display: 'flex', flexDirection: 'column' , gap:'10px', paddingInlineStart: 0, listStyle: 'none' }}>
          {row.original.activities && row.original.activities.map((item: activities) => (
            <Typography component='li' key={item.title} variant='h6'>
              {item.title}
            </Typography>
          ))}
          </Typography>
      </Box>

    <Box width='60%' sx={{ display: 'flex' }}>
              <Typography component='ul' sx={{ 
                display: 'flex',
                columns: '2',
                gap:'10px',
                paddingInlineStart: 0,
                justifyContent: 'space-between',
                width: '100%', listStyle: 'none'  }} >
                {row.original.activities && row.original.activities.map((item: activities) => (
                  <Typography component='li' key={item.title} variant='smallTextNormal'>
                    {item.participants}
                  </Typography>
                ))}
        </Typography>
    </Box>
  </Box>
)
const ExpandBody = ({ row }: any) => (
  <Box
    onClick={() => row.toggleExpanded()}
    sx={{
      mr: '12px',
      cursor: 'pointer',
      p: '5px',
      display: 'flex',
      alignItems: 'center',
      gap: '9px',
    }}
  >
    <Typography variant='smallTextNormal'>Voir les participants</Typography>
    <OpenCloseArrowIcon open={row.getIsExpanded()} />
  </Box>
)
const EnterpriseEvent = () => {
  useSelectedItemSideBar('events.enterpriseEvent')
  const [page, setPage] = useState('1')
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id:'start_date', desc: true }])
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const {data , isFetching} = useGetEventsQuery({
    member_crm_id: activeEnterprise?.crm_id,
    page,
    sort: sorting[0],
  })
  const columns = useMemo<MRT_ColumnDef<EventsType>[]>(
    () => [
      {
        accessorKey: 'start_date',
        header: 'Date',
        Header: DateTitle,
        maxSize: 29,
        minSize: 29,
        size: 29,
        enableSorting: true,
        Cell: DateBody,
      },
      {
        accessorKey: 'buyer_full_name',
        header: 'Acheteur',
        Header: UserTitle,
        maxSize: 29,
        minSize: 29,
        size: 29,
        enableSorting: true,
        Cell: UserBody,
      },
      {
        accessorKey: 'title',
        header: 'événement',
        size: 54,
        enableSorting: true,
        maxSize: 54,
        minSize: 54,
        Cell: TitleBody,
      },
    ],
    [],
  )
  return (
    <>
      <Title sx={{ mb: '48px' }}>événements entreprise</Title>
      <CustomTable
        muiTableBodyRowProps={({ row, isDetailPanel }) => ({
          sx: {
            ...(isDetailPanel &&
              !row.getIsExpanded() && {
                height: '0px',
                overflow: 'hidden',
              }),
          },
          hover: false,
        })}
        data={data?.data??[]}
        columns={columns}
        renderDetailPanel={DetailPanel}
        displayColumnDefOptions={{
          'mrt-row-expand': {
            size: 41,
            maxSize: 41,
            minSize: 41,
            header: '',
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                px: '0',
                justifyContent: 'end',
                borderBottom: '1px solid #DADCDD',
                ...(row.getIsExpanded() ? { borderBottom: 'none' } : {}),
              },
            }),
            Cell: ExpandBody,
          },
        }}
        muiTableDetailPanelProps={({ row }) => ({
          sx: {
            width: '100%',
            pt: '3px',
            '& .MuiCollapse-root': {
              width: '100%',
            },
            ...(row.getIsExpanded() ? { pb: '36px' } : {}),
          },
        })}
        state={{ sorting, isLoading: isFetching }}
        enableExpanding
        enableSortingRemoval={false}
        enableMultiSort={false}
        manualSorting
        propsPagination={{
          page: Number(page),
          count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
          onChange: (e, index) => {
            setPage(index.toString())
          },
        }}
        onSortingChange={(params) => {
          setPage('1')
          setSorting(params)
        }}
      />
    </>
  )
}

export default EnterpriseEvent
