import { InputAdornment } from '@mui/material'
import { FieldConfig } from 'formik'
import OutlinedField from '../../common/fields/outlinedField'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FormikContext from '../../../context/formikContext'
import { useContext } from 'react'
import { getNestedObject } from '../../../utils'
type Props = FieldConfig & React.ComponentProps<typeof OutlinedField>
const inputProps = {
  endAdornment: (
    <InputAdornment position='end'>
      <ErrorOutlineIcon sx={{ color: 'error.main' }} />
    </InputAdornment>
  ),
}
const OutlinedFieldFormik = ({ name, InputProps, ...props }: Props) => {
  const formik = useContext(FormikContext)
  const hasError = Boolean(
    getNestedObject(name, formik.touched) && getNestedObject(name, formik.errors),
  )
  return (
    <OutlinedField
      {...props}
      {...formik.getFieldProps(name)}
      error={hasError}
      helperText={hasError ? getNestedObject(name, formik.errors) : ''}
      InputProps={{
        ...(hasError ? inputProps : {}),
        ...InputProps,
      }}
    />
  )
}

export default OutlinedFieldFormik
