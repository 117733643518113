import { Member } from './../types/api/user'
import React from 'react'
export interface ActiveEnterpriseContextType {
  activeEnterprise: Member
  setActiveEnterprise: (selectedItem: Member) => void
}
export const ActiveEnterpriseContext = React.createContext<ActiveEnterpriseContextType>({
  activeEnterprise: {} as Member,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveEnterprise: () => {},
})
