

import {
    EventsType,
  } from '../../types/api/events'
import EventsApi from '../../api/eventsApi'
import useCustomQuery from './useCustomQuery'
import { OptionsTypeReactQuery, PaginationResponseSecondFormat, OptionalParamsApi } from '../../types/api'
export const useGetEventsQuery = (
    params: OptionalParamsApi,
    options?: OptionsTypeReactQuery<PaginationResponseSecondFormat<EventsType>>,
  ) =>
    useCustomQuery<PaginationResponseSecondFormat<EventsType>, Error>(
      ['events', params],
      async (header) => await EventsApi.getEvents(params, header),
      options,
  )