import React, { createContext } from 'react'
interface EditableContextType {
  isEditable: boolean
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
}
export const EditableContext = createContext<EditableContextType>({
  isEditable: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsEditable: () => {},
})
