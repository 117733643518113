import React from 'react'
import ArrowDownloadIcon from '../../../icons/arrowDownloadIcon'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'
interface Props {
  sx?: SxProps<Theme>
}
const DownloadButton = ({ sx, ...rest }: React.ComponentProps<typeof Box>) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        p: '5px',
        mr: '20px',
        '&:hover svg path': {
          fill: '#5F686A',
        },
        ...sx,
      }}
      {...rest}
    >
      <ArrowDownloadIcon />
    </Box>
  )
}

export default DownloadButton
