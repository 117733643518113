import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface Props {
  subtotal: string
  discount?: string | null
  code_promo_value?: string | null
  subtotal_with_discount?: string| null
  tps?: string | null
  tpsNumber?: string | null
  tvq?: string | null
  tvqNumber?: string | null
  total: string | null
}
const defaultStyle = {
    padding: '30px 0', 
    borderBottom: '1px solid #5F686A', 
    display:'flex', 
    flexDirection:'column', 
    gap:'16px'
}
const OrderResume = ({ subtotal, discount, code_promo_value, subtotal_with_discount, tps, tvq, total, tpsNumber, tvqNumber}: Props) => {
  return (
        <>
            <Box sx={defaultStyle}>
                {subtotal && (subtotal !== '') && (
                    <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'8px'}}>
                    <Typography variant='h5' sx={{letterSpacing: '0'}}> 
                        Sous-total 
                    </Typography>
                    <Typography variant='body1'> 
                        {subtotal} $
                    </Typography>
                </Box>
                )}
                {discount && (discount !== '') && (
                    <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between',gap:'8px'}}>
                    <Typography variant='h5' sx={{letterSpacing: '0'}}> 
                        Rabais
                    </Typography>
                    <Typography variant='body1'> 
                        - {discount} $
                    </Typography>
                </Box>
                )}
                {code_promo_value && (code_promo_value !== '') &&(
                    <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between',gap:'8px'}}>
                    <Typography variant='h5' sx={{letterSpacing: '0'}}> 
                        Codes promos 
                    </Typography>
                    <Typography variant='body1'> 
                        - {code_promo_value} $
                    </Typography>
                </Box>
                )}
                {subtotal_with_discount && (subtotal_with_discount !== '') && (
                    <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between',gap:'8px'}}>
                    <Typography variant='h5' sx={{letterSpacing: '0'}}> 
                    Sous-total avec rabais appliqués 
                    </Typography>
                    <Typography variant='body1'> 
                        {subtotal_with_discount} $
                    </Typography>
                </Box>
                )}
                {tps && (tps !== '') && (
                    <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between',gap:'8px'}}>
                    <Typography variant='h5' sx={{letterSpacing: '0'}}> 
                        TPS {tpsNumber ? tpsNumber : ''} 
                    </Typography>
                    <Typography variant='body1'> 
                        {tps} $
                    </Typography>
                </Box>
                )}
                {
                tvq && (tvq !== '') && (
                    <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between',gap:'8px'}}>
                        <Typography variant='h5' sx={{letterSpacing: '0'}}> 
                            TVQ {tvqNumber ? tvqNumber : ''}
                        </Typography>
                        <Typography variant='body1'>
                            {tvq} $
                        </Typography>
                    </Box>
                )
                }
            </Box>
            {total && 
                (<Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between',gap:'8px' , marginTop:'30px'}}>
                    <Typography variant='h4' sx={{letterSpacing: '0'}}> 
                        Total 
                    </Typography>
                    <Typography variant='bodyLarge'> 
                        {total} $
                    </Typography>
                </Box>)
            }
        </>
  )
}
export default OrderResume
