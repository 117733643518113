import { Box, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'
import ResetIcon from '../../../icons/resetIcon'
interface Props {
  handleClick?: () => void
  sx?: SxProps<Theme>
}
const ResetButton = ({ handleClick, sx }: Props) => {
  return (
    <Box
      sx={{ display: 'flex', border: 'none', backgroundColor: 'white', cursor: 'pointer', ...sx }}
      component='button'
      onClick={handleClick}
    >
      <ResetIcon />
      <Typography
        variant='smallTextNormal'
        sx={{
          textDecoration: 'underline',
          ml: '6px',
          fontWeight: '500',
          display: 'block',
          mt: '3px',
        }}
      >
        Réinitialiser
      </Typography>
    </Box>
  )
}

export default ResetButton
