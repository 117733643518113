import { Autocomplete } from '@mui/material'
import { FieldConfig } from 'formik'
import { useContext, useState } from 'react'
import FormikContext from '../../../context/formikContext'
import { Option } from '../../../types'
import { getNestedObject } from '../../../utils'
import OutlinedField from '../../common/fields/outlinedField'

interface Options {
  listItems: Option[],
  placeholder: string
}

type Props = FieldConfig &
  Omit<React.ComponentProps<typeof Autocomplete>, 'renderInput' | 'options'> &
  Options
const AutoCompleteFormik = ({ listItems, name, ...rest }: Props) => {
  const formik = useContext(FormikContext)
  const [value, setValue] = useState(null as any)
  const hasError = Boolean(
    getNestedObject(name, formik.touched) && getNestedObject(name, formik.errors),
  )
  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      options={listItems}
      disableClearable
      size='small'
      clearOnBlur={false}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event: any, newValue: any) => {
        setValue(newValue)
        formik.setFieldValue(name, newValue.id)
      }}
      slotProps={{
        paper: {
          sx: {
            marginTop: '8px',
            fontSize: '16px',
            border: '1px solid #DADCDD',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25) !important',
            borderRadius: '4px',
            '& .MuiAutocomplete-listbox': {
              padding: '16px',
            },
            '& li.MuiAutocomplete-option': {
              fontFamily: 'Overpass',
              backgroundColor: 'white!important',
              padding: '0',
            },
            '& li:hover': {
              color: '#292929',
              fontWeight: '700',
            },
            '& li + li': {
              marginTop: '8px',
            },
          },
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          py: '10px',
        },
        '& .MuiButtonBase-root.MuiIconButton-root': {
          pt: '0',
        },
        '& .MuiButtonBase-root.MuiIconButton-root:hover': {
          backgroundColor: 'white',
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
          py: '0',
        },
      }}
      {...rest}
      renderInput={(params) => {
        return (
          <OutlinedField
            {...params}
            onBlur={() => formik.setFieldTouched(name, true)}
            error={hasError}
            helperText={(hasError ? getNestedObject(name, formik.errors) : '') as React.ReactNode}
          />
        )
      }}
    />
  )
}

export default AutoCompleteFormik
