import { useContext } from 'react'
import { FieldConfig } from 'formik'
import MenuItem from '@mui/material/MenuItem'
import OutlinedField from '../../common/fields/outlinedField'
import FormikContext from '../../../context/formikContext'
import { getNestedObject } from '../../../utils'
import { Option } from '../../../types'

interface Options {
  listItems: Option[]
}
type Props = FieldConfig & React.ComponentProps<typeof OutlinedField> & Options
export default function SelectFormik({ listItems, name, ...rest }: Props) {
  const formik = useContext(FormikContext)
  const hasError = Boolean(
    getNestedObject(name, formik.touched) && getNestedObject(name, formik.errors),
  )
  return (
    <OutlinedField
      {...rest}
      {...formik.getFieldProps(name)}
      SelectProps={{
        displayEmpty: true,
        // MenuProps: {
        //   PaperProps: {
        //     sx: {
        //       marginTop: '8px',
        //       fontSize: '16px',
        //       border: '1px solid #DADCDD',
        //       boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25) !important',
        //       borderRadius: '4px',
        //     },
        //   },
        //   MenuListProps: {
        //     sx: {
        //       padding: '16px',
        //       '& li': {
        //         fontFamily: 'Overpass',
        //         backgroundColor: 'white!important',
        //         padding: '0',
        //       },
        //       '& li:hover': {
        //         color: '#292929',
        //         fontWeight: '700',
        //       },
        //       '& li + li': {
        //         marginTop: '8px',
        //       },
        //     },
        //   },
        // },
      }}
      error={hasError}
      helperText={hasError ? getNestedObject(name, formik.errors) : ''}
      select
      defaultValue=''
    >
      <MenuItem value='' disabled>
        Choisir
      </MenuItem>
      {listItems.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </OutlinedField>
  )
}
