

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
interface Props {
  title: string
  style?: object
  textDesc?: string | null | React.ReactNode
}
const defaultStyle = {
  paddingBottom: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}
const BlockTitleText = ({ title, textDesc, style }: Props) => {
  return (
    <Box sx={{...defaultStyle , ...style}}>
      <Typography variant='textBold'>
        {title}
      </Typography>
      <Typography variant='smallTextNormal'>{textDesc}</Typography>
    </Box>
  )
}
export default BlockTitleText