const UnreadedEmailIcon = () => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 14C1.0875 14 0.734375 13.853 0.440625 13.5591C0.146875 13.2652 0 12.9119 0 12.4992V3.4941C0 3.08137 0.146875 2.72917 0.440625 2.4375C0.734375 2.14583 1.0875 2 1.5 2H9.52083C9.49306 2.23611 9.48611 2.48264 9.5 2.73958C9.51389 2.99653 9.55556 3.25 9.625 3.5H1.5L8 7.22917L10.9167 5.54167C11.1111 5.72222 11.3681 5.88889 11.6875 6.04167C12.0069 6.19444 12.2917 6.30556 12.5417 6.375L8 9L1.5 5.27083V12.5H14.5V6.3655C14.7778 6.30239 15.0382 6.20486 15.2812 6.07292C15.5243 5.94097 15.7639 5.79167 16 5.625V12.4952C16 12.9151 15.8507 13.2708 15.5521 13.5625C15.2535 13.8542 14.9028 14 14.5 14H1.5ZM13.5 5C12.8056 5 12.2153 4.75694 11.7292 4.27083C11.2431 3.78472 11 3.19444 11 2.5C11 1.80556 11.2431 1.21528 11.7292 0.729167C12.2153 0.243056 12.8056 0 13.5 0C14.1944 0 14.7847 0.243056 15.2708 0.729167C15.7569 1.21528 16 1.80556 16 2.5C16 3.19444 15.7569 3.78472 15.2708 4.27083C14.7847 4.75694 14.1944 5 13.5 5Z'
        fill='#5F686A'
      />
    </svg>
  )
}

export default UnreadedEmailIcon
