import { useContext, useState, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import OutlinedField from '../../common/fields/outlinedField'
import { FieldConfig } from 'formik'
import FormikContext from '../../../context/formikContext'
import { getNestedObject } from '../../../utils'
import DatePickerIcon from '../../../icons/datePickerIcon'
type Props = FieldConfig & React.ComponentProps<typeof OutlinedField>

export default function DatePickerFieldFormik({ name, ...rest }: Props) {
  const formik = useContext(FormikContext)
  const hasError = Boolean(
    getNestedObject(name, formik.touched) && getNestedObject(name, formik.errors),
  )
  const [value, setValue] = useState<Dayjs | null>(dayjs(''))

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue?.isValid()) {
      formik.setFieldValue(name, newValue.format('YYYY-MM-DD'))
    } else {
      formik.setFieldValue(name, '')
    }
    setValue(newValue)
  }
  useEffect(() => {
    if (getNestedObject(name, formik.values) === getNestedObject(name, formik.initialValues)) {
      setValue(dayjs(getNestedObject(name, formik.initialValues) as string))
    }
  }, [getNestedObject(name, formik.values)])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat='YYYY-MM-DD'
        value={value}
        onChange={handleChange}
        components={{
          OpenPickerIcon: DatePickerIcon,
        }}
        OpenPickerButtonProps={{
          sx: {
            '&:hover': {
              backgroundColor: 'white',
            },
            pr: '13px',
          },
        }}
        renderInput={(params) => (
          <OutlinedField
            {...params}
            {...rest}
            name={name}
            onBlur={formik.handleBlur}
            error={hasError}
            helperText={(hasError ? getNestedObject(name, formik.errors) : '') as React.ReactNode}
          />
        )}
      />
    </LocalizationProvider>
  )
}
