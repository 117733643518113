import Axios from '.'
import { AxiosConfigType } from '../types'
import {  EventsType} from '../types/api/events'
import { PaginationResponseSecondFormat, OptionalParamsApi } from '../types/api'
import { generateParamsURL } from '../utils'


const getEvents = async (
    params: OptionalParamsApi,
    config?: AxiosConfigType,
  ) => {
    const response = await Axios.get<PaginationResponseSecondFormat<EventsType>>(
      `/events/purchased?${generateParamsURL(params)}`,
      config,
    )
    return response.data
  }

const EventsApi = {
    getEvents,
}

export default EventsApi