import BodyModalWithActions from '../../../../components/common/modals/bodyModalWithActions'
import { Typography } from '@mui/material'
import { t } from 'i18next'
import ContainedButton from '../../../../components/common/buttons/containedButton'
interface Props {
  handleClose: () => void
}
const BodyModalConfirmResendInvitation = ({ handleClose }: Props) => {
  return (
    <BodyModalWithActions
      title={t('pages.users.resendInvitationTitle')}
      type='confirm'
      content={
        <Typography sx={{ my: '16px!important' }}>
          {t('pages.users.resendInvitationDescription')}
        </Typography>
      }
      actions={<ContainedButton title='ok' sx={{ py: '0' }} handleClick={handleClose} />}
    />
  )
}

export default BodyModalConfirmResendInvitation
