import Box from '@mui/material/Box'
import LargeArrowUpIcon from './largeArrowUpIcon'
import SmallArrowUpIcon from './smallArrowUpIcon'
import { SxProps, Theme } from '@mui/material'
interface Props {
  size?: 'large' | 'small'
  open: boolean
  sx?: SxProps<Theme>
}
const icons = {
  large: <LargeArrowUpIcon />,
  small: <SmallArrowUpIcon />,
}
const OpenCloseArrowIcon = ({ open, sx, size = 'small' }: Props) => {
  return (
    <Box
      component='span'
      sx={{ ...(open ? {} : { transform: 'rotate(180deg)', mt: '5px' }), ...sx }}
    >
      {icons[size]}
    </Box>
  )
}

export default OpenCloseArrowIcon
