import MaterialReactTable, { MaterialReactTableProps } from 'material-react-table'
import Box from '@mui/material/Box'
import Pagination from './pagination'
import OpenCloseArrowIcon from '../../icons/openCloseArrowIcon'
const ExpandComponent = ({ row }: any) => (
  <OpenCloseArrowIcon
    sx={{
      mr: '19px',
      cursor: 'pointer',
      p: '5px',
    }}
    open={row.getIsExpanded()}
  />
)
interface CustomTableProps<T extends Record<string, any>> extends MaterialReactTableProps<T> {
  propsPagination?: React.ComponentProps<typeof Pagination>
}
const CustomTable = <T extends Record<string, any>>({
  displayColumnDefOptions,
  propsPagination,
  ...props
}: CustomTableProps<T>) => {
  return (
    <>
      <MaterialReactTable
        layoutMode='grid'
        muiTablePaperProps={{
          sx: { borderRadius: 'none', boxShadow: 'none' },
        }}
        muiTableHeadCellProps={{
          sx: {
            color: 'white',
            textTransform: 'uppercase',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '500',
            letterSpacing: '1px',
            justifyContent:'center',
            py: '11px',
            px: '0',
            '& .MuiTableSortLabel-root': {
              ml: '14px',
            },
            '& .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon, & .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon':
              {
                color: '#A5A3A3',
              },
          },
        }}
        muiTableHeadRowProps={{
          sx: { backgroundColor: 'primary.main' },
        }}
        muiTableBodyCellProps={({ row }) => ({
          sx: {
            p: '10px 0',
            borderBottom: '1px solid #DADCDD',
            height: '44px',
            ...(row.getIsExpanded() ? { borderBottom: 'none' } : {}),
          },
        })}
        enableColumnFilters={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableBottomToolbar={false}
        enableExpandAll={false}
        enableColumnActions={false}
        positionExpandColumn='last'
        displayColumnDefOptions={{
          'mrt-row-expand': {
            size: 3,
            maxSize: 3,
            minSize: 3,
            header: '',
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                px: '0',
                justifyContent: 'end',
                borderBottom: '1px solid #DADCDD',
                ...(row.getIsExpanded() ? { borderBottom: 'none' } : {}),
              },
            }),
            Cell: ExpandComponent,
          },
          ...displayColumnDefOptions,
        }}
        muiTableDetailPanelProps={({ row }) => ({
          sx: {
            width: '100%',
            pt: '3px',
            pl: '56px',
            ...(row.getIsExpanded() ? { pb: '36px' } : {}),
          },
        })}
        localization={{
          unsorted: '',
          sortByColumnAsc: '',
          sortByColumnDesc: '',
          sortedByColumnAsc: '',
          sortedByColumnDesc: '',
          noRecordsToDisplay: 'Aucun élément à afficher',
        }}
        {...props}
      />
      {propsPagination?.count && propsPagination.count > 1 ? (
        <Box sx={{ mt: '40px', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Pagination size='large' {...propsPagination} />
        </Box>
      ): null}
    </>
  )
}

export default CustomTable
