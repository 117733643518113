import { useContext, useEffect } from 'react'
import ArrowRightIcon from '../../../icons/arrowRightIcon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import AvatarIcon from '../../../icons/avatarIcon'
import { useGetSession, useUpdateSession } from '../../../hooks/api/users'
import { SxProps, Theme } from '@mui/material'
import i18n from 'i18next'
import { equals } from 'ramda'
import { FrOrEnLanguage } from '../../../types'
import { Member } from '../../../types/api/user'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'
import { ActiveRegionContext } from '../../../context/activeRegionContext'
import { ErrorModalContext } from '../../../context/errorModalContext'
import { CartContext } from '../../../context/cartContext'
import { postCrossDomainMessage } from '../../../utils'
import ArrowDownIcon from '../../../icons/arrowDownIcon'
import { LocalStorageKeys } from '../../../constants'

interface PropsContentItemSubMenu {
  label: string
  region: string
  active?: boolean
}

const ContentItemSubMenu = ({ label, region, active = false }: PropsContentItemSubMenu) => (
  <>
    <Box mr='10px' height='100%' minWidth='12px'>
      {active && <ArrowRightIcon />}
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <span>{label}</span>
      <span>{region}</span>
    </Box>
  </>
)
interface PropsItemSubMenu {
  handleClick: () => void
  sx?: SxProps<Theme>
  label: React.ReactNode
}

const ItemSubMenu = ({ handleClick, sx, label }: PropsItemSubMenu) => {
  return (
    <Typography
      variant='smallText'
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight: '44px',
        backgroundColor: 'primary.lighter',
        pl: '19px',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: 'primary.light',
          color: 'white',
        },
        '&:hover svg path': {
          fill: '#fff',
        },
        ...sx,
      }}
      component='li'
      onClick={handleClick}
    >
      {label}
    </Typography>
  )
}

const useHandleSession = () => {
  const { data: session, error } = useGetSession()
  const current_lang = i18n.language as FrOrEnLanguage
  const { setActiveEnterprise } = useContext(ActiveEnterpriseContext)
  const { setActiveRegion } = useContext(ActiveRegionContext)
  const { setCart } = useContext(CartContext)

  const { setShowModal, setErrorMessage } = useContext(ErrorModalContext)
  const { mutate: updateSession, error: updateError } = useUpdateSession()
  useEffect(() => {
    if (!session) return
    if (session.is_member) {
      const localStorageActiveEnterprise = localStorage.getItem(LocalStorageKeys.ActiveEnterprise)
      const isEmptyLocalStorageActiveEnterprise = !localStorageActiveEnterprise
      const localStorageActiveEnterpriseNotInMembers = !session.members.find((member) =>
        equals(JSON.parse(localStorageActiveEnterprise!), member),
      )
      if (session.members.length === 0) {
        localStorage.removeItem(LocalStorageKeys.ActiveEnterprise)
        setActiveEnterprise({} as Member)
        return
      }
      if (
        session.members.length > 0 &&
        (isEmptyLocalStorageActiveEnterprise ||
          localStorageActiveEnterpriseNotInMembers ||
          session.active_member_id === null)
      ) {
        const activeMember =
          session.members.find((member) => member.crm_id === session.active_member_id) ??
          session.members[0]
        setActiveEnterprise(activeMember)
        localStorage.setItem(LocalStorageKeys.ActiveEnterprise, JSON.stringify(activeMember))
        setActiveRegion(activeMember.region?.wp_id[current_lang] || '')
        localStorage.setItem(
          LocalStorageKeys.ActiveRegion,
          activeMember.region?.wp_id[current_lang] || '',
        )
        activeMember.cart_item ? setCart(activeMember.cart_item): setCart('0')
        activeMember.cart_item ? 
        localStorage.setItem(
          LocalStorageKeys.CartItem,
          JSON.stringify(activeMember.cart_item))
        : localStorage.setItem(LocalStorageKeys.CartItem,'0')
        
      }
    } else {
      localStorage.removeItem(LocalStorageKeys.ActiveEnterprise)
      setActiveEnterprise({} as Member)
      setActiveRegion(session.region.wp_id[current_lang])
      localStorage.setItem(LocalStorageKeys.ActiveRegion, session.region.wp_id[current_lang])
      setCart(session.cart_item)
      localStorage.setItem(LocalStorageKeys.CartItem, JSON.stringify(session.cart_item))
    }
  }, [session])

  useEffect(() => {
    if (error || updateError) {
      setShowModal(true)
      setErrorMessage(
        error
          ? 'Une erreur de serveur est survenue lors de la récupération des données.'
          : 'Une erreur de serveur est survenue lors de la modication des données.',
      )
      localStorage.clear()
      setActiveEnterprise({} as Member)
    }
  }, [error, updateError])
  const handleUpdateActiveEnterprise = async (member: Member) => {
    await updateSession(member.crm_id)
    localStorage.setItem(LocalStorageKeys.ActiveEnterprise, JSON.stringify(member))
    setActiveEnterprise(member)
  }

  const handleUpdateActiveRegion = (region_id: string | null) => {
    if (region_id) {
      localStorage.setItem(LocalStorageKeys.ActiveRegion, region_id)
      setActiveRegion(region_id)
      postCrossDomainMessage(region_id)
    }
  }
  const handleUpdateCart = (cart_item: string | null | number) => {
    if (cart_item) {
      localStorage.setItem(LocalStorageKeys.CartItem, JSON.stringify(cart_item))
    } else localStorage.setItem(LocalStorageKeys.CartItem, '0')
    setCart(cart_item) 
  }
  return { session, handleUpdateActiveEnterprise, handleUpdateActiveRegion, handleUpdateCart, updateSession }
}
const DropDownMenu = () => {
  const { session, handleUpdateActiveEnterprise, handleUpdateActiveRegion, handleUpdateCart, updateSession } =
    useHandleSession()
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const handleLogout = async () => {
    await updateSession(null)
    localStorage.clear()
    window.location.href = `${process.env.REACT_APP_ACQ_SITE as string}/logout`
  }

  return (
    <Box
      sx={{
        cursor: 'pointer',
        my: '-15px',
        position: 'relative',
        '&:hover ul': {
          display: 'block',
        },
        '&:hover > div p': {
          color: 'white',
        },
        '&:hover > div': {
          backgroundColor: 'primary.light',
        },
        '&:hover > div svg path': {
          fill: '#fff',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '54px',
          width: '154px',
          mx: 'auto',
        }}
      >
        <AvatarIcon />
        <Typography variant='h6' component='p' sx={{ ml: '6px', mr: '10px' }}>
          Mon espace
        </Typography>
        <ArrowDownIcon />
      </Box>
      <Stack
        component='ul'
        spacing='1px'
        sx={{
          width: '230px',
          position: 'absolute',
          left: '0',
          background: 'white',
          pt: '1px',
          top: '100%',
          display: 'none',
          listStyle: 'none',
          pl: '0',
          my: '0',
        }}
      >
        {session?.is_member &&
          session?.members?.map((member) => (
            <ItemSubMenu
              key={member.crm_id}
              label={
                <ContentItemSubMenu
                  label={member.legal_name}
                  region={member.region?.name}
                  active={member.crm_id === activeEnterprise.crm_id}
                />
              }
              handleClick={() => {
                handleUpdateActiveEnterprise(member)
                handleUpdateActiveRegion(member.region?.wp_id.fr)
                handleUpdateCart(member.cart_item)
              }}
            />
          ))}
        <ItemSubMenu
          label='Déconnexion'
          sx={{
            backgroundColor: 'secondary.main',
            color: 'primary.main',
            '&:hover': 'none',
            pl: '41px',
          }}
          handleClick={handleLogout}
        />
      </Stack>
    </Box>
  )
}

export default DropDownMenu
