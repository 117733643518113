import React from 'react'
import Button from '@mui/material/Button'
type Porps = Omit<React.ComponentProps<typeof Button>, 'variant'> & {
  variant?: 'principal' | 'secondary'
}

const stylesPrincipal = {
  backgroundColor: 'secondary.main',
  py: '10px',
  px:'16px',
  color: 'white',
  '&:hover': {
    backgroundColor: 'primary.main',
    boxShadow: 'none',
  },
}
const stylesSecondary = {
  backgroundColor: 'white',
  py: '10px',
  minHeight: '16px',
  border: '2px solid #444D4E',
  color: 'primary.main',
  '&:hover': {
    backgroundColor: 'primary.main',
    color: 'white',
    boxShadow: 'none',
  },
}
const CustomButton = ({ children, variant = 'principal', sx, ...rest }: Porps) => {
  return (
    <Button
      variant='contained'
      sx={{
        px: '30px',
        fontWeight: '700',
        letterSpacing: '1px',
        borderRadius: '0px',
        boxShadow: 'none',
        fontSize: '12px',
        ...(variant === 'principal' ? stylesPrincipal : stylesSecondary),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default CustomButton
