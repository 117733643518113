const OpenEmailIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 0L15.244 4.14317C15.4841 4.28383 15.6701 4.46528 15.8021 4.6875C15.934 4.90972 16 5.15972 16 5.4375V14.5C16 14.9125 15.8531 15.2656 15.5594 15.5594C15.2656 15.8531 14.9125 16 14.5 16H1.5C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V5.4375C0 5.15972 0.0659722 4.90972 0.197917 4.6875C0.329861 4.46528 0.515896 4.28383 0.756021 4.14317L8 0ZM8 9.22917L14.5 5.5L8 1.77083L1.5 5.5L8 9.22917ZM8 11L1.5 7.29167V14.5H14.5V7.29167L8 11Z'
        fill='#F9A51A'
      />
    </svg>
  )
}

export default OpenEmailIcon
