import PaginationMui from '@mui/material/Pagination'
const styles = {
  '& li': {
    height: '40px',
    width: '40px',
  },
  '& .MuiPaginationItem-root': {
    color: 'primary.main',
    m: '0',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(249, 165, 26, 0.2)',
      '&:hover': {
        backgroundColor: 'rgba(249, 165, 26, 0.2)',
      },
    },
  },

  '& .MuiPaginationItem-ellipsis': {
    lineHeight: '32px',
  },
  '& li + li': {
    mr: '5px',
  },
  '& li:last-child': {
    mr: 0,
  },
}
const Pagination = (props: React.ComponentProps<typeof PaginationMui>) => {
  return <PaginationMui count={4} {...props} sx={styles} />
}

export default Pagination
