import Drawer from '@mui/material/Drawer'
import { drawerWidth } from '.'
import ListSideBar from './listSideBar'
import Box from '@mui/material/Box'

const SideBar = () => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'primary.background',
          pl: '44px',
          pr: '18px',
          borderRight: 'none',
        },
      }}
      variant='persistent'
      anchor='left'
      open={true}
    >
      <Box
        component='img'
        src='/logo.svg'
        sx={{ mb: '66px', width: '194px', height: '36px', mt: '67px' }}
      />
      <ListSideBar />
    </Drawer>
  )
}

export default SideBar
