import { useCallback, useMemo, useState } from 'react'
import Typography from '@mui/material/Typography'
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import { Box } from '@mui/material'
import CustomTable from '../../../../components/common/customTable'
import SimpleArrowRightIcon from '../../../../icons/simpleArrowRightIcon'
import {  useGetAutoFormationQuery } from '../../../../hooks/api/formations'
import {FormationType} from '../../../../types/api/formations'

const TitleColumn = () => <Box sx={{ pl: '19px' }}>Formation</Box>
const DurationBody = ({ row }: any) => (
  <Typography variant='smallTextNormal'>{`${row.original.duration} h`}</Typography>
)
const AutoFormations = () => {
  const [selectedTitle, setSelectedTitle] = useState('')
  const [page, setPage] = useState('1')
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'title', desc: true }])
  
  const { data, isFetching } = useGetAutoFormationQuery(
    {
      page,
      sort: sorting[0],
    }
  ,'AF')
  const TitleBody = useCallback(
    ({ row }: any) => {
      return (
        <Typography
          onMouseEnter={() => setSelectedTitle(row.original.crm_id)}
          onMouseLeave={() => setSelectedTitle('')}
          component='a'
          href={row.original.moodle_link}
          target='_blank'
          sx={{ pl: '19px', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {row.original.title}
        </Typography>
      )
    },
    [setSelectedTitle],
  )
  const ArrowBody = useCallback(
    ({ row }: any) => (
      <Typography
      component='a'
        href={row.original.moodle_link}
        target='_blank'
        sx={{
          width: '32px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover':{
            backgroundColor: 'secondary.main',
            '& svg path': {
              fill: '#fff',
            },
          },
          ...(selectedTitle === row.original.crm_id
            ? {
                backgroundColor: 'secondary.main',
                '& svg path': {
                  fill: '#fff',
                },
              }
            : {}),
        }}
        variant='smallTextNormal'
      >
        <SimpleArrowRightIcon />
      </Typography>
    ),
    [selectedTitle],
  )

  const columns = useMemo<MRT_ColumnDef<FormationType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Formation',
        Header: TitleColumn,
        size: 80,
        enableSorting: true,
        maxSize: 80,
        minSize: 80,
        Cell: TitleBody,
      },
      {
        accessorKey: 'duration',
        header: 'Durée',
        size: 15,
        enableSorting: false,
        maxSize: 15,
        minSize: 15,
        Cell: DurationBody,
      },
      {
        header: 'arrow',
        Header: <></>,
        size: 5,
        enableSorting: false,
        maxSize: 5,
        minSize: 5,
        Cell: ArrowBody,
      },
    ],
    [selectedTitle],
  )

  return <CustomTable data={data?.data ?? []} columns={columns} 
  enableSortingRemoval={false}
  enableSorting={true}
  enableMultiSort={false}
  manualSorting
  onSortingChange={(params) => {
    setPage('1')
    setSorting(params)
  }}
  state={{ sorting, isLoading: isFetching }}
  propsPagination={{
    page: Number(page),
    count: data?.metadata?.last_page ? Number(data?.metadata?.last_page) : 0,
    onChange: (e, index) => {
      setPage(index.toString())
    },
  }} muiTableBodyRowProps={{ hover: false }} />
}

export default AutoFormations
