import { useContext, useState } from 'react'
import DocumentTab from './documentTab'
import { ActiveEnterpriseContext } from '../../../../context/activeEnterpriseContext'
import { useGetDocumentsEnterpriseQuery } from '../../../../hooks/api/entreprises'
import { MRT_SortingState } from 'material-react-table'
const DocumentsEntreprise = () => {
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'created_at', desc: true }])
  const [page, setPage] = useState('1')
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data, isFetching } = useGetDocumentsEnterpriseQuery(activeEnterprise.crm_id, {
    page,
    sort: sorting[0],
  })
  return (
    <DocumentTab
      propsTable={{
        data: data?.data ?? [],
        state: { sorting, isLoading: isFetching },
        onSortingChange: (params) => {
          setPage('1')
          setSorting(params)
        },
        propsPagination: {
          page: Number(page),
          count: data?.meta?.last_page ? Number(data?.meta?.last_page) : 0,
          onChange: (e, index) => {
            setPage(index.toString())
          },
        },
       
      }}
      type="enterprise"
      text='Dans cet onglet se trouvent les documents liés au membrership de votre entreprise (copie du contrat d’adhésion ou de renouvellement d’adhésion, preuve de cautionnement de licence RBQ, documents achetés par l’entreprise, etc.).'
    />
  )
}

export default DocumentsEntreprise
