import React, { useState, useMemo, useEffect } from 'react'
import Box from '@mui/material/Box'
import Header from './header'
import SideBar from './sideBar'
import ErrorModal from '../common/modals/errorModal'
import { ErrorModalContext } from '../../context/errorModalContext'
import { SidebarContext } from '../../context/sideBarContext'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { ActiveRegionContext } from '../../context/activeRegionContext'
import { getActiveEnterpriseFromLocalStorage, hasAccess } from '../../utils'
import { LocalStorageKeys, authorization } from '../../constants'
import GeneralLoader from './generalLoader'
import { useAuth0 } from '@auth0/auth0-react'
export const drawerWidth = 288

export default function LayoutWithoutMenu() {
  const [selectedItemSidebar, setSelectedItemSidebar] = useState('')
  const [activeEnterprise, setActiveEnterprise] = useState(getActiveEnterpriseFromLocalStorage())
  const [activeRegion, setActiveRegion] = useState(
    localStorage.getItem(LocalStorageKeys.ActiveRegion) ?? '',
  )
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const location = useLocation()
  useEffect(() => {
    if (!localStorage.getItem(LocalStorageKeys.OpenItemsSideBar)) {
      localStorage.setItem(LocalStorageKeys.OpenItemsSideBar, JSON.stringify(['myProfile']))
    }
  }, [])
  const activeEnterpriseState = useMemo(
    () => ({ activeEnterprise, setActiveEnterprise }),
    [activeEnterprise, setActiveEnterprise],
  )
  const stateActiveRegion = useMemo(
    () => ({ activeRegion, setActiveRegion }),
    [activeRegion, setActiveRegion],
  )
  const stateSelectedItemSidebar = useMemo(
    () => ({ selectedItemSidebar, setSelectedItemSidebar }),
    [selectedItemSidebar, setSelectedItemSidebar],
  )
  const stateErrorHandling = useMemo(
    () => ({ showModal, errorMessage, setShowModal, setErrorMessage }),
    [showModal, errorMessage, setShowModal, setErrorMessage],
  )
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <GeneralLoader />
  }
  return (
    <ActiveEnterpriseContext.Provider value={activeEnterpriseState}>
      <ActiveRegionContext.Provider value={stateActiveRegion}>
        <SidebarContext.Provider value={stateSelectedItemSidebar}>
          <ErrorModalContext.Provider value={stateErrorHandling}>
            <Box sx={{ display: 'flex' }}>
              <Box
                component='main'
                sx={{
                  flexGrow: 1,
                  paddingInline: '120px',
                  paddingTop: '124px',
                  paddingBottom: '100px',
                  position:'relative'
                }}
              >
                {!hasAccess(authorization[location.pathname]?.read || null, activeEnterprise) ? (
                  <Navigate to='/profile/personal-informations' replace />
                ) : (
                  <Outlet />
                )}
              </Box>
              <ErrorModal></ErrorModal>
            </Box>
          </ErrorModalContext.Provider>
        </SidebarContext.Provider>
      </ActiveRegionContext.Provider>
    </ActiveEnterpriseContext.Provider>
  )
}
