import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'

interface Props {
  label: string
  switchProps?: SwitchProps | null
}

const StyledSwitch = (props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' {...props} />
)
const CustomSwitch = ({ label, switchProps }: Props) => {
  return (
    <FormControlLabel
      sx={{ flexDirection: 'row-reverse', marginLeft: '0px', alignSelf: 'flex-start' }}
      control={<StyledSwitch sx={{ m: 1 }} {...switchProps} />}
      label={label}
    />
  )
}
export default CustomSwitch
