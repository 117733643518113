import Box from '@mui/material/Box'
import Loader from '../common/loader'

const GeneralLoader = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </Box>
  )
}

export default GeneralLoader
